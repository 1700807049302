import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export type NuxState = {
  seenTutorial: boolean
  initialLoad: boolean
  homeViewAnyProfileAnnouncementSeen: boolean
}

const initialState = {
  seenTutorial: false,
  initialLoad: false,
  homeViewAnyProfileAnnouncementSeen: false,
} as NuxState

export const nuxSlice = createSlice({
  name: 'nux',
  initialState,
  reducers: {
    resetFromStorage: (
      state: NuxState,
      action: PayloadAction<Omit<NuxState, 'initialLoad'>>,
    ) => {
      state.seenTutorial = action.payload.seenTutorial
      state.homeViewAnyProfileAnnouncementSeen =
        action.payload.homeViewAnyProfileAnnouncementSeen
    },

    markSeenTutorial: (state) => {
      state.seenTutorial = true
    },

    markInitialLoad: (state) => {
      state.initialLoad = true
    },

    closeHomeViewAnnouncement: (state) => {
      state.homeViewAnyProfileAnnouncementSeen = true
    },
  },
})

export const selectNuxState = (state: RootState) => state.nux
export const selectSeenTutorial = (state: RootState) => state.nux.seenTutorial

export const {
  resetFromStorage,
  markSeenTutorial,
  markInitialLoad,
  closeHomeViewAnnouncement,
} = nuxSlice.actions

export default nuxSlice.reducer
