import {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'

export const useSearchParam = (key: string) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  return searchParams.get(key)
}

const getOrientation = () =>
  window.screen?.orientation?.type

export const useScreenOrientation = () => {
  const [orientation, setOrientation] =
    useState(getOrientation())

  const updateOrientation = () => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'orientationchange',
        updateOrientation
      )
    }
  }, [])

  return orientation
}