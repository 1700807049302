import { ExtraEventProps, ShareOptions } from './ShareTypes'
import * as playpass from 'playpass'
import { ABKeys, getBucketID } from '../../abTests';

const shareClicked = (
  shareTextTemplate: string,
  shareOptions: ShareOptions,
  eventProperties: ExtraEventProps,
  payload: Record<string, any>,
  link?: string,
  image?: string,
) => {
  let openGraph = {};
  if (getBucketID(ABKeys.OPEN_GRAPH) === 'variant') {
    openGraph = {
      image,
      title: 'Tweedle App',
      description: 'Love Wordle? Love browsing Twitter? Then Tweedle is the game for you! Play daily word puzzles for all your favorite profiles on social media!',
    };
  }

  const linkToShare =
    link ||
    playpass.createLink({
      data: {
        ...payload,
        destination: shareOptions.type === 'twitter' ? 'TWITTER' : 'UNKNOWN',
      },
      url: document.location.pathname,
      ...openGraph,
    })
  const text = shareTextTemplate.replace('{{link}}', linkToShare)

  return playpass.share({
    text,
    type: shareOptions.type as playpass.ShareType,
    trackProps: eventProperties,
    inReplyTo:
      shareOptions.type === 'twitter' && shareOptions.inReplyTo
        ? shareOptions.inReplyTo
        : undefined,
  })
}

export default shareClicked
