import { assignTestManually, getBucketId } from 'playpass/dist/esm/gcinstant'

export enum ABKeys {
  EXPLICIT_PLACEBO = '0001_explicit_placebo',
  // TUTORIAL_DEFAULT_PROFILE = '0002_tutorial_default_profile',
  // SHARE_BUTTON_CONFIGURATION = '0003_share_button_config',
  // HELP_SCREEN_BUTTON_COLOR = '0004_help_screen_button_color',
  // HELP_SCREEN_LESS_CONTENT = '0005_help_screen_less_content',
  // SHOW_HINT_FIRST_GAME = '0006_show_hint_first_game',
  // SHARE_CTA_TEXT = '0007_share_cta_text',
  // BROWSE_BEFORE_PLAY = '0008_browse_before_play',
  // CONTEXTUAL_SHARE = '009_contextual_share',
  // DEFAULT_PROFILE_HUMOR_INTEREST = '0010_default_profile_humor_interest',
  // HELP_VIEW_CTA = '0011_help_view_cta',
  // GAME_EASY_MODE = '0012_game_easy_mode',
  // REPLY_TO_TWEET_SHARE = '0013_reply_to_tweet_share',
  // HIDE_MENU_FTUE = '0014_hide_menu_ftue',
  // PLAY_NEXT_TWEET_CNN = '0015_play_next_tweet_cnn',
  // TWITTER_ADS_ANSWER = '0016_twitter_ads_answer',
  // HIGHLIGHT_ON_LAST_LETTER = '0017_highlight_on_last_letter',
  // MODAL_SHARE = '0018_modal_share',
  // NUX_VARIATION = '0019_nux_variation',
  // MODAL_STATS = '0020_modal_stats', 
  // SHARE_TEXT = '0021_share_text',
  OPEN_GRAPH = '0022_open_graph',
  // LEADERBOARD = '0023_leaderboard',
  // WEEKLY_PROGRESS = '0024_weekly_progress',
  // LEADERBOARD = '0025_leaderboard',
  // DEFAULT_TWEET = '0026_default_tweet',
  SHARE_TO_PLAY = '0027_share_to_play',
  SORTED_TWEETS = '0028_sorted_tweets',
}

export const Control = 'control'
export const Variant = 'variant'
export const Variant1 = 'variant1'
export const Variant2 = 'variant2'

export const abTestConfig = {
  [ABKeys.EXPLICIT_PLACEBO]: createABTest(Control, Variant),
  /*
  [ABKeys.TUTORIAL_DEFAULT_PROFILE]: createABTest(
    'elonmusk',
    'cnnbrk',
    'theellenshow',
    'kingjames',
    'katyperry',
  ),
  */
  /*[ABKeys.SHARE_BUTTON_CONFIGURATION]: createABTest(
    'control', // actions_with_twitter_button
    'a_s', // show sharesheet button with sub-actions
    'o_s', // show only the sharesheet button until clicked
    'o_t', // show only the twitter button until clicked
    'o_ts', // show only the twitter button and sharesheet button until clicked
    'a_ts', // show the twitter and sharesheet buttons with sub-actions
  ),*/
  // [ABKeys.HELP_SCREEN_BUTTON_COLOR]: createABTest('control', 'black'),
  // [ABKeys.HELP_SCREEN_LESS_CONTENT]: createABTest('control', 'less'),
  /*
  [ABKeys.SHOW_HINT_FIRST_GAME]: createABTest(
    'control',
    'keyboard',
    'prefilled',
  ),
  */
  /*
  [ABKeys.SHARE_CTA_TEXT]: createABTest(
    'control',
    GENERIC_BRAG,
    GENERIC_CHALLENGE,
    STREAK_BRAG,
    STREAK_SHARE,
    PERCENTILE_BRAG,
  ),
  */
  // [ABKeys.BROWSE_BEFORE_PLAY]: createABTest('control', 'browse_first'),
  // [ABKeys.CONTEXTUAL_SHARE]: createABTest('control', 'contextual_share'),
  /*
  [ABKeys.DEFAULT_PROFILE_HUMOR_INTEREST]: createABTest(
    'control',
    'qikipedia',
    'Dadsaysjokes',
  ),
  */
  // [ABKeys.HELP_VIEW_CTA]: createABTest('control', 'play', 'start', 'guess'),
  // [ABKeys.GAME_EASY_MODE]: createABTest('control', 'tutorial_only', 'always'),
  // [ABKeys.REPLY_TO_TWEET_SHARE]: createABTest('control', 'reply', 'reply_brag'),
  // [ABKeys.HIDE_MENU_FTUE]: createABTest('control', 'hidden'),
  // [ABKeys.PLAY_NEXT_TWEET_CNN]: createABTest('control', 'next_tweet'),
  // [ABKeys.TWITTER_ADS_ANSWER]: createABTest('control', 'answer'),
  // [ABKeys.HIGHLIGHT_ON_LAST_LETTER]: createABTest('control', 'highlight'),
  // [ABKeys.MODAL_SHARE]: createABTest('control', 'modal'),
  // [ABKeys.NUX_VARIATION]: createABTest('control', 'variant'),
  // [ABKeys.MODAL_STATS]: createABTest('control', 'modal_stats'),
  // [ABKeys.SHARE_TEXT]: createABTest('control', 'variant'),
  [ABKeys.OPEN_GRAPH]: createABTest('control', 'variant'),
  // [ABKeys.LEADERBOARD]: createABTest('control', 'variant'),
  // [ABKeys.WEEKLY_PROGRESS]: createABTest('control', 'variant'),
  // [ABKeys.LEADERBOARD]: createABTest('control', 'variant'),
  // [ABKeys.DEFAULT_TWEET]: createABTest('control', 'variant_share_prompted', 'variant_puzzle_complete'),
  [ABKeys.SHARE_TO_PLAY]: createABTest('control', 'share_2nd_game', 'share_3rd_game'),
  [ABKeys.SORTED_TWEETS]: createABTest('control', 'sorted_tweets'),
}

function createABTest<A extends string, B extends string, T extends string[]>(
  a: A,
  b: B,
  ...buckets: T
) {
  return {
    active: true,
    default: a,
    buckets: [a, b, ...buckets].map((bucket: A | B | T[number]) => ({
      id: bucket,
    })),
  }
}

export type ABTests = typeof abTestConfig
export type ManuallyAssignedABTestID = {
  [K in ABKeys]: ABTests[K] extends { assignManually: boolean } ? K : never
}[ABKeys]

export type ABTestBucket<T extends ABKeys> = ABTests[T]['buckets'][number]['id']

export type RunningABTests = {
  [K in ABKeys]: ABTestBucket<K> | null | undefined
}

export type ABTestEntry = {
  id: ABKeys
  bucket: string
}

export function isAbBucket<T extends ABKeys>(
  test: T,
  bucket: ABTestBucket<T>,
): boolean {
  return getBucketID(test) === bucket
}

export function assignAbBucket<T extends ABKeys>(
  test: T,
  bucket: ABTestBucket<T>,
): void {
  console.log('setting to', bucket)
  assignTestManually(test, bucket)
  console.log(getBucketId(test))
}

export function assignTestForDebug<T extends ABKeys>(
  test: T,
  bucket: ABTestBucket<T>,
) {
  debugData[test] = bucket
  saveDebugAB(debugData)
}

export type AnyBucket = ABTestBucket<ABKeys>

let debugData: Partial<Record<ABKeys, AnyBucket>>

loadDebugAB()

export function getBucketID<T extends ABKeys>(test: T): ABTestBucket<T> {
  if (debugData[test]) {
    return debugData[test] as ABTestBucket<T>
  }

  // if the test is not active lets return a default if there is one..
  // will always default to control..
  if (getBucketId(test) === undefined) {
    return 'control'
  }

  return getBucketId(test as string) as ABTestBucket<T>
}

export function loadDebugAB(): void {
  const data = JSON.parse(
    localStorage.getItem('abDebugData') as string,
  ) as Partial<Record<ABKeys, AnyBucket>>

  debugData = data ?? {}
}

export function clearDebugAB() {
  localStorage.setItem('abDebugData', JSON.stringify({}))
}

export function saveDebugAB(data: Partial<Record<ABKeys, string>>): void {
  window.localStorage.setItem('abDebugData', JSON.stringify(data))
}
