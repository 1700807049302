import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export interface LeaderboardState {
  username: string
}

const initialState: LeaderboardState = {
  username: '',
}

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setLeaderboardUsername: (state, action: PayloadAction<{ name: string; isDefault?: boolean; }>) => {
      state.username = action.payload.name
    },
    resetLeaderboardFromStorage: (state, action: PayloadAction<LeaderboardState>) => {
      state.username = action.payload.username
    },
  },
});

export const selectLeaderboardName = (state: RootState) => state.leaderboard.username;

export const {
  setLeaderboardUsername,
  resetLeaderboardFromStorage,
} = leaderboardSlice.actions

export default leaderboardSlice.reducer;
