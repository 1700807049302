import GameLogic from '../GameLogic'
import { State } from '../storage/daily'
import Keyboard from '../components/Keyboard'
import entryPointData from '../util/entryPointData'
import { useAppSelector, usePrevious } from '../app/hooks'
import gsap from 'gsap'
import {
  useCurrentProfile,
  useCurrentProfileContext,
} from '../features/profiles/profilesHooks'
import {
  addLetter,
  removeLetter,
  GameState,
  setMessage,
  submitAnswer,
  selectIsHardMode,
} from '../features/game/gameSlice'
import { PlaySource } from '../features/profiles/profilesSlice'
import { getTweetText } from '../features/profiles/tweetUtils'
import { getRandomHint } from '../util/hints'
import ProfileTweet from '../features/profiles/ProfileTweet'
import { ResizableGrid } from '../components/Grid'
import ResultsView from './ResultsView'
import SizeAwareDiv from '../components/SizeAwareDiv'
import { useUserProperty } from '../features/user/userHooks'
import { UserProperties } from '../features/user/userSlice'
import { useDispatch } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { ABKeys, getBucketID } from '../abTests'
import * as playpass from 'playpass'
import classNames from 'classnames'

const PlayView = (props: {
  username: string
  setup: GameState['levelState']
  state: State
  message: GameState['message']
  gameState: GameState['gameState']
}) => {
  const { state, setup, gameState } = props
  const currentProfile = useCurrentProfile()
  const currentProfileContext = useCurrentProfileContext()
  const dispatch = useDispatch()
  const isHardMode = useAppSelector(selectIsHardMode);

  const firstCompletedProfile = useUserProperty(
    UserProperties.FIRST_COMPLETED_PROFILE,
  )
  const hint = useMemo(() => {
    return getRandomHint({ word: setup.word })
  }, [])

  const [expandTweet, setExpandTweet] = useState(true)

  const prevGameState = usePrevious(gameState)
  const gameJustFinished =
    (prevGameState &&
      prevGameState === 'PENDING' &&
      (gameState === 'WIN' || gameState === 'LOSS')) ||
    false

  useEffect(() => {
    // animate keyboard and results screen
    if (gameJustFinished) {
      const animTime = 1.0
      const delayTime = 1.0
      gsap.to('.keyboardContainer', {
        x: '-100vw',
        duration: animTime,
        delay: delayTime,
      })
      gsap.to('.resultsContainer', {
        x: '0vw',
        duration: animTime,
        delay: delayTime,
      })
    }

  }, [gameJustFinished])

  // We animate the first word for low intent users coming into the default
  // tutorial.
  const freeAnswerforTwitterAdsUsers =
    entryPointData.channel === 'AD' &&
    entryPointData.adNetworkName === 'Twitter' &&
    !firstCompletedProfile

  const shouldPrefill =
    currentProfileContext?.source === PlaySource.TUTORIAL_DEFAULT &&
    entryPointData.channel === 'AD' &&
    isHardMode &&
    !freeAnswerforTwitterAdsUsers

  const animateFirstWord =
    shouldPrefill &&
    state.marks.length === 0 &&
    hint.startsWith(state.words[0]) &&
    !firstCompletedProfile

  useEffect(() => {
    // Only run this hint if you're in the AB test and haven't finished a game yet
    if (animateFirstWord) {
      let remainingWord = hint.slice(state.words[0].length)
      let currentTimeout: NodeJS.Timeout | null = null

      dispatch(
        setMessage({
          type: 'info',
          text: "Entering tutorial guess '" + hint + "'",
        }),
      )

      const addNextHintLetter = () => {
        if (remainingWord) {
          dispatch(addLetter(remainingWord[0]))
        } else {
          dispatch(submitAnswer())
        }
      }

      currentTimeout = setTimeout(addNextHintLetter, 500)
      return () => {
        if (currentTimeout) {
          clearTimeout(currentTimeout)
        }
      }
    } else if (
      state.marks.length === 1 &&
      state.words[1] === '' &&
      shouldPrefill
    ) {
      dispatch(
        setMessage({
          type: 'info',
          text: 'Enter another word!',
        }),
      )
    }
  }, [state.words[0], state.marks.length])

  useEffect(() => {
    if (
      currentProfile &&
      freeAnswerforTwitterAdsUsers &&
      state.marks.length === 0 &&
      setup.word.startsWith(state.words[0])
    ) {
      dispatch(
        setMessage({
          type: 'info',
          text: "Try entering '" + setup.word + "'",
        }),
      )
    }
  }, [state.words[0], state.marks.length, currentProfile?.username])

  const handleKeyEvent = (key: string) => {
    if (GameLogic.isSolved(state) || state.marks.length === setup.guesses) {
      return
    }

    if (key === 'Enter') {
      dispatch(submitAnswer())
    } else if (key === 'Delete') {
      dispatch(removeLetter())
    } else {
      dispatch(addLetter(key))
    }
  }

  return (
    <>
    <div className="gameScreen" id="playingScreen">
      {currentProfile?.level.tweet !== undefined && (
        <ProfileTweet
          profile={currentProfile!.profile}
          word={currentProfile!.level.word}
          tweetObject={currentProfile!.level.object}
          tempMessage={props.message.text}
          shortText={
            gameState !== 'WIN' && gameState !== 'LOSS'
              ? 'Tap for hint'
              : `#${props.message.text.toLowerCase()} Tap to reveal tweet`
          }
          expandedText={
            getTweetText(currentProfile!.level.object) ||
            currentProfile!.level.tweet
          }
          gameData={{
            currentProfile,
            state,
            level: setup,
            status: gameState,
          }}
          linkToTwitter={gameState === 'WIN' || gameState === 'LOSS'}
          expand={expandTweet}
          textStyleOptions={{
            obfuscateAnswer: gameState !== 'WIN' && gameState !== 'LOSS',
            obfuscateTweet:
              gameState === 'WIN' || gameState === 'LOSS' || !isHardMode
                ? 0
                : (currentProfile.level.guesses - state.marks.length) /
                  currentProfile.level.guesses,
            highlight: gameState === 'WIN' || gameState === 'LOSS',
          }}
          onClick={() => {
            setExpandTweet(!expandTweet)
          }}
          whiteBackground={true}
        />
      )}
      <div
        className="clickCatcher"
        onClick={() => {
          playpass.analytics.track('GameHoneypotClicked')
          playpass.analytics.setUserProperties({
            maybeBot: true,
            maybeBotSource: 'gameHoneypot',
          })
        }}
      ></div>
      <SizeAwareDiv
        key={
          expandTweet && currentProfile?.level.tweet ? 'smallGrid' : 'largeGrid'
        }
        className="primary"
        render={(width, height) => {
          return (
            <ResizableGrid
              key={'playgrid'}
              width={width}
              height={height}
              enableScrollable={true}
              state={state}
              length={setup.word.length}
              guesses={setup.guesses}
            />
          )
        }}
      />
      <div className="bottom">
        <div
          className="keyboardContainer"
          style={{
            transform:
              (gameJustFinished || (gameState !== 'WIN' && gameState !== 'LOSS'))
                ? undefined
                : 'translateX(100vw)',
          }}
        >
          <Keyboard
            state={state}
            onKey={(e) => handleKeyEvent(e)}
            message={
              props.message.type === 'info' ? props.message.text : undefined
            }
            disable={animateFirstWord}
            currentWord={currentProfile!.level.word}
          />
        </div>
        <div
          className={classNames('resultsContainer')}
          style={{
            transform:
            !gameJustFinished && (gameState === 'WIN' || gameState === 'LOSS')
              ? undefined
              : 'translateX(100vw)',
          }}
        >
          <ResultsView state={state} setup={setup} status={gameState} />
        </div>
      </div>
    </div>
    </>
  )
}
export default PlayView
