import { ProfileItem } from './ProfileItem'
import { Profile } from './profilesSlice'
import './Profiles.css'

export const ProfileSection = (props: {
  id: string
  name: string
  profiles: Array<{ profile: Profile } & { lastCompletionTime?: string }>
  onTap: (username: string) => void
}) => {
  return (
    <section key={'profileSection' + props.id} className="profileSection">
      <h3 className="header">{props.name}</h3>
      {props.profiles.map((p) => {
        return (
          <ProfileItem
            key={p.profile.username}
            {...p}
            onTap={() => props.onTap(p.profile.username)}
          />
        )
      })}
    </section>
  )
}
