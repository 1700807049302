import React, { ReactFragment, ReactNode } from 'react'
import { ABKeys, getBucketID } from '../abTests'
import { GameState } from '../features/game/gameSlice'
import { CurrentPlayProfile } from '../features/profiles/profilesSlice'
import { State } from '../storage/daily'

export const GENERIC_BRAG = 'generic_brag'
export const GENERIC_CHALLENGE = 'generic_challenge'
export const STREAK_BRAG = 'streak_brag'
export const STREAK_SHARE = 'streak_share'
export const PERCENTILE_BRAG = 'p_brag'

export function getTwitterButtonText(type: 'short' | 'long') {
  return type === 'short' ? 'Brag' : 'Brag on Twitter'
}

export function getShareButtonText(type: 'short' | 'long') {
  return type === 'short' ? 'Brag' : 'Brag to Play Again'
}

export function getShareButtonTextFromGame(
  profile: CurrentPlayProfile,
  s: State,
  l: GameState['levelState'],
  st: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING',
) {
  if (st === 'LOADING' || st === 'PENDING') {
    const text = getSharedText(profile, s, st) + '\n\n{{link}}'
    return text
  }

  const dayText = profile.level.day !== -1 ? ` #${profile.level.day}` : ''

  // Share some text along with our link
  return (
    getSharedText(profile, s, st) +
    `\n${getShareHashtags(profile).join(' ')}` +
    dayText +
    ' ' +
    (st === 'LOSS' ? 'X' : s.marks.length.toString()) +
    '/' +
    l.guesses +
    '\n\n' +
    s.marks
      .map((str) =>
        str.replace(/n/g, '⬜').replace(/b/g, '🟩').replace(/c/g, '🟨'),
      )
      .join('\n') +
    '\n\n{{link}}'
  )
}

export function getOwnProfileShareText(
  profile: CurrentPlayProfile,
  s: State,
  l: GameState['levelState'],
  st: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING',
) {
  if (st === 'LOADING' || st === 'PENDING') {
    const text = 'Play #wordle puzzles for my tweets! #tweedle\n\n{{link}}'
    return text
  }

  const dayText = profile.level.day !== -1 ? ` #${profile.level.day}` : ''

  // Share some text along with our link
  return (
    'Can you beat my score?' +
    `\n#tweedle` +
    dayText +
    ' ' +
    (st === 'LOSS' ? 'X' : s.marks.length.toString()) +
    '/' +
    l.guesses +
    '\n\n' +
    s.marks
      .map((str) =>
        str.replace(/n/g, '⬜').replace(/b/g, '🟩').replace(/c/g, '🟨'),
      )
      .join('\n') +
    '\n\n{{link}}'
  )
}

export function getReplyShareButtonTextFromGame(
  profile: CurrentPlayProfile,
  s: State,
  l: GameState['levelState'],
  st: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING',
) {
  if (st === 'LOADING' || st === 'PENDING') {
    return 'I played this tweet! #tweedle\n\n{{link}}'
  }

  const dayText = profile.level.day !== -1 ? ` #${profile.level.day}` : ''

  // Share some text along with our link
  return (
    `I played this tweet!\n#tweedle` +
    dayText +
    ' ' +
    (st === 'LOSS' ? 'X' : s.marks.length.toString()) +
    '/' +
    l.guesses +
    '\n\n' +
    s.marks
      .map((str) =>
        str.replace(/n/g, '⬜').replace(/b/g, '🟩').replace(/c/g, '🟨'),
      )
      .join('\n') +
    '\n\n{{link}}'
  )
}

export function getBragShareButtonTextFromGame(
  profile: CurrentPlayProfile,
  s: State,
  l: GameState['levelState'],
  st: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING',
) {
  if (st === 'LOADING' || st === 'PENDING') {
    const text = getBragSharedText(profile, s, st) + '\n\n{{link}}'
    return text
  }

  const dayText = profile.level.day !== -1 ? ` #${profile.level.day}` : ''

  // Share some text along with our link
  return (
    getBragSharedText(profile, s, st) +
    `\n#tweedle` +
    dayText +
    ' ' +
    (st === 'LOSS' ? 'X' : s.marks.length.toString()) +
    '/' +
    l.guesses +
    '\n\n' +
    s.marks
      .map((str) =>
        str.replace(/n/g, '⬜').replace(/b/g, '🟩').replace(/c/g, '🟨'),
      )
      .join('\n') +
    '\n\n{{link}}'
  )
}

export function getShareHashtags(profile: CurrentPlayProfile) {
  return ['#tweedle']
}

function getShareExplainerTextWin(
  profile: CurrentPlayProfile,
  state: GameState['playerState'],
): ReactNode {
  return (
    <span>
      No one knows <b>{profile.profile.name}</b> better than you. Show off your
      score!
    </span>
  )
}

function getShareExplainerTextLoss(
  profile: CurrentPlayProfile,
  state: GameState['playerState'],
): ReactNode {
  return (
    <span>
      No one knows <b>{profile.profile.name}</b> better than you. Show off your
      score!
    </span>
  )
}

export function getSharedText(
  profile: CurrentPlayProfile,
  state: GameState['playerState'],
  gameState: GameState['gameState'],
) {
  if (gameState === 'LOADING' || gameState === 'PENDING') {
    return `Tweedle #tweedle\nCan you guess ${profile.username}'s  word?`
  } else {
    return `I know ${profile.username} better than you! Can you beat my score?`
  }
}

export function getBragSharedText(
  profile: CurrentPlayProfile,
  state: GameState['playerState'],
  gameState: GameState['gameState'],
) {
  if (gameState === 'LOADING' || gameState === 'PENDING') {
    return `Tweedle #tweedle\nCan you guess ${profile.username}'s  word?`
  } else {
    return `I know ${profile.username} better than you! Can you beat my score?`
  }
}

export function getShareExplainerText(
  profile: CurrentPlayProfile,
  playerState: GameState['playerState'],
  gameState: GameState['gameState'],
): ReactNode {
  if (gameState === 'WIN') {
    return getShareExplainerTextWin(profile, playerState)
  } else if (gameState === 'LOSS') {
    return getShareExplainerTextLoss(profile, playerState)
  } else {
    return ''
  }
}

export function getStatsShareText(
  playerState: GameState['playerState'],
  profile: CurrentPlayProfile,
) {
  const guessDistributions = playerState.wins.map((i) => {
    return i.reduce((p, c) => (c.win ? p + 1 : p), 0)
  })
  const [numberWon, totalGames] = playerState.wins.reduce(
    (prev, curr) => {
      const [wins, totalGames] = curr.reduce(
        (p, c) => {
          return [p[0] + (c.win ? 1 : 0), p[1] + 1]
        },
        [0, 0],
      )

      return [prev[0] + wins, prev[1] + totalGames]
    },
    [0, 0],
  )
  const maxStreak = playerState.maxStreak
  const winPercent =
    totalGames !== 0 ? Math.floor((numberWon / totalGames) * 100) : 0

  const maxValue = Math.max(...guessDistributions)
  const getSquares = (numberOfS: number) => {
    let output = ''
    for (let i = 0; i < numberOfS; i += 1) {
      output += '🟩'
    }
    return output
  }
  const graphData = guessDistributions.reduce((acc, val, index) => {
    const squares = Math.floor((val * 9) / maxValue) // 9 squares is max
    acc[index] = {
      squares: getSquares(squares),
      percent: `${((val / totalGames) * 100).toFixed(0)}%`,
    }
    return acc
  }, {} as Record<number, { squares: string; percent: string }>)

  return (
    '' +
    `#Tweedle #TweedleStats ${profile.username} 
🏆 Win ${winPercent}%
🏃 Max Streak ${maxStreak}
🥇 Games Played ${totalGames}

1️⃣ ${graphData[0].squares} ${graphData[0].percent}
2️⃣ ${graphData[1].squares} ${graphData[1].percent}
3️⃣ ${graphData[2].squares} ${graphData[2].percent}
4️⃣ ${graphData[3].squares} ${graphData[3].percent}
5️⃣ ${graphData[4].squares} ${graphData[4].percent}
6️⃣ ${graphData[5].squares} ${graphData[5].percent}
{{link}}
`
  )
}
