import { Fragment } from 'react'
import classNames from 'classnames'

declare global {
  interface String {
    hashCode(): number
  }
}

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr
  if (this.length === 0) return hash
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return (hash >>> 0) / 0x0ffffffff
}

const styleText = (
  text: string,
  word: string,
  options: {
    obfuscateAnswer: boolean
    highlight: boolean
    obfuscateTweet: number
    retweetAuthor?: string
  },
) => {
  let i = 0

  const lcText = text.toLowerCase()

  const findNextToken = () => {
    let token = ''
    if (i >= lcText.length) {
      return null
    }

    if (token !== '') {
      throw new Error('bad state')
    }
    let begIdx = i

    const stopRegex = /[^\w\d@#]{1}/
    let letter = lcText[begIdx++]
    while (letter.match(stopRegex) !== null && begIdx < lcText.length) {
      letter = lcText[begIdx++]
    }

    if (begIdx >= lcText.length) {
      i = begIdx
      if (letter) {
        return { token: letter, begIdx: begIdx - 1, endIdx: lcText.length }
      } else {
        return null
      }
    }

    let endIdx = begIdx
    begIdx = begIdx - 1
    token += letter

    letter = lcText[endIdx++]
    while (letter.match(stopRegex) === null && endIdx < lcText.length) {
      token += letter
      letter = lcText[endIdx++]
    }

    if (endIdx >= lcText.length && letter.match(stopRegex) === null) {
      i = endIdx
      if (token) {
        token += letter
        return { token, begIdx, endIdx: lcText.length }
      } else {
        return null
      }
    }

    // Special case for link
    if (token === 'https' || token === 'http') {
      // search till we find a space
      const linkStopRegex = /[\s]{1}/
      token += letter
      letter = lcText[endIdx++]
      while (letter.match(linkStopRegex) === null && endIdx < lcText.length) {
        token += letter
        letter = lcText[endIdx++]
      }
      if (endIdx >= lcText.length) {
        i = endIdx
        if (token) {
          token += letter
          return { token, begIdx, endIdx: lcText.length }
        } else {
          return null
        }
      }
    }

    endIdx = endIdx - 1
    i = endIdx
    if (token) {
      return { token, begIdx, endIdx }
    }
    return null
  }

  let nextToken = findNextToken()
  let currentFrag = ''
  let lastIndex = 0
  const { obfuscateAnswer, obfuscateTweet, highlight } = options

  const elements: Array<JSX.Element> = []

  let elementKey = 0
  if (options.retweetAuthor) {
    elements.push(
      <Fragment key={++elementKey}>
        RT{' '}
        <span key={++elementKey} className="link">
          {options.retweetAuthor}:{' '}
        </span>
      </Fragment>,
    )
  }

  let currentFragStartIndex = 0x0ffffffff
  const pushCurrentFrag = () => {
    const tokenHash = currentFragStartIndex / lcText.length // (currentFragStartIndex + ':' + currentFrag).hashCode()
    if (obfuscateTweet > 0 && tokenHash >= 1 - obfuscateTweet) {
      elements.push(
        <span key={++elementKey} className="obfuscate">
          {currentFrag}
        </span>,
      )
    } else {
      elements.push(<Fragment key={++elementKey}>{currentFrag}</Fragment>)
    }

    currentFrag = ''
    currentFragStartIndex = 0x0ffffffff
  }

  const appendToCurrentFrag = (begIdx: number, endIdx: number) => {
    currentFrag += text.substring(begIdx, endIdx)
    currentFragStartIndex = Math.min(begIdx, currentFragStartIndex)
  }

  while (nextToken !== null) {
    appendToCurrentFrag(lastIndex, nextToken.begIdx)

    const { token, begIdx } = nextToken
    // const tokenHash = ((begIdx + ':' + token).hashCode() >>> 0) / 0x0ffffffff
    const tokenHash = begIdx / lcText.length // (currentFragStartIndex + ':' + currentFrag).hashCode()

    if (nextToken.token.match(/^(http:|https:|@|#)/)) {
      pushCurrentFrag()

      const classes = classNames({
        link: text,
        obfuscate: obfuscateTweet > 0 && tokenHash >= 1 - obfuscateTweet,
      })

      elements.push(
        <span key={++elementKey} className={classes}>
          {text.substring(nextToken.begIdx, nextToken.endIdx)}
        </span>,
      )
    } else if (
      highlight &&
      nextToken.token.toLowerCase() === word.toLowerCase()
    ) {
      pushCurrentFrag()

      elements.push(
        <span key={++elementKey} className="highlight">
          {text.substring(nextToken.begIdx, nextToken.endIdx)}
        </span>,
      )
    } else if (obfuscateAnswer && token.toLowerCase() === word.toLowerCase()) {
      pushCurrentFrag()

      elements.push(
        <span key={++elementKey} className="obfuscate">
          {text.substring(nextToken.begIdx, nextToken.endIdx)}
        </span>,
      )
    } else if (obfuscateTweet > 0 && tokenHash >= 1 - obfuscateTweet) {
      pushCurrentFrag()

      elements.push(
        <span key={++elementKey} className="obfuscate">
          {text.substring(nextToken.begIdx, nextToken.endIdx)}
        </span>,
      )
    } else {
      appendToCurrentFrag(nextToken.begIdx, nextToken.endIdx)
    }
    lastIndex = nextToken.endIdx
    nextToken = findNextToken()
  }

  elements.push(<Fragment key={++elementKey}>{currentFrag}</Fragment>)

  return <Fragment key={'elementList' + ++elementKey}>{elements}</Fragment>
}
export default styleText
