import { entryPointData as epd } from "playpass/dist/esm/gcinstant";

if (process.env.NODE_ENV === 'development') {
  console.log(epd)
}

const entryPointData = {
  channel: epd.$channel,
  adNetworkName: epd.$adNetworkName as 'Twitter' | 'FB' | undefined,
}
export default entryPointData