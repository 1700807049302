import * as playpass from 'playpass'
import { GameState } from '../features/game/gameSlice'
import { NuxState } from '../features/nux/nuxSlice'

const TAG = 'nux_v1'

export class NuxStorage {
  constructor() {}

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<Omit<NuxState, 'initialLoad'>> {
    try {
      const state = (await playpass.storage.get(TAG)) as NuxState
      if (state) {
        return state
      }

      return {
        seenTutorial: false,
        homeViewAnyProfileAnnouncementSeen: false,
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: Omit<NuxState, 'initialLoad'>) {
    await playpass.storage.set(TAG, state)
  }
}
