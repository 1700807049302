import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppDispatch } from '../../app/store'
import { isValidWord } from '../../boilerplate/dictionary'
import GameLogic from '../../GameLogic'
import { Tweet } from '../profiles/tweetUtils'
import {
  generateRandomPlayerName,
  incrementPlayerScore,
} from '../../util/leaderboard'
import { getWinPercentage } from '../../util/stats'

type WinRecord = { day: number; guesses: number; win: boolean }
type Wins = WinRecord[][]

function newWins(): Wins {
  return [[], [], [], [], [], [], [], [], [], []]
}

export type GameHistoryValue = {
  day: number
  marks: string[]
  maxGuesses: number
  words: string[]
  gameState: 'WIN' | 'LOSS' | 'PENDING' | 'LOADING'
}

export type GameTweetHistoryValue = {
  tweetId: string
  marks: string[]
  maxGuesses: number
  words: string[]
  gameState: 'WIN' | 'LOSS' | 'PENDING' | 'LOADING'
}

export type NuxState = {
  walletNuxSeen: boolean
}

export type GameState = {
  playerState: {
    words: string[]
    marks: string[]
    currentStreak: number
    maxStreak: number
    wins: Wins
    day: number
  }
  levelState: {
    profile: string
    word: string
    guesses: number
    day: number
    id: string
    isCurrentDay: boolean
    object?: Tweet
    tweetId: string
  }
  needsTutorial: boolean
  gameState: 'WIN' | 'LOSS' | 'PENDING' | 'LOADING'
  message: {
    type: 'info' | 'reveal' | 'none'
    text: string
    id: 'bad_length' | 'not_a_word' | 'custom' | 'none' | 'game_over'
  }
  sessionStreak?: number
  history: Record<number, GameHistoryValue>
  tweetHistory: Record<number, GameTweetHistoryValue>
  nuxState: NuxState
  username?: string
  isHardMode: boolean
}

const initialState: GameState = {
  nuxState: {
    walletNuxSeen: false,
  },
  playerState: {
    words: [''],
    marks: [],
    currentStreak: 0,
    maxStreak: 0,
    wins: newWins(), // wins count for each successful attempt
    day: 0,
  },
  username: undefined,
  message: {
    type: 'none',
    text: '',
    id: 'none',
  },
  levelState: {
    profile: '',
    day: -1,
    word: '',
    guesses: 0,
    id: '',
    isCurrentDay: false,
    tweetId: '-1',
  },
  sessionStreak: 0,
  needsTutorial: false,
  gameState: 'LOADING',
  history: {},
  tweetHistory: {},
  isHardMode: false,
}

function getMessageForWin(guesses: number) {
  switch (guesses) {
    case 1:
      return 'Omniscient'
    case 2:
      return 'Majestic'
    case 3:
      return 'Sublime'
    case 4:
      return 'Marvelous'
    case 5:
      return 'Cool'
    default:
      return 'Close'
  }
}

function clearMessage(state: GameState) {
  state.message.text = ''
  state.message.type = 'none'
  state.message.id = 'none'
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    clear: (state) => {
      state.gameState = initialState.gameState
      state.message = initialState.message
      state.levelState = initialState.levelState
      state.history = initialState.history
      state.playerState = initialState.playerState
      state.tweetHistory = initialState.tweetHistory
    },
    resetFromStorage: (
      state,
      action: PayloadAction<{
        username: string
        state: Pick<GameState, 'playerState' | 'history' | 'tweetHistory'>
      }>,
    ) => {
      if (state.levelState.guesses === 0) {
        throw new Error('Level state needs to be reset first')
      }

      state.playerState = action.payload.state.playerState
      state.history = action.payload.state.history
      state.tweetHistory = action.payload.state.tweetHistory
      state.username = action.payload.username.toLowerCase()

      if (GameLogic.isSolved(state.playerState)) {
        state.gameState = 'WIN'

        state.message.type = 'reveal'
        state.message.text = getMessageForWin(state.playerState.marks.length)
        state.message.id = 'game_over'
      } else if (GameLogic.isLost(state.playerState, state.levelState)) {
        state.gameState = 'LOSS'

        state.message.type = 'reveal'
        state.message.text = state.levelState.word
        state.message.id = 'game_over'
      } else {
        state.gameState = 'PENDING'
        clearMessage(state)
      }
    },
    setMessage: (
      state,
      action: PayloadAction<Omit<GameState['message'], 'id'>>,
    ) => {
      state.message.text = action.payload.text
      state.message.type = action.payload.type
      state.message.id = 'custom'
    },
    resetLevel: (state, action: PayloadAction<GameState['levelState']>) => {
      state.levelState.day = action.payload.day
      state.levelState.word = action.payload.word
      state.levelState.guesses = action.payload.guesses
      state.levelState.id = action.payload.id
      state.levelState.object = action.payload.object
      state.levelState.isCurrentDay = action.payload.isCurrentDay
      state.levelState.profile = action.payload.profile
      state.levelState.tweetId = action.payload.tweetId
      if (process.env.NODE_ENV === 'development') {
        console.log(`answer: ${action.payload.word}`)
      }
    },
    clearCurrentWord: (state) => {
      state.playerState.words[state.playerState.words.length - 1] = ''
      clearMessage(state)
    },

    submitAnswer: (state) => {
      const word = state.playerState.words[state.playerState.words.length - 1]
      if (word.length < state.levelState.word.length) {
        state.message.type = 'info'
        state.message.text = 'Not enough letters'
        state.message.id = 'bad_length'
        return
      }

      if (isValidWord(word)) {
        const result = GameLogic.getMarks(word, state.levelState.word)
        state.playerState.marks.push(result)

        const isWin = GameLogic.isSolved(state.playerState)
        const isLoss = GameLogic.isLost(state.playerState, state.levelState)

        if (isWin) {
          state.gameState = 'WIN'

          state.playerState.wins[state.playerState.marks.length - 1].push({
            win: true,
            day: state.playerState.day,
            guesses: state.playerState.marks.length,
          })
          state.playerState.currentStreak++
          state.playerState.maxStreak = Math.max(
            state.playerState.currentStreak,
            state.playerState.maxStreak,
          )
          if (state.sessionStreak) {
            state.sessionStreak++
          } else {
            state.sessionStreak = 1;
          }

          state.message.type = 'reveal'
          state.message.text = getMessageForWin(state.playerState.marks.length)
          state.message.id = 'game_over'
        } else if (isLoss) {
          state.gameState = 'LOSS'

          state.playerState.wins[state.playerState.marks.length - 1].push({
            win: false,
            day: state.playerState.day,
            guesses: state.playerState.marks.length,
          })
          state.playerState.currentStreak = 0
          if (state.sessionStreak) {
            state.sessionStreak++
          } else {
            state.sessionStreak = 1;
          }

          state.message.type = 'reveal'
          state.message.text = state.levelState.word
          state.message.id = 'game_over'
        } else {
          if (state.playerState.words.length < state.levelState.guesses) {
            state.playerState.words.push('')
          }

          state.gameState = 'PENDING'
          state.message.id = 'none'
        }

        if (isWin || isLoss) {
          const { wins } = state.playerState
          const winPerc = getWinPercentage(wins)
          incrementPlayerScore(state.levelState.profile, winPerc)
        }

        // Record history
        if (state.levelState.day !== -1) {
          const day = state.levelState.day
          state.history[day] = {
            day: day,
            maxGuesses: state.levelState.guesses,
            marks: state.playerState.marks,
            words: state.playerState.words,
            gameState: state.gameState,
          }
        }
        const tweetId = state.levelState.tweetId
        state.tweetHistory[parseInt(tweetId)] = {
          tweetId: tweetId,
          maxGuesses: state.levelState.guesses,
          marks: state.playerState.marks,
          words: state.playerState.words,
          gameState: state.gameState,
        }
      } else {
        state.message.type = 'info'
        state.message.text = 'Not a valid word.'
        state.message.id = 'not_a_word'
      }
    },

    addLetter: (state, action: PayloadAction<string>) => {
      if (action.payload.length !== 1) {
        return
      }

      const word = state.playerState.words[state.playerState.words.length - 1]
      if (word.length + 1 > state.levelState.word.length) {
        return
      }
      state.playerState.words[state.playerState.words.length - 1] =
        word + action.payload

      clearMessage(state)
    },

    removeLetter: (state) => {
      const word = state.playerState.words[state.playerState.words.length - 1]

      if (word.length) {
        state.playerState.words[
          state.playerState.words.length - 1
        ] = word.substring(0, word.length - 1)
      }

      clearMessage(state)
    },

    setIsHardMode: (state, action: PayloadAction<boolean>) => {
      state.isHardMode = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
})

export const selectPlayerState = (state: RootState) => state.game.playerState
export const selectLevelState = (state: RootState) => state.game.levelState
export const selectInitialLoad = (state: RootState) => state.nux.initialLoad
export const selectGameState = (state: RootState) => state.game.gameState
export const selectMessage = (state: RootState) => state.game.message
export const selectHistory = (state: RootState) => state.game.history
export const selectNuxState = (state: RootState) => state.game.nuxState
export const selectTweetHistory = (state: RootState) => state.game.tweetHistory
export const selectIsHardMode = (state: RootState) => state.game.isHardMode
export const selectSessionStreak = (state: RootState) => state.game.sessionStreak

export const {
  clear,
  setMessage,
  resetLevel,
  submitAnswer,
  addLetter,
  removeLetter,
  resetFromStorage,
  clearCurrentWord,
  setIsHardMode,
} = gameSlice.actions

export default gameSlice.reducer
