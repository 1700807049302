import { isAnyOf } from '@reduxjs/toolkit'
import {
  resetFromStorage as resetGameFromStorage,
  submitAnswer,
} from '../features/game/gameSlice'
import {
  markPlayed,
  markProfileCompleted,
} from '../features/profiles/profilesSlice'
import { setUserProperty, UserProperties } from '../features/user/userSlice'
import GameLogic from '../GameLogic'
import { addAppListener } from './middleware'
import { StoreType } from './store'

const addStatsMiddleware = (store: StoreType) => {
  store.dispatch(
    addAppListener({
      matcher: isAnyOf(
        submitAnswer,
        resetGameFromStorage,
        markPlayed,
        markProfileCompleted,
      ),
      effect: (action, listenerApi) => {
        const state = listenerApi.getState().game.playerState
        const levelState = listenerApi.getState().game.levelState

        const realtimeGamesCompleted =
          listenerApi.getState().user.properties[
            UserProperties.REALTIME_GAMES_COMPLETED
          ] || 0

        const realtimeGamesStarted =
          listenerApi.getState().user.properties[
            UserProperties.REALTIME_GAMES_STARTED
          ] || 0

        switch (action.type) {
          case resetGameFromStorage.type:
            if (
              !GameLogic.isSolved(state) &&
              !GameLogic.isLost(state, levelState)
            ) {
              listenerApi.dispatch(
                setUserProperty({
                  key: UserProperties.REALTIME_GAMES_STARTED,
                  value: realtimeGamesStarted + 1,
                }),
              )
            }
            break

          case submitAnswer.type:
            if (
              GameLogic.isSolved(state) ||
              GameLogic.isLost(state, levelState)
            ) {
              listenerApi.dispatch(
                setUserProperty({
                  key: UserProperties.REALTIME_GAMES_COMPLETED,
                  value: realtimeGamesCompleted + 1,
                }),
              )
            }
            break

          case markPlayed.type:
          case markProfileCompleted.type:
            const realtimeProfilesPlayed = Object.values(
              listenerApi.getState().profiles.profileHistory,
            ).reduce((p, c) => {
              if (c.lastPlayTime !== undefined) {
                return p + 1
              }
              return p
            }, 0)
            const realtimeProfilesCompleted = Object.values(
              listenerApi.getState().profiles.profileHistory,
            ).reduce((p, c) => {
              if (c.lastCompletionTime !== undefined) {
                return p + 1
              }
              return p
            }, 0)

            listenerApi.dispatch(
              setUserProperty({
                key: UserProperties.REALTIME_PROFILES_STARTED,
                value: realtimeProfilesPlayed,
              }),
            )
            listenerApi.dispatch(
              setUserProperty({
                key: UserProperties.REALTIME_PROFILES_COMPLETED,
                value: realtimeProfilesCompleted,
              }),
            )
        }
      },
    }),
  )
}

export default addStatsMiddleware
