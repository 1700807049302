import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { Profile } from '../profiles/profilesSlice'

export type Properties = Record<string, any>

export enum UserProperties {
  FIRST_COMPLETED_PROFILE = '$firstCompletedProfile',
  LAST_COMPLETED_PROFILE = '$lastCompletedProfile',
  FIRST_STARTED_PROFILE = '$firstStartedProfile',
  LAST_STARTED_PROFILE = '$lastStartedProfile',
  LAST_ENTRY_IS_WEBVIEW = '$lastEntryIsWebview',
  LAST_ENTRY_BROWSER_APP = '$lastEntryBrowserApp',
  REALTIME_GAMES_COMPLETED = 'realtimeGamesCompleted',
  REALTIME_GAMES_STARTED = 'realtimeGamesStarted',
  REALTIME_PROFILES_STARTED = 'realtimeProfilesStarted',
  REALTIME_PROFILES_COMPLETED = 'realtimeProfilesCompleted',
}

export type UserState = {
  loginState: {
    isLoggedIn: boolean
    playerID?: string
  }
  twitterLogin?: {
    id: string
    username: string
    accessToken: string
    accessSecret: string
  }
  properties: Properties
}

export const initialState = {
  loginState: {
    isLoggedIn: false,
    playerID: undefined,
  },
  properties: {},
} as UserState

function processUserPropertyPayload(
  state: WritableDraft<UserState>,
  payload: { key: string; value: any; firstTimeOnly?: boolean },
) {
  if (payload.firstTimeOnly && state.properties[payload.key] !== undefined) {
    return
  }

  state.properties[payload.key] = payload.value
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetFromStorage: (state: UserState, action: PayloadAction<UserState>) => {
      state.loginState = action.payload.loginState
      state.properties = action.payload.properties
      state.twitterLogin = action.payload.twitterLogin
    },
    login: (state, action: PayloadAction<string>) => {
      state.loginState.isLoggedIn = true
      state.loginState.playerID = action.payload
    },
    logout: (state) => {
      state.loginState.isLoggedIn = false
      state.loginState.playerID = undefined
    },
    setUserProperty: (
      state,
      action: PayloadAction<{
        key: string
        value: any
        firstTimeOnly?: boolean
        disableLog?: boolean
      }>,
    ) => {
      processUserPropertyPayload(state, action.payload)
    },
    loginWithTwitter: (
      state,
      action: PayloadAction<UserState['twitterLogin']>,
    ) => {
      state.twitterLogin = action.payload
    },
  },
})

export const selectLoginState = (state: RootState) => state.user.loginState
export const selectUserProperties = (state: RootState) => state.user.properties
export const selectUserProperty = (property: UserProperties) => (
  state: RootState,
) => state.user.properties[property]
export const selectTwitterLoginState = (state: RootState) =>
  state.user.twitterLogin

export const useTwitterLogin = () => useAppSelector(selectTwitterLoginState)

export const {
  resetFromStorage,
  login,
  logout,
  setUserProperty,
  loginWithTwitter,
} = userSlice.actions

export default userSlice.reducer
