import EventEmitter from 'events'

const TIMEZONE_OVERRIDE = 'tz_override'

export default class DebugOverrides {
  private constructor() {
    this.reset()
  }

  public static instance = new DebugOverrides()

  private _events = new EventEmitter()
  private _active = false
  private _version = 0
  private _timezoneOverride: number | null = null

  isActive() {
    return this._active
  }
  setActive(active: boolean) {
    this._active = active
    this.save('active', this._active)
    return this
  }

  reset() {
    this._active = this.load('active', false)
    this._timezoneOverride = this.load(TIMEZONE_OVERRIDE, null)
  }

  setTimezoneOverride(minutes: number | null) {
    this._timezoneOverride = minutes
    this.save(TIMEZONE_OVERRIDE, this._timezoneOverride)
    return this
  }

  hasTimezoneOverride() {
    if (!this._active) {
      return null
    }
    return this._timezoneOverride !== null
  }

  getTimezoneOverride() {
    if (!this._active) {
      return null
    }

    return this._timezoneOverride
  }

  private save(key: string, value: unknown) {
    ++this._version
    localStorage['__debug_' + key] = value
    this._events.emit('change', this._version)
  }

  private load<T>(key: string, defaultValue: T) {
    const tag = '__debug_' + key
    if (localStorage[tag] !== undefined) {
      try {
        return JSON.parse(localStorage[tag]) as T
      } catch (e) {
        return defaultValue
      }
    }
    return defaultValue
  }

  addChangeListener(func: (version: number) => void) {
    this._events.addListener('change', func)
  }

  removeChangeListener(func: (version: number) => void) {
    this._events.removeListener('change', func)
  }

  clearAll() {
    for (var key in localStorage) {
      if (key.startsWith('__debug_')) {
        delete localStorage[key]
      }
    }

    this._version++
    this.reset()
    this._events.emit('change', this._version)
  }
}
