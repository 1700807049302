import { Fragment, useEffect, useState } from 'react'
import DebugOverrides from '../DebugOverrides'
import './Debug.css'
import {
  ABKeys,
  abTestConfig,
  assignTestForDebug,
  clearDebugAB,
  getBucketID,
} from '../abTests'
import classNames from 'classnames'
import * as playpass from 'playpass'
import zip from 'lodash/zip'

async function getInternalID(): Promise<string> {
  return new Promise((resolve, reject) => {
    const db = indexedDB.open('Playpass', 1)
    db.onsuccess = () => {
      const result = db.result
      const playerIdRequest = result
        .transaction('Internal', 'readonly')
        .objectStore('Internal')
        .get('playerId')
      playerIdRequest.onsuccess = () => {
        resolve(playerIdRequest.result as string)
      }
    }
  })
}

const GlobalActiveSection = () => {
  const active = DebugOverrides.instance.isActive()

  const setActive = (active: boolean) => {
    DebugOverrides.instance.setActive(active)
  }

  return (
    <Fragment>
      <p>
        Active{' '}
        <input
          type="checkbox"
          name="active"
          checked={active}
          onChange={(e) => setActive(e.currentTarget.checked)}
        />
      </p>
      <p>
        <button
          className="secondary"
          onClick={() => DebugOverrides.instance.clearAll()}
        >
          Clear All
        </button>
      </p>
    </Fragment>
  )
}

const ClearAllData = () => {
  return (
    <section>
      <h2>Clear All Data</h2>

      <button
        onClick={() => {
          const db = indexedDB.open('Playpass', 1)
          db.onsuccess = () => {
            const result = db.result
            const clearRequest = result
              .transaction('Game', 'readwrite')
              .objectStore('Game')
              .clear()
            clearRequest.onsuccess = () => {
              window.location.reload()
            }
          }
        }}
      >
        Clear all Data
      </button>
    </section>
  )
}

const LogAllData = () => {
  return (
    <section>
      <h2>Log All Data</h2>

      <button
        onClick={() => {
          const db = indexedDB.open('Playpass', 1)
          db.onsuccess = () => {
            const result = db.result
            const clearRequest = result
              .transaction('Game', 'readwrite')
              .objectStore('Game')
              .getAll()
            clearRequest.onsuccess = () => {
              const allResult = clearRequest.result
              const keysRequest = result
                .transaction('Game', 'readwrite')
                .objectStore('Game')
                .getAllKeys()
              keysRequest.onsuccess = () => {
                const keysResult = keysRequest.result
                const data = Object.fromEntries(zip(keysResult, allResult))
                console.log(data)
                playpass.analytics.track('DebugStateDump', {
                  state: JSON.stringify(data),
                })
              }
            }
          }
        }}
      >
        Log all Data
      </button>
    </section>
  )
}

const sections = [
  'recommended',
  'news',
  'sports',
  'crypto',
  'music',
  'influential people',
  'politics',
]

const LinkUtilitySection = () => {
  const [defer, setDefer] = useState(false)

  const [filterSections, setFilterSections] = useState({
    recommended: false,
    news: false,
    sports: false,
    crypto: false,
    music: false,
    'influential people': false,
    politics: false,
  } as { [key: string]: boolean })

  const updateFilterSection = (key: string, toggle: boolean) => {
    setFilterSections(Object.assign({}, filterSections, { [key]: toggle }))
  }

  const [first, setFirst] = useState('recommended')

  let options: Array<string> = []
  if (defer) {
    options.push('deferTutorial=1')
  }

  if (Object.keys(filterSections)) {
    let filters: Array<string> = []
    for (var item in filterSections) {
      if (filterSections[item]) {
        filters.push(item)
      }
    }

    if (filters.length > 0) {
      options.push('filter=' + filters.join(','.replace(' ', '%20')))
    }
  }

  if (first !== 'recommended') {
    options.push('order=' + first)
  }

  let href =
    options.length > 0
      ? 'https://tweedle.app?' + options.join('&')
      : 'https://tweedle.app'

  return (
    <section>
      <h2>Link Generation</h2>
      <div style={{ marginBottom: '10px' }}>
        Defer Tutorial till first play
        <input
          type="checkbox"
          checked={defer}
          onChange={(e) => setDefer(e.currentTarget.checked)}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        Only Show following sections
        {sections.map((s) => {
          return (
            <div key={s}>
              {s}{' '}
              <input
                type="checkbox"
                checked={filterSections[s]}
                onChange={(e) => {
                  console.log(s, e.currentTarget.checked)
                  updateFilterSection(s, e.currentTarget.checked)
                }}
              />
            </div>
          )
        })}
      </div>
      <div>
        First result
        <select value={first} onChange={(e) => setFirst(e.currentTarget.value)}>
          {sections.map((s) => {
            return (
              <option key={s} value={s}>
                {s}
              </option>
            )
          })}
        </select>
      </div>
      <h5>Result</h5>
      <a target="_blank" href={href}>
        {href}
      </a>
    </section>
  )
}

const TimezoneSection = () => {
  const overrideTime = DebugOverrides.instance.getTimezoneOverride()

  return (
    <section>
      <h2>Timezone Override</h2>

      <select
        value={overrideTime !== null ? overrideTime : -1}
        onChange={(e) => {
          const notSet = e.currentTarget.value === '-1'
          if (e.currentTarget.value === '-1') {
            DebugOverrides.instance.setTimezoneOverride(null)
          } else if (e.currentTarget.value === '0') {
            DebugOverrides.instance.setTimezoneOverride(0)
          } else {
            DebugOverrides.instance.setTimezoneOverride(
              parseInt(e.currentTarget.value),
            )
          }
        }}
      >
        <option value={-1}>No Override</option>
        <option value={-540}>JST</option>
        <option value={0}>GMT</option>
        <option value={420}>PDT</option>
      </select>
    </section>
  )
}

const ABTestSectionRow = (props: { test: ABKeys; idx: number }) => {
  const t = props.test
  const bucket = getBucketID(t)
  const metadata = abTestConfig[t]
  const buckets = metadata.buckets.map((b) => b.id)

  const [selectedBucket, setSelectedBucket] = useState(bucket)

  const classes = classNames({
    abTest: true,
    highlightRow: props.idx % 2 === 1,
  })

  return (
    <tr className={classes}>
      <td>{t}</td>
      <td>{bucket}</td>
      <td>
        <select
          value={selectedBucket}
          onChange={(e) =>
            setSelectedBucket(e.currentTarget.value as typeof selectedBucket)
          }
        >
          {buckets.map((b) => (
            <option key={b} value={b}>
              {b}
            </option>
          ))}
        </select>
      </td>
      <td>
        <button
          className="primary"
          onClick={() => {
            assignTestForDebug(t, selectedBucket)
            window.location.reload()
          }}
        >
          Assign
        </button>
        <button
          className="primary outline"
          onClick={() => {
            assignTestForDebug(t, metadata.default)
            window.location.reload()
          }}
        >
          Reset
        </button>
      </td>
    </tr>
  )
}

const ABTestSection = () => {
  const tests = Object.keys(abTestConfig) as ABKeys[]
  const testUI = tests.map((t, i) => <ABTestSectionRow test={t} idx={i} />)
  return (
    <section className="abTest">
      <h2>ABTest Overrides</h2>
      <table style={{ textAlign: 'left' }}>
        <thead style={{ fontWeight: 'bold' }}>
          <tr className="highlightRow header">
            <td>Experiment</td>
            <td>Current</td>
            <td>Override</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>{testUI}</tbody>
      </table>
      <button
        className="secondary"
        onClick={() => {
          clearDebugAB()
          window.location.reload()
        }}
      >
        Clear AB Overrides
      </button>
    </section>
  )
}

const DebugView = () => {
  const [version, setVersion] = useState(-1)
  const updateFunc = (val: number) => setVersion(val)
  useEffect(() => {
    DebugOverrides.instance.addChangeListener(updateFunc)
    return () => {
      DebugOverrides.instance.removeChangeListener(updateFunc)
    }
  })

  const renderSections = () => {
    if (!DebugOverrides.instance.isActive()) {
      return <Fragment />
    }
    const isPlaypassDomain =
      document.location.hostname.indexOf('playpass.games') !== -1
    return (
      <Fragment>
        <TimezoneSection />
        {(process.env.NODE_ENV === 'development' || isPlaypassDomain) && (
          <ABTestSection />
        )}
      </Fragment>
    )
  }

  const [playerId, setPlayerId] = useState('')
  useEffect(() => {
    getInternalID().then((id: string) => setPlayerId(id))
  }, [])

  return (
    <div className="debug">
      <h1>Debug Settings</h1>
      <p
        onClick={() => {
          void navigator.clipboard.writeText(playerId)
        }}
      >
        Internal ID: {playerId}
      </p>
      <GlobalActiveSection />
      <LinkUtilitySection />
      <LogAllData />
      <ClearAllData />
      {renderSections()}
    </div>
  )
}

export default DebugView
