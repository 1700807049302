import './AnnouncementToast.css'

const AnnouncementToast = (props: {
  title: string
  subtitle?: string
  onClose?: () => void
  children: any
  imgSrc?: string
}) => {
  return (
    <div className="announcementToast">
      {props.onClose && (
        <div className="closeButton" onClick={props.onClose}></div>
      )}
      <div className="row">
        {props.imgSrc && <img className="toastImage" src={props.imgSrc} />}
        <div className="widthConstrainer">
          <h1 className="title">{props.title}</h1>
          {props.subtitle && <h5 className="subtitle">{props.subtitle}</h5>}
          <div className="content">{props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default AnnouncementToast
