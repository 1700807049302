const randomChoices = {
  3: [
    'CAT',
    'DOG',
    'ANT',
    'NEW',
    'END',
    'LIU',
    'AND',
    'BUD',
    'YEA',
    'ICE',
    'CHI',
    'ZOO',
    'AXE',
    'MAN',
    'MOM',
    'FAT',
    'GAB',
    'JAR',
    'YAK',
    'PAL',
    'VIE',
  ],
  4: [
    'HOME',
    'THEN',
    'ABLE',
    'AERO',
    'BOZO',
    'COXA',
    'EGER',
    'FANG',
    'GUTS',
    'IDEA',
    'IDLE',
    'YAKS',
    'AXEL',
    'PART',
    'PALS',
  ],
  5: [
    'HOUSE',
    'RIGHT',
    'TRAIN',
    'ABOIL',
    'GHOST',
    'AXELS',
    'POINT',
    'WHERE',
    'ZOOMS',
    'BEVEL',
    'YAWNS',
  ],
  6: ['HOUSES', 'TRAINS', 'BARREL', 'CHEEKY', 'DUALLY', 'ESCAPE', 'YACHTS'],
  7: [
    'FINDING',
    'HAPPIER',
    'THOUGHT',
    'TRAINED',
    'WINDOWS',
    'ABILITY',
    'ANIMALS',
  ],
}

export function getRandomHint({ word }: { word: string }) {
  const wordLength = word.length
  const words = randomChoices[wordLength as 3 | 4 | 5 | 6 | 7]!

  const scoredWords = words.map((w) => {
    let correct = 0
    let misplaced = 0
    let lastSeen = {} as { [key: string]: number }
    for (var ii = 0; ii < word.length; ii++) {
      const l = w[ii]
      lastSeen[l] = ii

      if (l === word[ii]) {
        correct++
        continue
      }

      for (var jj = ii + 1; jj < word.length; jj++) {
        if (l === word[jj] && (lastSeen[l] === undefined || jj > lastSeen[l])) {
          misplaced++
          lastSeen[l] = jj
        }
      }
    }

    return {
      word: w,
      score: (correct - 1) * (correct - 1) + (misplaced - 1) * (misplaced - 1),
    }
  })

  scoredWords.sort((a, b) => a.score - b.score)
  return scoredWords[0].word
}
