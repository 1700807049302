import { stat } from 'fs'
import * as playpass from 'playpass'
import { GameState } from '../features/game/gameSlice'
import DateTime, { Timezone } from '../util/DateTime'
import DebugOverrides from '../DebugOverrides'

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24

// IMPORTANT - NEVER CALL Date.setHours() because it will lead to inconsistencies with how the server
// deals with dates.

export function getNowMidnight() {
  return getNow().toMidnight()
}

function getNow() {
  if (DebugOverrides.instance.hasTimezoneOverride()) {
    return DateTime.fromUtcDateTimeToLocal(
      DateTime.nowUtc(),
      DebugOverrides.instance.getTimezoneOverride()!,
    )
  }
  return DateTime.nowLocal()
}

export function getTomorrowMidnight() {
  return getNow().toTomorrow().toMidnight()
}

type WinRecord = { day: number; guesses: number; win: boolean }
type Wins = WinRecord[][]

function newWins(): Wins {
  return [[], [], [], [], [], [], [], [], [], []]
}

export type State = {
  words: string[]
  marks: string[]
  currentStreak: number
  maxStreak: number
  wins: Wins
  day: number
}
const TAG_SUFFIX = '_daily_v1'

type SerializedState = Pick<
  GameState,
  'playerState' | 'history' | 'tweetHistory'
>

export class Daily {
  public day: number = -1
  public tweetId: number = -1

  constructor(private firstDate: DateTime<Timezone.ClientLocal>, day?: number) {
    if (day) {
      this.day = day
    } else {
      this.resetDay()
    }
  }

  static forTweet(tweetId: string): Daily {
    const daily = new Daily(DateTime.nowLocal(), -1)
    daily.tweetId = parseInt(tweetId)
    return daily
  }

  resetDay() {
    this.day = DateTime.dayDifference(this.firstDate, getNowMidnight())
  }

  isCurrentDay() {
    return DateTime.dayDifference(this.firstDate, getNowMidnight()) === this.day
  }

  getTimeTillTomorrow() {
    return getTomorrowMidnight().getTime() - getNow().getTime()
  }

  async loadObject(
    username: string,
  ): Promise<Pick<GameState, 'playerState' | 'history' | 'tweetHistory'>> {
    const rawObject = await this.rawLoadObject(username)
    return this.transformObject(rawObject)
  }

  private transformObject(object: Awaited<ReturnType<Daily['rawLoadObject']>>) {
    if (!object.tweetHistory) {
      object.tweetHistory = {}
    }

    if (!(object.playerState.wins instanceof Array)) {
      object.playerState.wins =
        Object.values(object.playerState.wins as Record<string, Wins>)[0] ||
        newWins()
    }

    return object
  }

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async rawLoadObject(
    username: string,
  ): Promise<Pick<GameState, 'playerState' | 'history' | 'tweetHistory'>> {
    try {
      const state = (await playpass.storage.get(
        username.toLowerCase() + TAG_SUFFIX,
      )) as SerializedState
      const newState = {
        words: [''],
        marks: [],
        currentStreak: 0,
        maxStreak: 0,
        wins: newWins(), // wins count for each successful attempt
        day: 0,
      } as GameState['playerState']
      if (!state) {
        return {
          playerState: {
            ...newState,
            day: this.day,
          },
          history: {},
          tweetHistory: {},
        }
      } else if (state.playerState.day !== this.day) {
        // If they already played this day in the past, rehydrate history
        if (state.history[this.day] !== undefined) {
          return {
            playerState: {
              ...newState,
              ...state.playerState,
              words: state.history[this.day].words,
              marks: state.history[this.day].marks,
              day: this.day,
            },
            history: state.history,
            tweetHistory: state.tweetHistory,
          }
        }

        // No history, fresh game
        return {
          playerState: {
            ...newState,
            ...state.playerState,
            words: [''],
            marks: [],
            day: this.day,
          },
          history: state.history,
          tweetHistory: state.tweetHistory,
        }
      } else if (this.tweetId !== -1) {
        // If there is a valid tweetId and it has already been played, rehydrate history
        if (state.tweetHistory[this.tweetId] !== undefined) {
          return {
            playerState: {
              ...newState,
              ...state.playerState,
              words: state.tweetHistory[this.tweetId].words,
              marks: state.tweetHistory[this.tweetId].marks,
              day: -1,
            },
            history: state.history,
            tweetHistory: state.tweetHistory,
          }
        }

        // No tweet history, start fresh tweet game
        return {
          playerState: {
            ...newState,
            ...state.playerState,
            words: [''],
            marks: [],
            day: -1,
          },
          history: state.history,
          tweetHistory: state.tweetHistory,
        }
      } else {
        return {
          playerState: {
            ...newState,
            ...state.playerState,
          },
          history: state.history,
          tweetHistory: state.tweetHistory,
        }
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(username: string, state: SerializedState) {
    // HACK - need to eventually move to lower case storage, but double write it for now
    // to try to preserve play history
    const lcUsername = username.toLowerCase()
    await playpass.storage.set(lcUsername + TAG_SUFFIX, state)
  }

  /** Gets a random number between 0 and 1 unique to this day. */
  random() {
    return ((1103515245 * this.day + 12345) >>> 0) / 0xffffffff
  }

  processWins(state: State) {
    const { wins } = state

    const emojiBadge = []
  }
}
