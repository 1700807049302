export const getDescriptionText = (profile?: string) => {
  if (!profile) {
    return 'Love Wordle? Love browsing Twitter? Then Tweedle is the game for you! Play daily word puzzles for all your favorite profiles on social media!'
  }

  return `@${profile}'s Tweedle: Play daily word puzzles for @${profile}'s Twitter profile. Come back everyday for a new puzzle!`
}

export const getTitleText = (profile?: string) => {
  if (!profile) {
    return 'Tweedle: A daily word game for your favorite Twitter profiles!'
  }

  return `Tweedle for @${profile}: A daily word game based on @${profile}'s tweets.`
}
