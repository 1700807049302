import { PlaySourceType } from './features/profiles/profilesSlice'

export const WALLET_PATH = '/wallet'
export const WALLET_HELP_PATH = '/wallet/help'
export const STATS_PATH = '/stats'
export const HELP_PATH = '/help'
export const SETTINGS_PATH = '/settings'
export const HOME_PATH = '/'
export const DEBUG_PATH = '/__debug'
export const TWEET_PATH = '/tweet'
export const ME_PATH = '/me'

export function profilePath(u: string, source?: PlaySourceType) {
  if (source) {
    return '/' + u + `?source=${source}`
  }
  return '/' + u
}
