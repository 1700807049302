import {
  configureStore,
  ThunkAction,
  Action,
  createListenerMiddleware,
} from '@reduxjs/toolkit'
import gameReducer from '../features/game/gameSlice'
import nuxReducer from '../features/nux/nuxSlice'
import profilesReducer from '../features/profiles/profilesSlice'
import userReducer from '../features/user/userSlice'
import entryReducer from '../features/entry/entrySlice'
import leaderboardReducer from '../features/leaderboard/leaderboardSlice';

export const listenerMiddleware = createListenerMiddleware()
export const store = configureStore({
  reducer: {
    game: gameReducer,
    nux: nuxReducer,
    profiles: profilesReducer,
    user: userReducer,
    entry: entryReducer,
    leaderboard: leaderboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})

export type StoreType = typeof store
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
