type WinRecord = { day: number; guesses: number; win: boolean }
type Wins = WinRecord[][]

export function getWinPercentage(wins: Wins): number {
  const profileWins = wins

  const [numberWon, totalGames] = profileWins.reduce(
    (prev, curr) => {
      const [wins, totalGames] = curr.reduce(
        (p, c) => {
          return [p[0] + (c.win ? 1 : 0), p[1] + 1]
        },
        [0, 0],
      )
      return [prev[0] + wins, prev[1] + totalGames]
    },
    [0, 0],
  )
  console.log('numberWon', numberWon, 'totalGames', totalGames)
  return totalGames > 0 ? Math.floor((numberWon / totalGames) * 100) : 0
}
