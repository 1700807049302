import { useAppSelector } from '../app/hooks'
import Panel from '../components/Panel'
import ShareButton from '../components/share/ShareButton'
import { GUESS_LENGTHS } from '../config/game'
import {
  GameState,
  selectGameState,
  selectLevelState,
  selectPlayerState,
} from '../features/game/gameSlice'
import { selectCurrentProfile } from '../features/profiles/profilesSlice'
import { getShareButtonText, getShareButtonTextFromGame } from './shareCTAs'

const numberLanguage = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
  7: '7th',
  8: '8th',
  9: '9th',
}

const Distribution = ({
  state,
  setup,
  numberWon,
}: {
  state: GameState['playerState']
  setup: GameState['levelState']
  numberWon: number
}) => {
  const guessDistributions = state.wins.map((i) => {
    return i.reduce((p, c) => (c.win ? p + 1 : p), 0)
  })

  const [bars, text] = guessDistributions.slice(0, 9).reduce(
    (p, d, i) => {
      p[0].push(
        <div key={'bar' + i} className="barBackground">
          <div
            className="bar"
            style={{ width: Math.floor(100 * (d / numberWon)) + '%' }}
          >
            {' '}
          </div>
        </div>,
      )

      p[1].push(
        <div key={'stat' + i} className="stat">
          {d} {d === 1 ? 'win' : 'wins'} on{' '}
          {numberLanguage[(i + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9]} guess
        </div>,
      )

      return p
    },
    [[] as Array<JSX.Element>, [] as Array<JSX.Element>],
  )

  return (
    <div className="distributions">
      <div className="bars">{bars}</div>
      <div className="text">{text}</div>
    </div>
  )
}

const StatsView = (props: { onBack: () => void }) => {
  const state = useAppSelector(selectPlayerState)
  const setup = useAppSelector(selectLevelState)
  const gameState = useAppSelector(selectGameState)
  const currentPlayProfile = useAppSelector(selectCurrentProfile).data!

  const { wins } = state
  const profileWins = wins
  const [numberWon, totalGames] = profileWins.reduce(
    (prev, curr) => {
      const [wins, totalGames] = curr.reduce(
        (p, c) => {
          return [p[0] + (c.win ? 1 : 0), p[1] + 1]
        },
        [0, 0],
      )

      return [prev[0] + wins, prev[1] + totalGames]
    },
    [0, 0],
  )

  return (
    <div className="statsView">
      <Panel onClose={() => props.onBack()} showClose={false}>
        <h3>{`${currentPlayProfile.username} Play Statistics`}</h3>
        <div className="metrics">
          <div className="wins">
            <div>Games Won</div>
            <div>{`${numberWon} / ${totalGames}`}</div>
          </div>
          <div className="streak">
            <div>Max Streak</div>
            <div>{state.maxStreak}</div>
          </div>

          <div className="winPerc">
            <div>Win %</div>
            <div>
              {totalGames !== 0
                ? Math.floor((numberWon / totalGames) * 100)
                : 0}
            </div>
          </div>
        </div>
        <h2>Guess Distribution</h2>
        <Distribution numberWon={numberWon} state={state} setup={setup} />
      </Panel>
      <ShareButton
        buttonClass="primary share"
        id="share"
        feature="share-stats"
        subfeature="share-sheet"
        label="Share"
        shareTextTemplate={getShareButtonTextFromGame(
          currentPlayProfile,
          state,
          setup,
          gameState,
        )}
        payload={{}}
        shareOptions={{
          type: 'any',
        }}
      />
    </div>
  )
  return <div />
}
export default StatsView
