import { setLeaderboardUsername } from "../features/leaderboard/leaderboardSlice";
import { startAppListening } from "./middleware";
import * as playpass from 'playpass';


const addLeaderboardMiddleware = () => {
  startAppListening({
    actionCreator: setLeaderboardUsername,
    effect: (action, listenerApi) => {
      playpass.leaderboards.setProfileData({
        name: action.payload.name,
      }).then(() => {
        playpass.analytics.track('SetLeaderboardName', {
          name: action.payload.name,
          isDefault: !!action.payload.isDefault,
        })
      })
    },
  })
};

export default addLeaderboardMiddleware;
