import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import AppPather from './AppPather'
import reportWebVitals from './reportWebVitals'
import './index.css'
import * as playpass from 'playpass'
import { Daily, getNowMidnight } from './storage/daily'
import { BrowserRouter } from 'react-router-dom'
import { isAnyOf } from '@reduxjs/toolkit'
import { addAppListener } from './app/middleware'
import { initGCInstant } from 'playpass/dist/esm/gcinstant'
import { handleVisibilityChange } from './util/pageVisibility'
import { NuxStorage } from './storage/nux'
import { ProfilesStorage } from './storage/profiles'
import {
  markInitialLoad,
  resetFromStorage as resetNuxFromStorage,
} from './features/nux/nuxSlice'
import { resetFromStorage as resetProfilesFromStorage } from './features/profiles/profilesSlice'
import {
  login,
  logout,
  resetFromStorage as resetUserFromStorage,
} from './features/user/userSlice'
import { abTestConfig } from './abTests'
import { makeRandomGenerator } from './util/prng'

import { initGTM } from 'playpass/dist/esm/gtm'
import { resetEntryLinkPayload } from './features/entry/entrySlice'
import addAnalyticsMiddleware from './app/addAnalyticsMiddleware'
import addStorageMiddleware from './app/addStorageMiddleware'
import UserStorage from './storage/UserStorage'
import addUserPropertiesMiddleware from './app/addUserPropertiesMiddleware'
import { getStandardEntryUserProperties } from './util/analytics'
import ErrorBoundary from './components/ErrorBoundary'
import addStatsMiddleware from './app/addStatsMiddleware'
import addLeaderboardMiddleware from './app/addLeaderboardMiddleware'
import { LeaderboardStorage } from './storage/leaderboard'
import { resetLeaderboardFromStorage, setLeaderboardUsername } from './features/leaderboard/leaderboardSlice'
import { generateRandomPlayerName } from './util/leaderboard'

function isPlaypassDomain() {
  return document.location.hostname.indexOf('playpass.games') !== -1
}

let d: Daily = new Daily(getNowMidnight()) // Hack

let nuxStorage: NuxStorage = new NuxStorage()
let profilesStorage: ProfilesStorage = new ProfilesStorage()
let userStorage = new UserStorage()
let leaderboardStorage = new LeaderboardStorage();
let timerForTomorrow: ReturnType<typeof setTimeout> | undefined

const reload = async (source: string) => {
  d.resetDay()

  const payload = playpass.getLinkData() as Record<string, any>
  if (payload !== undefined) {
    store.dispatch(resetEntryLinkPayload(payload))
  }

  const [nux, profiles, user, leaderboard] = await Promise.all([
    nuxStorage.loadObject(),
    profilesStorage.loadObject(),
    userStorage.loadObject(),
    leaderboardStorage.loadObject(),
  ])

  store.dispatch(resetProfilesFromStorage({ ...profiles }))
  store.dispatch(resetNuxFromStorage(nux))
  store.dispatch(resetUserFromStorage(user))
  if (leaderboard) {
    store.dispatch(resetLeaderboardFromStorage(leaderboard))
  }

  // If they dont have a username, Make a random starting one
  if (!leaderboard?.username) {
    store.dispatch(setLeaderboardUsername({ name: generateRandomPlayerName(), isDefault: true }))
  }

  if (playpass.account.isLoggedIn()) {
    store.dispatch(login(playpass.account.getPlayerId()!))
  }

  if (timerForTomorrow) {
    clearTimeout(timerForTomorrow)
    timerForTomorrow = undefined
  }
  timerForTomorrow = setTimeout(
    () => reload('daily-timer'),
    d.getTimeTillTomorrow() + 1000,
  )

  store.dispatch(markInitialLoad())
  playpass.analytics.track('AppReloaded', { source })
}

const container = document.getElementById('root')!
const root = createRoot(container)
const setup = async () => {
  initGTM({ tagId: 'GTM-MVW4XC7' })
  await playpass.init({ gameId: 'da5344ae-3f2d-43e6-b048-4774c641e250' })

  await initGCInstant({
    abTestConfig,
    amplitude:
      process.env.NODE_ENV === 'development' || isPlaypassDomain()
        ? 'b0800bf1fee8ad2e24e4f087722c2585'
        : '950cc8309e8c9d8c5f38a3b815448242',
    hashFunction: makeRandomGenerator,
    entryFinalProperties: getStandardEntryUserProperties(),
    version: process.env.REACT_APP_VERSION,
  })

  // Experimental: Detect Twitter webviews on iOS/Mac by setting a user property
  function detectTwitter() {
    playpass.analytics.setUserProperties({ maybeTwitterWebview: true })
  }
  if (!(window as any).al_onAdViewRendered) {
    ;(window as any).al_onAdViewRendered = detectTwitter
  }
  if (!(window as any).al_onPoststitialDismiss) {
    ;(window as any).al_onPoststitialDismiss = detectTwitter
  }

  // ORDER IS VERY IMPORTANT - DO NOT CHANGE
  addLeaderboardMiddleware();
  addStatsMiddleware(store)
  addStorageMiddleware(store)
  addUserPropertiesMiddleware(store) // MUST COME BEFORE ANALYTICS
  addAnalyticsMiddleware(store)

  store.dispatch(
    addAppListener({
      matcher: isAnyOf(login, logout),
      effect: (action, listenerApi) => {
        const oldLoginState = listenerApi.getOriginalState().user.loginState
        const { loginState } = listenerApi.getState().user

        if (
          oldLoginState.isLoggedIn !== loginState.isLoggedIn ||
          oldLoginState.playerID !== loginState.playerID
        ) {
          // reload('login').catch(console.error)
        }
      },
    }),
  )

  await reload('page-load')
}

setup().catch(console.error)

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <AppPather />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
