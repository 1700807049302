import { useAppDispatch, useAppSelector } from '../app/hooks'
import './SettingsView.css'

import * as playpass from 'playpass'
import { login, logout, selectLoginState } from '../features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { DEBUG_PATH } from '../Paths'

const SettingsView = (props: { onBack: () => void }) => {
  const loggedInState = useAppSelector(selectLoginState)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const playerID = playpass.account.getPlayerId()
  let currentTime: number | null = null

  return (
    <div className="screen" id="settingsScreen">
      <h2
        onPointerDown={() => {
          currentTime = Date.now()
        }}
        onPointerUp={() => {
          if (currentTime && Date.now() - currentTime > 3000) {
            navigate(DEBUG_PATH)
          }
          currentTime = null
        }}
      >
        Settings
      </h2>
      <div className="settings">
        {!loggedInState.isLoggedIn && (
          <div className="showIfLoggedOut">
            <div>Login to save or transfer your game progress.</div>
            <button
              id="loginBtn"
              onClick={async () => {
                const didLogin = await playpass.account.login()
                if (didLogin) {
                  dispatch(login(playpass.account.getPlayerId()!))
                }
              }}
            >
              📱 Login
            </button>
          </div>
        )}
        {loggedInState.isLoggedIn && (
          <div className="showIfLoggedIn">
            <div>You are logged in, your progress will be saved.</div>
            <button
              id="logoutBtn"
              onClick={() => {
                playpass.account.logout()
                dispatch(logout())
              }}
            >
              Logout
            </button>
          </div>
        )}
        <button
          className="primary outline"
          id="settingsBackBtn"
          onClick={() => props.onBack()}
        >
          Back
        </button>
      </div>
      <div className="moreinfo">
        <a
          target="_blank"
          href="https://twitter.com/playtweedlenow?ref_src=twsrc%5Etfw"
        >
          <button className="twitter">Follow us on Twitter</button>
        </a>
        {playerID && <p style={{ fontSize: '10px' }}>Player ID: {playerID}</p>}
      </div>
    </div>
  )
}
export default SettingsView
