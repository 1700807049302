import { Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../app/hooks'
import AnnouncementToast from '../components/AnnouncementToast'
import SizeAwareDiv from '../components/SizeAwareDiv'
import { selectNuxState } from '../features/nux/nuxSlice'
import { ProfileSection } from '../features/profiles/ProfileSection'
import {
  useFavoriteProfiles,
  useRecommendedProfiles,
} from '../features/profiles/profilesHooks'
import './HomeView.css'
import CustomProfileItem from '../features/profiles/CustomProfileItem'
import { useSearchParam } from '../util/hooks'
import * as playpass from 'playpass'
import { profilePath } from '../Paths'
import { PlaySource } from '../features/profiles/profilesSlice'

export const HomeViewAnnouncement = ({
  defaultProfile,
}: {
  defaultProfile?: string
}) => {
  return (
    <AnnouncementToast
      title="Welcome to Tweedle"
      subtitle="Play word games for your favorite people on Twitter!"
    >
      <CustomProfileItem defaultProfile={defaultProfile} />
    </AnnouncementToast>
  )
}

const HomeView = () => {
  const navigate = useNavigate()
  const favorites = useFavoriteProfiles()

  const filterParam = useSearchParam('filter')
  const orderParam = useSearchParam('order')
  const profileMode = useSearchParam('profileMode')

  // Filter and sort the recommended profiles
  const recommended = useRecommendedProfiles({
    filter: filterParam,
    order: orderParam,
  })

  // configure what the default profile is
  const defaultProfile =
    profileMode === 'firstrec' && recommended?.data?.sections?.length
      ? recommended.data?.sections[0].profiles[0]?.profile?.username.slice(1)
      : undefined

  const nuxState = useAppSelector(selectNuxState)

  return (
    <Fragment>
      <SizeAwareDiv
        key={
          nuxState.homeViewAnyProfileAnnouncementSeen
            ? 'homeViewScrollNoAnnounce'
            : 'homeViewScrollWithAnnounce'
        }
        className="homeView"
        render={(w, h) => {
          return (
            <div
              className="homeViewScroll"
              style={{ width: w + 'px', height: h + 'px' }}
            >
              <HomeViewAnnouncement defaultProfile={defaultProfile} />
              {favorites && favorites.length > 0 && (
                <ProfileSection
                  id="recent"
                  name="Recent"
                  profiles={favorites}
                  onTap={(u) => {
                    playpass.analytics.track('PlayProfileClicked', {
                      profile: u,
                      section: 'Favorites',
                    })
                    navigate(profilePath(u, PlaySource.HOME_SCREEN_RECENT))
                  }}
                />
              )}
              {recommended &&
                recommended.data &&
                recommended.data!.sections.map((s) => {
                  return (
                    <ProfileSection
                      id={s.id}
                      key={s.id}
                      name={s.name}
                      profiles={s.profiles}
                      onTap={(u) => {
                        playpass.analytics.track('PlayProfileClicked', {
                          profile: u,
                          section: s.name,
                        })
                        navigate(
                          profilePath(u, PlaySource.HOME_SCREEN_RECOMMENDED),
                        )
                      }}
                    />
                  )
                })}
              {(recommended.fetchStatus === 'PENDING' ||
                recommended.fetchStatus === 'FETCHING') && <p>Loading</p>}
              {recommended.fetchStatus === 'COMPLETE' &&
                !recommended.data?.sections && (
                  <p>Could not fetch recommended profiles</p>
                )}
            </div>
          )
        }}
      />
    </Fragment>
  )
}
export default HomeView
