import * as playpass from 'playpass'
import { useMemo, useState } from 'react'
import { ABKeys, getBucketID } from '../../abTests'
import { remapBestShareType } from '../../util/analytics'
import useShareImage from '../../util/useShareImage'
import shareClicked from './shareClicked'
import { ExtraEventProps, ShareOptions } from './ShareTypes'
import ShareIcon from '../../skin/assets/shareIcon.png';

export type ShareButtonProps = {
  buttonClass: string
  id?: string
  feature: string
  subfeature: string
  label: string
  shareTextTemplate: string
  extraEventProps?: ExtraEventProps
  payload: Record<string, any>
  shareOptions: ShareOptions
  onPostShare?: () => void
  withShareIcon?: boolean;
}

const ShareButton = ({
  id,
  buttonClass,
  feature,
  subfeature,
  label,
  shareTextTemplate,
  extraEventProps,
  shareOptions,
  payload,
  onPostShare,
  withShareIcon = false,
}: ShareButtonProps) => {
  const [sending, setSending] = useState(false)
  const computedPayload = useMemo(() => ({
    ...payload,
    feature,
    subfeature,
    platformSocialNetwork: remapBestShareType(
      playpass.device.getBestShareType(),
    ),
  }), [
    feature,
    subfeature,
    payload,
  ]);
  const imageData = useShareImage();

  // This is a hack to make sharing work correctly from within twitter.
  const link = useMemo(() => {
    if (
      shareOptions.type !== 'twitter' ||
      playpass.device.getBestShareType() !== 'twitter'
    ) {
      return undefined
    }

    let openGraph = {};
    if (getBucketID(ABKeys.OPEN_GRAPH) === 'variant') {
      openGraph = {
        title: 'Tweedle App',
        description: 'Love Wordle? Love browsing Twitter? Then Tweedle is the game for you! Play daily word puzzles for all your favorite profiles on social media!',
        image: imageData.imageURL,
      };
    }

    return playpass.createLink({
      ...openGraph,
      data: {
        ...computedPayload,
        destination: shareOptions.type === 'twitter' ? 'TWITTER' : 'UNKNOWN',
      },
      url: document.location.pathname,
    })
  }, [
    shareOptions.type,
    imageData.imageURL,
    computedPayload
  ])

  if (imageData.status === 'loading') {
    return null;
  }

  return (
    <button
      className={buttonClass}
      id={id}
      disabled={sending}
      onClick={() => {
        setSending(true)
        shareClicked(
          shareTextTemplate,
          shareOptions,
          { ...extraEventProps, feature, subfeature },
          computedPayload,
          link,
          imageData.imageURL,
        ).then(() => {
          onPostShare && onPostShare()
          setSending(false)
        })
      }}
    >
      {withShareIcon && (
        <img src={ShareIcon} alt='share' className='shareicon' />
      )}
      {!sending ? label : 'Launching...'}
    </button>
  )
}

export default ShareButton
