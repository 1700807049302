import * as playpass from 'playpass'
import { initialState, UserState } from '../features/user/userSlice'

const TAG = 'user_v1'

export default class UserStorage {
  constructor() {}

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<UserState> {
    try {
      const state = (await playpass.storage.get(TAG)) as UserState
      if (state) {
        return state
      }

      return initialState
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: UserState) {
    await playpass.storage.set(TAG, state)
  }
}
