import { LevelV2 } from '../../../../backend/src/types'
import format from 'date-fns/format'
import { GameTweetHistoryValue } from '../game/gameSlice'

export type Tweet = LevelV2['tweet']

export const getRetweetOriginalAuthor = (tweet?: Tweet) => {
  if (!tweet) {
    return undefined
  }
  const match = tweet.text.match(/^RT @([\w\d]+):/)
  return match ? '@' + match[1] : undefined
}

export const getTweetText = (tweet?: Tweet) => {
  if (!tweet) {
    return undefined
  }

  if (getRetweetOriginalAuthor(tweet) && tweet.referenced_tweets?.length) {
    return tweet.referenced_tweets![0].text || tweet.text
  }
  return tweet.text
}

export const getTimeElapsed = (tweet?: Tweet) => {
  if (!tweet) {
    return undefined
  }

  const timeDeltaSeconds = Math.floor(
    (new Date().getTime() - new Date(tweet.created_at).getTime()) / 1000,
  )

  if (timeDeltaSeconds < 60) {
    return '' + timeDeltaSeconds + 's'
  } else if (timeDeltaSeconds < 60 * 60) {
    return '' + Math.floor(timeDeltaSeconds / 60) + 'm'
  } else if (timeDeltaSeconds < 60 * 60 * 24) {
    return '' + Math.floor(timeDeltaSeconds / 3600) + 'h'
  } else {
    return format(new Date(tweet.created_at), 'MMM d')
  }
}

export const getNextTweet = (tweets: string[], tweetHistory: Record<number, GameTweetHistoryValue>, tweetId: string) => {
  if (!tweetHistory) return getDifferentTweet(tweets, tweetId)

  for (let tweet of tweets) {
    if (!tweetHistory[parseInt(tweet)] || tweetHistory[parseInt(tweet)].gameState === 'PENDING') {
      return tweet
    }
  }
  return getDifferentTweet(tweets, tweetId)
}

const getDifferentTweet = (tweets: string[], tweetId: string) => {
  let nextTweetId = ''
  if (tweetId) {
    if (tweets.indexOf(tweetId) === -1) {
      nextTweetId = tweets[0]
    } else if (tweets.indexOf(tweetId) === tweets.length - 1) {
      nextTweetId = tweets[0]
    } else {
      const index = tweets.indexOf(tweetId);
      nextTweetId = tweets[index + 1]
    }
  }

  return nextTweetId
}
