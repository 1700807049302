import cellGreen from './assets/cellGreen.png'
import cellYellow from './assets/cellYellow.png'
import cellMissing from './assets/cellMissing.png'

export const BullLetterStyle = {
  backgroundSize: 'cover',
  backgroundColor: 'var(--green-color)',
  color: 'var(--cell-color-found)',
}

export const CowLetterStyle = {
  backgroundSize: 'cover',
  backgroundColor: 'var(--yellow-color)',
  color: 'var(--cell-color-found)',
}

export const MissedLetterStyle = {
  backgroundSize: 'cover',
  backgroundColor: 'var(--missing-color)',
  color: 'var(--cell-color-missed)',
}
