import classNames from 'classnames'
import {
  CurrentPlayProfile,
  Profile,
  Tweet,
} from './profilesSlice'
import styleText from './styleText'
import unescape from 'lodash/unescape'
import {
  getRetweetOriginalAuthor,
  getTimeElapsed,
} from './tweetUtils'
import {
  GameState,
  selectIsHardMode,
  setIsHardMode,
} from '../../features/game/gameSlice'
import ShareButton from '../../components/share/ShareButton'
import { getReplyShareButtonTextFromGame } from '../../views/shareCTAs'
import ToggleSwitch from '../../components/ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../app/hooks'

export const DefaultStyleOptions = {
  highlight: false,
  obfuscateTweet: 0,
  obfuscateAnswer: false,
}

const ProfileTweet = (props: {
  profile?: Profile
  username?: string
  fullName?: string
  shortText?: string
  expandedText?: string | JSX.Element
  expand?: boolean
  disableInteraction?: boolean
  tempMessage?: string
  word: string
  tweetObject?: Tweet
  linkToTwitter: boolean
  gameData?: {
    state: GameState['playerState']
    level: GameState['levelState']
    status: GameState['gameState']
    currentProfile: CurrentPlayProfile
  }
  textStyleOptions: {
    obfuscateAnswer: boolean
    obfuscateTweet: number
    highlight: boolean
  }
  whiteBackground?: boolean
  onClick?: () => void
}) => {
  const {
    profile,
    shortText,
    expandedText,
    expand,
    onClick,
    linkToTwitter,
    disableInteraction,
    tweetObject,
    whiteBackground,
  } = props
  const dispatch = useDispatch()
  const elapsedTime = getTimeElapsed(tweetObject)
  const retweetAuthor = getRetweetOriginalAuthor(tweetObject)
  const isHardMode: boolean = useAppSelector(selectIsHardMode);

  const profileTweetClasses = classNames({
    profileTweet: true,
    expanded: expand,
    collapsed: !expand,
    whiteBackground: whiteBackground,
  })

  let twitterLink = ''
  if (profile?.username && tweetObject) {
    twitterLink = `https://twitter.com/${profile?.username.slice(1)}/status/${
      tweetObject?.id
    }`
  }

  return (
    <div
      className={classNames({
        profileTweetContainer: true,
        expanded: expand,
        collapsed: !expand,
      })}
    >
      <div
        className={classNames({
          expandableOverlay: true,
          expanded: expand,
          collapsed: !expand,
        })}
      >
        <div className={profileTweetClasses}>
          <div className="imageAndButton">
            <img
              onClick={onClick}
              src={profile?.profilePictureURL || '/tweedleProfileRound.png'}
            />
            {linkToTwitter && expand && twitterLink && (
              <ShareButton
                buttonClass="twitter noimage"
                feature="tweet-view-on-twitter"
                subfeature="share-twitter"
                label={'View'}
                shareTextTemplate={
                  props.gameData
                    ? getReplyShareButtonTextFromGame(
                        props.gameData.currentProfile,
                        props.gameData.state,
                        props.gameData.level,
                        props.gameData.status,
                      )
                    : 'I played this tweet! #tweedle\n\n{{link}}'
                }
                payload={{
                  day: props.gameData ? props.gameData?.level.day : undefined,
                }}
                shareOptions={{
                  type: 'twitter',
                  inReplyTo: tweetObject?.id,
                }}
              />
            )}
            {!linkToTwitter && expand && !disableInteraction && (
              <ToggleSwitch
                name='hardmode'
                checked={isHardMode}
                onChange={(isChecked) => {
                  dispatch(setIsHardMode(isChecked));
                }}
              />
            )}
          </div>
          <div className="speechBubble" onClick={onClick}>
            <div className="header">
              <span className="name">{profile?.name || props.fullName}</span>
              <span className="username">
                {profile?.username || props.username}
              </span>
              {elapsedTime && (
                <span className="elapsedTime">
                  {' ' + String.fromCharCode(183) + ' ' + elapsedTime}
                </span>
              )}
            </div>
            <div
              className={classNames({
                tweet: true,
                expanded: expand === true,
                collapsed: expand !== true,
              })}
            >
              {expand && expandedText
                ? typeof expandedText === 'string'
                  ? styleText(unescape(expandedText), props.word, {
                      ...props.textStyleOptions,
                      retweetAuthor,
                    })
                  : expandedText
                : styleText(shortText!, '', {
                    obfuscateAnswer: false,
                    obfuscateTweet: 0,
                    highlight: false,
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProfileTweet
