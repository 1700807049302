import * as playpass from 'playpass'
import { GameState } from '../features/game/gameSlice'
import {
  FavoriteProfile,
  Profile,
  ProfilesState,
} from '../features/profiles/profilesSlice'

type State = Pick<ProfilesState, 'profileHistory' | 'favoriteProfiles'>
const TAG = 'profiles_v1'

const normalizeProfiles = (profiles: Record<string, FavoriteProfile>) => {
  let normalizedProfiles: Record<string, FavoriteProfile> = {}
  for (var item in profiles) {
    normalizedProfiles[item.toLowerCase()] = profiles[item]
  }
  return normalizedProfiles
}

export class ProfilesStorage {
  constructor() {}

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<State> {
    try {
      const state = (await playpass.storage.get(TAG)) as State
      if (state) {
        return {
          ...state,
          favoriteProfiles: normalizeProfiles(state.favoriteProfiles),
        }
      }

      return { favoriteProfiles: {}, profileHistory: {} } as State
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: State) {
    await playpass.storage.set(TAG, state)
  }
}
