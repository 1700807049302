import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProfileTweet, {
  DefaultStyleOptions,
} from '../../features/profiles/ProfileTweet'
import { getTutorialProfile } from '../../features/profiles/tutorialData'
import './MeView.css'
import * as playpass from 'playpass'
import { profilePath } from '../../Paths'
import { PlaySource } from '../../features/profiles/profilesSlice'
import { ExampleGridMulti } from '../../components/Grid'
import {
  completeTwitterLogin,
  getTwitterRedirectStatus,
  redirectForTwitterLogin,
} from '../../util/TwitterOauth'
import {
  loginWithTwitter,
  useTwitterLogin,
} from '../../features/user/userSlice'
import { useAppDispatch } from '../../app/hooks'

const ProfileEntry = (props: { defaultProfile?: string }) => {
  const sampleProfileName =
    props.defaultProfile || getTutorialProfile().slice(1)
  const [profile, setProfile] = useState('')
  const navigate = useNavigate()

  const onSubmit = () => {
    let name = profile.trimStart().trimEnd()
    if (name === '') {
      name = sampleProfileName
    }

    if (name.startsWith('@')) {
      name = name.slice(1)
    }

    playpass.analytics.track('PlayProfileManuallyClicked', {
      profile: '@' + name.toLowerCase(),
    })

    navigate(profilePath('@' + name, PlaySource.ME_SCREEN_INPUT_BOX))
  }

  return (
    <div className="profileEntry">
      <span style={{ color: '#566370' }}>{'@ '}</span>
      <input
        type="text"
        value={profile}
        placeholder={sampleProfileName}
        onChange={(e) => setProfile(e.currentTarget.value)}
        onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
      />
      <button className="secondary" onClick={onSubmit}>
        Play
      </button>
    </div>
  )
}

const SignInWithTwitterCTA = (props: {
  activeLogin: boolean
  onTwitterClick: () => void
  onManualClick: () => void
}) => {
  const { activeLogin, onTwitterClick, onManualClick } = props
  return (
    <div>
      <button
        disabled={activeLogin}
        className="twitter primary"
        onClick={onTwitterClick}
      >
        {!activeLogin ? 'Sign In with Twitter' : 'Logging in...'}
      </button>
      <div>or</div>
      <div>
        <a onClick={onManualClick}>Enter your Username</a>
      </div>
    </div>
  )
}

const EnterProfileManuallyCTA = (props: {
  activeLogin: boolean
  onTwitterClick: () => void
}) => {
  const { activeLogin, onTwitterClick } = props
  return (
    <div>
      <ProfileEntry defaultProfile="playtweedlenow" />
      <div>or</div>
      <a onClick={() => !activeLogin && onTwitterClick()}>
        {!activeLogin ? 'Sign In with Twitter' : 'Logging in...'}
      </a>
    </div>
  )
}

const MeView = () => {
  const twitterLoginStatus = getTwitterRedirectStatus()
  const twitterLogin = useTwitterLogin()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [activeLogin, setActiveLogin] = useState(
    twitterLoginStatus === 'success',
  )

  const [error, setError] = useState('')
  const [forceManual, setForceManual] = useState(false)

  const loginWithTwitterClicked = () => {
    playpass.analytics.track('SignInWithTwitterClicked')

    setActiveLogin(true)

    redirectForTwitterLogin()
  }

  useEffect(() => {
    playpass.analytics.track('MeViewLoaded', {
      twitterOAuthStatus: twitterLoginStatus,
      loggedInWithTwitter: twitterLogin !== undefined,
    })
    return () => {
      playpass.analytics.track('MeViewUnloaded', {
        twitterOAuthStatus: twitterLoginStatus,
        loggedInWithTwitter: twitterLogin !== undefined,
      })
    }
  }, [])

  useEffect(() => {
    if (twitterLogin !== undefined) {
      navigate(
        profilePath(twitterLogin.username, PlaySource.TWITTER_LOGIN_REDIRECT),
      )
    }
  }, [twitterLogin])

  useEffect(() => {
    if (twitterLoginStatus === 'success') {
      playpass.analytics.track('TwitterLoginRedirectBegin')
      completeTwitterLogin()
        .then((result) => {
          if (result) {
            dispatch(
              loginWithTwitter({
                id: result.id,
                username: result.username,
                accessToken: result.accessToken,
                accessSecret: result.accessSecret,
              }),
            )
          } else {
            playpass.analytics.track('TwitterLoginRedirectError')
            // handle error
            setActiveLogin(false)
            setError(
              'Something went wrong logging into Twitter. Please enter your username manually.',
            )
          }
        })
        .catch((e) => {
          playpass.analytics.track('TwitterLoginRedirectError', {
            error: (e as Error).message,
          })
          console.error(e)
          // handle error
          setError(
            'Something went wrong logging into Twitter. Please enter your username manually.',
          )
          setActiveLogin(false)
        })
    } else if (twitterLoginStatus === 'failed') {
      playpass.analytics.track('TwitterLoginDenied')
    }
  }, [twitterLoginStatus])

  return (
    <div className="meView">
      <ProfileTweet
        linkToTwitter={false}
        profile={undefined}
        username={'@PlayTweedleNow'}
        fullName="tweedle"
        disableInteraction={true}
        expandedText="Tweedle is wordle for Twitter! Sign in to play your own game!"
        expand={true}
        word=""
        textStyleOptions={DefaultStyleOptions}
        whiteBackground={true}
      />
      <ExampleGridMulti
        marks={['nnnnc', 'nbncn', 'nncnn', 'bbbbb']}
        words={['GUESS', 'YOUR ', '  OWN', 'WORDS']}
        width={250}
        height={200}
      />
      {(activeLogin || !error) && !forceManual ? (
        <SignInWithTwitterCTA
          activeLogin={activeLogin}
          onTwitterClick={loginWithTwitterClicked}
          onManualClick={() => {
            playpass.analytics.track('EnterUsernameManuallyClicked')
            setForceManual(true)
          }}
        />
      ) : (
        <EnterProfileManuallyCTA
          activeLogin={activeLogin}
          onTwitterClick={loginWithTwitterClicked}
        />
      )}
    </div>
  )
}
export default MeView
