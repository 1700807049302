import { isAnyOf } from '@reduxjs/toolkit'
import {
  addLetter,
  removeLetter,
  submitAnswer,
  resetFromStorage as resetGameFromStorage,
} from '../features/game/gameSlice'
import {
  closeHomeViewAnnouncement,
  markSeenTutorial,
} from '../features/nux/nuxSlice'
import {
  addFavoriateProfile,
  clearCurrentProfile,
  fetchProfiles,
  markProfileCompleted,
  markPlayed,
  playProfile,
  playTweet,
} from '../features/profiles/profilesSlice'
import { addAppListener } from './middleware'
import { StoreType } from './store'
import { Daily } from '../storage/daily'
import DateTime from '../util/DateTime'
import { ProfilesStorage } from '../storage/profiles'
import { NuxStorage } from '../storage/nux'
import UserStorage from '../storage/UserStorage'
import {
  login,
  loginWithTwitter,
  logout,
  setUserProperty,
} from '../features/user/userSlice'
import { LeaderboardStorage } from '../storage/leaderboard'
import { setLeaderboardUsername } from '../features/leaderboard/leaderboardSlice'

const addStorageMiddleware = (store: StoreType) => {
  store.dispatch(
    addAppListener({
      matcher: isAnyOf(
        resetGameFromStorage,
        addLetter,
        removeLetter,
        submitAnswer,
        markSeenTutorial,
        markProfileCompleted,
        markPlayed,
        addFavoriateProfile,
        playProfile.pending,
        playProfile.fulfilled,
        playProfile.rejected,
        playTweet.pending,
        fetchProfiles.rejected,
        clearCurrentProfile.pending,
        clearCurrentProfile.fulfilled,
        closeHomeViewAnnouncement,
        setUserProperty,
        loginWithTwitter,
        setLeaderboardUsername,
      ),
      effect: (action, listenerApi) => {
        const state = listenerApi.getState().game.playerState
        let nuxStorage = new NuxStorage()
        let profilesStorage = new ProfilesStorage()
        let userStorage = new UserStorage()
        let leaderboardStorage = new LeaderboardStorage()

        switch (action.type) {
          case submitAnswer.type:
          case addLetter.type:
          case removeLetter.type:
          case playProfile.pending.type:
          case playTweet.pending.type:
          case clearCurrentProfile.pending.type:
            {
              const currentPlayer = listenerApi.getState().profiles
                .currentPlayProfile.data
              if (currentPlayer) {
                const d = new Daily(
                  DateTime.fromUtcToLocal(
                    currentPlayer.startDateUTC,
                    DateTime.systemTimezoneOffset(),
                  ),
                  currentPlayer.level.day,
                )
                void d.saveObject(
                  currentPlayer.username,
                  listenerApi.getState().game,
                )
              }
            }
            break
          case markPlayed.type:
          case markProfileCompleted.type:
          case addFavoriateProfile.type:
            const profiles = listenerApi.getState().profiles
            void profilesStorage.saveObject({
              favoriteProfiles: profiles.favoriteProfiles,
              profileHistory: profiles.profileHistory,
            })
            break
          case markSeenTutorial.type:
          case closeHomeViewAnnouncement.type:
            void nuxStorage.saveObject(listenerApi.getState().nux)
            break
          case login.type:
          case logout.type:
          case setUserProperty.type:
          case loginWithTwitter.type:
            void userStorage.saveObject(listenerApi.getState().user)
            break
          case setLeaderboardUsername.type:
            void leaderboardStorage.saveObject(listenerApi.getState().leaderboard)
            break;
        }
      },
    }),
  )
}

export default addStorageMiddleware
