import { isAnyOf } from '@reduxjs/toolkit'
import {
  addLetter,
  removeLetter,
  submitAnswer,
  resetFromStorage as resetGameFromStorage,
  setIsHardMode,
} from '../features/game/gameSlice'
import {
  closeHomeViewAnnouncement,
  markSeenTutorial,
} from '../features/nux/nuxSlice'
import {
  addFavoriateProfile,
  clearCurrentProfile,
  fetchProfiles,
  markProfileCompleted,
  markPlayed,
  playProfile,
  playTweet,
} from '../features/profiles/profilesSlice'
import { addAppListener } from './middleware'
import * as playpass from 'playpass'
import { StoreType } from './store'
import GameLogic from '../GameLogic'
import { isValidWord } from '../boilerplate/dictionary'
import { loginWithTwitter } from '../features/user/userSlice'

const addAnalyticsMiddleware = (store: StoreType) => {
  store.dispatch(
    addAppListener({
      matcher: isAnyOf(
        resetGameFromStorage,
        addLetter,
        removeLetter,
        submitAnswer,
        markSeenTutorial,
        markProfileCompleted,
        markPlayed,
        addFavoriateProfile,
        playProfile.pending,
        playProfile.fulfilled,
        playProfile.rejected,
        playTweet.rejected,
        fetchProfiles.rejected,
        clearCurrentProfile.pending,
        clearCurrentProfile.fulfilled,
        closeHomeViewAnnouncement,
        loginWithTwitter,
        setIsHardMode,
      ),
      effect: (action, listenerApi) => {
        const state = listenerApi.getState().game.playerState

        // Log the actions
        const oldState = listenerApi.getOriginalState().game.playerState
        const levelState = listenerApi.getState().game.levelState
        const username = listenerApi.getState().game.username
        const message = listenerApi.getState().game.message
        const isHardMode = listenerApi.getState().game.isHardMode

        switch (action.type) {
          case resetGameFromStorage.type:
            if (
              GameLogic.isSolved(state) ||
              GameLogic.isLost(state, levelState)
            ) {
              playpass.analytics.track('PuzzleAlreadyComplete', {
                guessedWords: state.marks.length,
                day: state.day,
                profile: username,
              })
            } else {
              playpass.analytics.track('PuzzleStart', {
                guessedWords: state.marks.length,
                day: state.day,
                profile: username,
              })
            }

            break
          case addLetter.type:
            const isLastLetter =
              state.words[state.words.length - 1].length ===
              levelState.word.length
            playpass.analytics.track('AddLetter', {
              row: state.marks.length + 1,
              isFirstLetter: state.words[state.words.length - 1].length === 1,
              isLastLetter,
              currentWord: state.words[state.words.length - 1],
              profile: username,
              isValidWord: isLastLetter
                ? isValidWord(state.words[state.words.length - 1])
                : undefined,
            })
            break
          case removeLetter.type:
            playpass.analytics.track('RemoveLetter', {
              currentWord: state.words[state.words.length - 1],
              profile: username,
            })
            break
          case submitAnswer.type:
            if (message.id === 'bad_length') {
              playpass.analytics.track('RowSubmissionBadLength', {
                row: state.marks.length,
                profile: username,
                currentWord: state.words[state.words.length - 1],
              })
            } else if (message.id === 'not_a_word') {
              playpass.analytics.track('RowSubmissionInvalidWord', {
                row: state.marks.length,
                profile: username,
                currentWord: state.words[state.words.length - 1],
              })
            }

            if (oldState.marks.length !== state.marks.length) {
              playpass.analytics.track('RowComplete', {
                row: state.marks.length,
                profile: username,
                word: state.words[state.words.length - 2],
              })
            }

            if (
              GameLogic.isSolved(state) ||
              GameLogic.isLost(state, levelState)
            ) {
              playpass.analytics.track('PuzzleComplete', {
                guessedWords: state.marks.length,
                day: state.day,
                profile: username,
              })
            }
            break
          case setIsHardMode.type:
            playpass.analytics.track('SetIsHardMode', {
              currentWord: state.words[state.words.length - 1],
              profile: username,
              isHardMode,
            })
            break
          case fetchProfiles.rejected.type:
            {
              const error = listenerApi.getState().profiles.recommendedProfiles
                .error
              playpass.analytics.track('ProfilesFetchError', {
                error: error?.error,
                code: error?.code,
                url: error?.urlMeta?.url,
                status: error?.urlMeta?.status,
                stack: error?.stack,
                extra: error?.extra,
              })
            }
            break
          case playProfile.rejected.type:
            const error = listenerApi.getState().profiles.currentPlayProfile
              .error
            playpass.analytics.track('ProfileLoadError', {
              profile: action?.meta?.arg?.username,
              error: error?.error,
              code: error?.code,
              url: error?.urlMeta?.url,
              status: error?.urlMeta?.status,
              stack: error?.stack,
              extra: error?.extra,
            })
            break
          case playTweet.rejected.type:
            const tweetError = listenerApi.getState().profiles
              .currentPlayProfile.error
            playpass.analytics.track('TweetLoadError', {
              profile: action?.meta?.arg?.username,
              error: tweetError?.error,
              code: tweetError?.code,
              url: tweetError?.urlMeta?.url,
              status: tweetError?.urlMeta?.status,
              stack: tweetError?.stack,
              extra: tweetError?.extra,
            })
            break
          case loginWithTwitter.type:
            playpass.analytics.track('LoggedInWithTwitter')
            break
        }
      },
    }),
  )
}

export default addAnalyticsMiddleware
