import { PlaySource, Profile } from './profilesSlice'
import './Profiles.css'
import classNames from 'classnames'
import { useState } from 'react'
import * as playpass from 'playpass'
import { useNavigate } from 'react-router-dom'
import { getTutorialProfile } from './tutorialData'
import { profilePath } from '../../Paths'

export const CustomProfileItem = ({
  defaultProfile,
}: {
  defaultProfile?: string
}) => {
  const sampleProfileName = defaultProfile || getTutorialProfile().slice(1)
  const [profile, setProfile] = useState('')
  const navigate = useNavigate()

  const onSubmit = () => {
    let name = profile.trimStart().trimEnd()
    if (name === '') {
      name = sampleProfileName
    }

    if (name.startsWith('@')) {
      name = name.slice(1)
    }

    playpass.analytics.track('PlayCustomGameClicked', {
      profile: '@' + name.toLowerCase(),
    })

    navigate(profilePath('@' + name, PlaySource.HOME_SCREEN_INPUT_BOX))
  }

  const buttonClasses = classNames({
    secondary: true,
  })

  return (
    <div className="profileItem">
      <div className="itemLeft">
        <img src="/tweedleProfileRound.png" />
        <div className="text">
          <h3>What's the profile?</h3>
          <div className="cta">
            <span style={{ color: '#566370' }}>{'@ '}</span>
            <input
              type="text"
              value={profile}
              placeholder={sampleProfileName}
              onChange={(e) => setProfile(e.currentTarget.value)}
              onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
            />
          </div>
        </div>
      </div>
      <button className={buttonClasses} onClick={onSubmit}>
        Play
      </button>
    </div>
  )
}

export default CustomProfileItem
