export enum Timezone {
  Utc = 'utc',
  ClientLocal = 'local',
}

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24

export default class DateTime<T extends Timezone> {
  private _type: T
  private _date: Date
  private _timezoneOffset: number // minutes

  private constructor(
    dateUtc: Date,
    type: T,
    timezoneOffset: number,
    doOffset: boolean,
  ) {
    if (type === Timezone.Utc && timezoneOffset !== 0) {
      throw new Error(
        `Bad date configuration for Utc - timezoneOffset should be 0, got ${timezoneOffset}`,
      )
    }

    if (doOffset) {
      this._date = new Date(dateUtc.getTime() - timezoneOffset * 60_000)
    } else {
      this._date = new Date(dateUtc.getTime())
    }

    this._type = type
    this._timezoneOffset = timezoneOffset
  }

  static nowUtc() {
    return new DateTime(new Date(), Timezone.Utc, 0, true)
  }

  static nowLocal() {
    const date = new Date()
    return new DateTime(
      date,
      Timezone.ClientLocal,
      DateTime.systemTimezoneOffset(),
      true,
    )
  }

  getTime() {
    return this._date.getTime()
  }

  static fromUtc(dateStr: string) {
    return new DateTime(new Date(dateStr), Timezone.Utc, 0, true)
  }

  static fromUtcToLocal(dateStr: string, timezoneOffset: number) {
    return new DateTime<Timezone.ClientLocal>(
      new Date(dateStr),
      Timezone.ClientLocal,
      timezoneOffset,
      true,
    )
  }

  static fromUtcDateTimeToLocal(
    dt: DateTime<Timezone.Utc>,
    timezoneOffset: number,
  ) {
    return new DateTime<Timezone.ClientLocal>(
      new Date(dt.toISOString()),
      Timezone.ClientLocal,
      timezoneOffset,
      true,
    )
  }

  toSameTimezone(utcDatetime: DateTime<Timezone.Utc>) {
    return new DateTime<T>(
      utcDatetime._date,
      this._type,
      this._timezoneOffset,
      true,
    )
  }

  /**
   * Returns the signed difference in days between the two dates. Rounds
   * to the nearest whole day (so -1.5 goes -1, 1.5 goes to 1
   */
  static dayDifference<U extends Timezone>(
    start: DateTime<U>,
    end: DateTime<U>,
  ) {
    if (start._timezoneOffset !== end._timezoneOffset) {
      throw new Error('timezones are incompatible')
    }

    const delta =
      (end._date.getTime() - start._date.getTime()) / MILLISECONDS_IN_DAY
    return delta > 0 ? Math.floor(delta) : Math.ceil(delta)
  }

  toMidnight() {
    const date = new Date(this._date.getTime())
    date.setUTCHours(0, 0, 0, 0)
    return new DateTime<T>(date, this._type, this._timezoneOffset, false)
  }

  toTomorrow() {
    const date = new Date(this._date.getTime())
    date.setUTCDate(date.getUTCDate() + 1)
    return new DateTime<T>(date, this._type, this._timezoneOffset, false)
  }

  toISOString() {
    return this._date.toISOString()
  }

  serialize() {
    return JSON.stringify({
      type: this._type,
      date: this._date.toISOString(),
      timezoneOffset: this._timezoneOffset,
    })
  }

  toUtc() {
    const date = new Date(this._date.getTime() + this._timezoneOffset * 60_000)
    return new DateTime<Timezone.Utc>(date, Timezone.Utc, 0, true)
  }

  static deserialize<T extends Timezone>(str: string, type: T) {
    try {
      const object = JSON.parse(str)
      if (typeof object !== 'object') {
        return null
      }

      if (
        !object.type ||
        !object.date ||
        typeof object.timezoneOffset !== 'number'
      ) {
        return null
      }

      if (object.type !== type) {
        return null
      }

      const date = new Date(object.date)
      if (object.type === 'utc') {
        return new DateTime<T>(date, type, object.timezoneOffset, true)
      }
      if (object.type === 'local') {
        return new DateTime<T>(date, type, object.timezoneOffset, false)
      }

      return null
    } catch (e) {
      return null
    }
  }

  static systemTimezoneOffset() {
    return new Date().getTimezoneOffset()
  }
}
