import React, { useEffect } from 'react'
import './App.css'
import './skin/skin.css'
import './boilerplate/boilerplate.css'
import SettingsView from './views/SettingsView'
import HelpView from './views/HelpView'
import StatsView from './views/StatsView'
import DailyGameView, { GameViewRedirect } from './views/DailyGameView'
import TweetGameView from './views/TweetGameView'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
  useMatch,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from './app/hooks'
import Title from './skin/Title'
import classNames from 'classnames'
import * as playpass from 'playpass'
import {
  DEBUG_PATH,
  HELP_PATH,
  HOME_PATH,
  ME_PATH,
  SETTINGS_PATH,
  STATS_PATH,
} from './Paths'
import HomeView from './views/HomeView'
import DebugView from './views/DebugView'
import { markSeenTutorial } from './features/nux/nuxSlice'
import { getTutorialProfile, getTutorialTweet } from './features/profiles/tutorialData'
import { ABKeys, getBucketID } from './abTests'
import { useUserProperty } from './features/user/userHooks'
import { UserProperties } from './features/user/userSlice'
import MeView from './views/me/MeView'

const Navigation = (props: {
  toggleScreen: (
    screen: string,
    ignoreProfile?: boolean,
    searchParams?: string,
  ) => void
  hideMenuOptions: boolean
  titlePath: string
}) => {
  const { toggleScreen, hideMenuOptions, titlePath } = props

  const inProfile = useMatch('/@:profile')
  const maybeProfile = inProfile?.params?.profile

  const location = useLocation()
  const pathname = location.pathname

  return (
    <nav>
      <div
        id="homeBtn"
        className={classNames({
          active: pathname.startsWith(HOME_PATH),
          button: true,
          hidden: hideMenuOptions,
        })}
        onClick={() => {
          !hideMenuOptions && toggleScreen(HOME_PATH)
        }}
      ></div>
      <div
        id="helpBtn"
        className={classNames({
          active: pathname.startsWith(HELP_PATH),
          button: true,
          hidden: hideMenuOptions,
        })}
        onClick={() => !hideMenuOptions && toggleScreen(HELP_PATH)}
      ></div>
      <h1
        onClick={() =>
          toggleScreen(
            titlePath,
            undefined,
            hideMenuOptions ? 'hideMenu=1' : undefined,
          )
        }
      >
        <Title />
      </h1>
      {
        <div
          id="statsBtn"
          className={classNames({
            active: pathname.startsWith(STATS_PATH),
            button: true,
            hidden: !maybeProfile || hideMenuOptions,
          })}
          onClick={() =>
            !hideMenuOptions && maybeProfile && toggleScreen(STATS_PATH)
          }
        ></div>
      }

      <div
        id="settingsBtn"
        className={classNames({
          active: pathname.startsWith(SETTINGS_PATH),
          button: true,
          hidden: hideMenuOptions,
        })}
        onClick={() => !hideMenuOptions && toggleScreen(SETTINGS_PATH)}
      ></div>
    </nav>
  )
}

type AppProps = {
  needsTutorial?: boolean
}
function App({ needsTutorial }: AppProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  const currentPlayUsername = useAppSelector(state => state?.profiles?.currentPlayProfile?.data?.username);

  const searchParams = new URLSearchParams(location.search)
  let profileSearchParam = searchParams.get('profile')
  let tweetIdSearchParam = searchParams.get('tweetId')
  let hideMenu = searchParams.get('hideMenu')

  const hideMenuFtueAB = true
  const firstCompletedProfile = useUserProperty(
    UserProperties.FIRST_COMPLETED_PROFILE,
  )

  if (profileSearchParam) {
    profileSearchParam = profileSearchParam.toLowerCase()
  }

  const dispatch = useAppDispatch()

  const toggleScreen = (
    screen: string,
    ignoreProfile?: boolean,
    paramString?: string,
    profile?: string,
  ) => {
    if (pathname === HELP_PATH && needsTutorial) {
      dispatch(markSeenTutorial())
      playpass.analytics.track('TutorialHide', {
        profile: profileSearchParam,
        tweetId: tweetIdSearchParam,
      })
    }
    if (pathname === screen) {
      playpass.analytics.track('PageHide', { page: screen })

      if (pathname === HELP_PATH && ignoreProfile && profile && typeof(profile) === 'string') {
        // Default to profile daily tweet
        // playpass.analytics.track('PageShow', { page: '/' + profile })
        // navigate(
        //   '/' + profile + (paramString ? '?' + paramString : ''),
        // )
        playpass.analytics.track('PageShow', {
          page: '/tweet/' + getTutorialTweet(profile),
        })
        navigate(
          '/tweet/' + getTutorialTweet(profile) + (paramString ? '?' + paramString : ''),
        )
      } else if (profileSearchParam !== null && ignoreProfile) {
        playpass.analytics.track('PageShow', { page: '/' + profileSearchParam })
        navigate(
          '/' + profileSearchParam + (paramString ? '?' + paramString : ''),
        )
      } else if (tweetIdSearchParam !== null) {
        playpass.analytics.track('PageShow', {
          page: '/tweet/' + tweetIdSearchParam,
        })
        navigate(
          '/tweet/' +
            tweetIdSearchParam +
            (paramString ? '?' + paramString : ''),
        )
      } else if (pathname === HELP_PATH && needsTutorial && !ignoreProfile) {
        // Default to profile daily tweet
        // playpass.analytics.track('PageShow', {
        //   page: '/' + getTutorialProfile(),
        // })
        // navigate(
        //   '/' + getTutorialProfile() + (paramString ? '?' + paramString : ''),
        // )
      
        playpass.analytics.track('PageShow', {
          page: '/tweet/' + getTutorialTweet(),
        })
        navigate(
          '/tweet/' + getTutorialTweet() + (paramString ? '?' + paramString : ''),
        )
      } else {
        playpass.analytics.track('PageShow', { page: '/' })
        navigate('/')
      }
    } else {
      if (screen.startsWith(HELP_PATH)) {
        playpass.analytics.track('TutorialShow', {
          profile: profileSearchParam,
          tweetId: tweetIdSearchParam,
        })
      }
      playpass.analytics.track('PageHide', { page: pathname })
      playpass.analytics.track('PageShow', { page: screen })
      navigate(screen)
    }
  }

  return (
    <div className="App">
      <header>
        <Navigation
          toggleScreen={toggleScreen}
          hideMenuOptions={
            (needsTutorial === undefined
              ? false
              : needsTutorial && hideMenuFtueAB) ||
            (hideMenu !== null && !firstCompletedProfile)
          }
          titlePath={needsTutorial && hideMenuFtueAB ? HELP_PATH : currentPlayUsername ? `/${currentPlayUsername}` : HOME_PATH}
        />
      </header>
      <div className="mainArea">
        <Routes>
          <Route
            path={HELP_PATH}
            element={
              <HelpView
                profile={profileSearchParam || undefined}
                tweetId={tweetIdSearchParam || undefined}
                onHome={(profile) => {
                  toggleScreen(HELP_PATH, /* ignoreProfile */ true, undefined, profile)
                }}
                onBack={(profile) => {
                  const params = new URLSearchParams()
                  if (hideMenuFtueAB) {
                    params.append('hideMenu', '1')
                  }

                  if (searchParams.get('source')) {
                    params.append('source', searchParams.get('source')!)
                  }

                  toggleScreen(HELP_PATH, true, params.toString(), profile)
                }}
              />
            }
          />
          <Route
            path={STATS_PATH}
            element={<StatsView onBack={() => toggleScreen(STATS_PATH)} />}
          />
          <Route
            path={SETTINGS_PATH}
            element={
              <SettingsView onBack={() => toggleScreen(SETTINGS_PATH)} />
            }
          />
          <Route path={DEBUG_PATH} element={<DebugView key={'debug'} />} />
          <Route path="/p/:profile" element={<GameViewRedirect />} />
          <Route path="/@:profile/:day" element={<DailyGameView />} />
          <Route path="/@:profile" element={<DailyGameView />} />
          <Route path="/home" element={<HomeView key={'home'} />} />
          <Route path={HOME_PATH} element={<HomeView key={'home'} />} />
          <Route path="/tweet/:tweetId" element={<TweetGameView />} />
          <Route path={ME_PATH} element={<MeView />} />
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={{ pathname: HOME_PATH, search: location.search }}
              />
            }
          />
        </Routes>
      </div>
    </div>
  )
}

export default App
