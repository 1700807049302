import * as playpass from 'playpass'
import { LeaderboardState } from '../features/leaderboard/leaderboardSlice'

const TAG = 'leaderboard_v1'

export class LeaderboardStorage {

  /** Loads an object from storage */
  async loadObject(): Promise<LeaderboardState | undefined> {
    try {
      const state = (await playpass.storage.get(TAG)) as LeaderboardState | undefined
      return state
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage */
  async saveObject(state: LeaderboardState) {
    await playpass.storage.set(TAG, state)
  }
}
