import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export type EntryState = {
  linkPayload: Record<string, any> & LinkPayload
}

export type LinkPayload = {
  day?: string
  destination?: 'TWITTER' | 'UNKNOWN'
}

const initialState = { linkPayload: {} } as EntryState

export const entrySlice = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    resetEntryLinkPayload: (
      state: EntryState,
      action: PayloadAction<EntryState['linkPayload']>,
    ) => {
      state.linkPayload = action.payload
    },

    clearEntryDay: (state: EntryState) => {
      state.linkPayload.day = undefined
    },
  },
})

export const { resetEntryLinkPayload, clearEntryDay } = entrySlice.actions

export const selectEntry = (state: RootState) => state.entry

export default entrySlice.reducer
