import {Component, ErrorInfo, ReactNode } from 'react';
import * as playpass from 'playpass';
import ErrorView from './ErrorView';

type Props = {children: ReactNode}
type State = {hasError: boolean}
export default class ErrorBoundary extends Component<{children: ReactNode}, {}> {
  constructor(props: Props) {
    super(props);
  }

  public state: State = {
    hasError: false,
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    playpass.analytics.track('ReactError', {
      message: error.message,
      stack: error.stack,
      name: error.name,
      cause: error.cause,
      componentStack: errorInfo.componentStack,
    })
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="loadingScreen">
          <img src={'/error.png'} />
          <p>Something went wrong!</p>
          <button onClick={() => document.location.reload()}className="primary">
            Try Again
          </button>
        </div>
      )
    }
    return this.props.children; 
  }
}
