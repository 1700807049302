import { FC } from "react";

interface Props {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

const ToggleSwitch: FC<Props> = ({ name, checked, onChange }) => {
  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <label className="toggle-switch-label" htmlFor={name}>
        <span className="toggle-switch-inner" data-no='Easy' data-yes='Hard' />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
}

export default ToggleSwitch;
