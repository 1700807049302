export const isGoogleBot = () => {
  return window.navigator.userAgent === 'Googlebot'
}

export const gtagEvent = (event: string) => {
  const dataLayer = (window as any).dataLayer
  if (dataLayer) {
    dataLayer.push({
      event,
    })
  }
}
