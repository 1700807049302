import * as playpass from 'playpass'

export const incrementPlayerScore = async (profile: string, winRate: number) => {
  const playerId = playpass.account.getPlayerId();
  const leaderboard = playpass.leaderboards.getLeaderboard(profile);
  const playerRecords = playerId && await leaderboard.getRecords([playerId]);
  const score = (playerRecords && playerRecords[0] && playerRecords[0].score) || 0;
  leaderboard.submitScore(score + 1,  {
    recordData: {
      accuracy: winRate
    }
  });
}

export const generateRandomPlayerName = (numLength = 6) => {
  let result = 'Player';
  const chars = '1234567890';
  for (let i = 0; i < numLength; i += 1) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}