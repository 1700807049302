import * as playpass from 'playpass'

export async function timeCallAsync<T>(
  func: () => Promise<T>,
  options?: {
    slowTimeoutDebug?: number
    extra?: any
  },
): Promise<[T, number]> {
  let timeout: NodeJS.Timeout | null = null
  if (options?.slowTimeoutDebug) {
    timeout = setTimeout(() => {
      playpass.analytics.track('DebugAPISlow', options?.extra)
    }, options.slowTimeoutDebug)
  }

  const now = Date.now()

  const ret = await func()

  const totalTime = (Date.now() - now) / 1000

  if (timeout) {
    clearTimeout(timeout)
  }

  return [ret, totalTime]
}
