import { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import * as playpass from 'playpass'
import { useAppDispatch, useAppSelector, usePrevious } from '../app/hooks'
import LoadingView from '../components/LoadingView'
import ErrorView from '../components/ErrorView'
import PlayView from './PlayView'
import {
  selectGameState,
  selectLevelState,
  selectMessage,
  selectPlayerState,
} from '../features/game/gameSlice'
import {
  markProfileCompleted,
  playProfile,
  PlaySource,
  selectCurrentProfile,
} from '../features/profiles/profilesSlice'
import { clearEntryDay, selectEntry } from '../features/entry/entrySlice'

export const GameViewRedirect = () => {
  const { profile } = useParams()
  if (profile) {
    return <Navigate to={'/@' + profile} />
  }
  return <Navigate to="/" />
}

const DailyGameView = () => {
  const playerState = useAppSelector(selectPlayerState)
  const levelState = useAppSelector(selectLevelState)
  const gameState = useAppSelector(selectGameState)
  const prevGameState = usePrevious(gameState)
  const messageState = useAppSelector(selectMessage)
  const currentPlayProfile = useAppSelector(selectCurrentProfile)
  const entryData = useAppSelector(selectEntry)

  const dispatch = useAppDispatch()
  const searchParams = new URLSearchParams(document.location.search)
  const sourceParam = searchParams.get('source')
  let source =
    sourceParam && PlaySource[sourceParam as keyof typeof PlaySource]
      ? PlaySource[sourceParam as keyof typeof PlaySource]
      : PlaySource.EXPLICIT

  const { profile, day } = useParams()

  const profilesAlign =
    '@' + profile?.toLowerCase() ===
    currentPlayProfile.data?.username.toLowerCase()

  let dayInt: number | undefined = undefined
  if (day) {
    dayInt = parseInt(day)
  } else if (entryData.linkPayload.day !== undefined) {
    dayInt = parseInt(entryData.linkPayload.day)
  } else if (levelState.isCurrentDay) {
    dayInt = levelState.day
  }

  const daysAlign = dayInt === currentPlayProfile.data?.level.day

  // Sometimes, this view gets loaded from a share, and that share has a payload specifying
  // a specific day to play. When that happens, we need to clear out the day from the payload
  // data to make sure we don't process the day for any other games
  useEffect(() => {
    if (entryData.linkPayload.day !== undefined) {
      dispatch(clearEntryDay())
    }
  }, [])

  useEffect(() => {
    if (!profilesAlign || !daysAlign) {
      dispatch(
        playProfile({
          username: '@' + profile,
          day: dayInt,
          context: {
            source: source,
          },
        }),
      )
    }
  }, [profile])

  useEffect(() => {
    if (
      gameState === 'WIN' ||
      (gameState === 'LOSS' && prevGameState === 'PENDING')
    ) {
      if (profilesAlign && levelState.isCurrentDay) {
        dispatch(markProfileCompleted({ username: '@' + profile }))
      }
    }
  }, [gameState, profile])

  useEffect(() => {
    if (profilesAlign) {
      playpass.analytics.track('PuzzlePlayBegin', {
        day: dayInt,
        profile: '@' + profile?.toLowerCase(),
      })
    }

    return () => {
      if (profilesAlign) {
        playpass.analytics.track('PuzzlePlayEnd', {
          day: dayInt,
          profile: '@' + profile?.toLowerCase(),
        })
      }
    }
  }, [profilesAlign])

  if (currentPlayProfile.fetchStatus === 'ERROR') {
    return (
      <ErrorView>
        <p>
          Cannot load game for <b>{'@' + profile}</b>. Please check back soon!
        </p>
      </ErrorView>
    )
  }

  if (
    !profilesAlign ||
    gameState === 'LOADING' ||
    currentPlayProfile.fetchStatus === 'PENDING' ||
    currentPlayProfile.fetchStatus === 'FETCHING'
  ) {
    return <LoadingView message={`Loading @${profile}'s game`} />
  }

  return (
    <>
      <PlayView
        username={profile!}
        gameState={gameState}
        state={playerState}
        setup={levelState}
        message={messageState}
      />
    </>
  )
}
export default DailyGameView
