import { GUESS_LENGTHS } from '../../config/game'
import { Daily } from '../../storage/daily'
import DateTime from '../../util/DateTime'

import type { Profile, Level, LevelV2 } from '../../../../backend/src/types'
import { ABKeys, getBucketID } from '../../abTests'
import DebugOverrides from '../../DebugOverrides'
import { timeCallAsync } from '../../util/timeCall'
import { sleep } from '../../util/sleep'
import * as playpass from 'playpass'

const DEV_BACKEND = false

// const USE_CLOUDFRONT = window.location.hostname === 'tweedle.app'
const USE_CLOUDFRONT = false

// Must match timestamp in backend/src/levels.ts!
const BASE_TIME = 1655200800000 // Date.parse('2022-06-15 GMT+14')
const DAY_MILLIS = 24 * 60 * 60 * 1000

export type ProfileAPIResponse = {
  sections: Array<{
    id: string
    name: string
    profiles: Profile[]
  }>
}

export type ProfileError = {
  error: string
  code: number
  stack: string
  urlMeta?: {
    url: string
    status: number
  }
  extra?: string
}

export function makeError(options: {
  error?: string
  code?: number
  urlMeta?: { url: string; status: number }
  extra?: string
  stack?: string
}) {
  return {
    error: options.error || 'Unknown error',
    code: options.code || 1,
    stack: options.stack || new Error().stack,
    urlMeta: options.urlMeta,
    extra: options.extra,
  } as ProfileError
}

async function callBackend<T>(
  method: string,
  params?: Record<string, string>,
  retryCount: number = 0,
): Promise<T> {
  const endpoint = DEV_BACKEND
    ? 'http://localhost:3001'
    : USE_CLOUDFRONT
    ? 'https://dob1tjpogcer9.cloudfront.net'
    : 'https://cmscmwtehivwhdnpvgwkzucwya0fasnz.lambda-url.us-east-1.on.aws'

  const url = new URL(`/api/${method}`, endpoint)
  if (params) {
    for (const k in params) {
      url.searchParams.set(k, params[k])
    }
  }

  try {
    const extra = {
      methodPrefix: method.substring(
        0,
        method.indexOf('/') === -1 ? method.length + 1 : method.indexOf('/'),
      ),
      method,
    }

    const [res, time] = await timeCallAsync(async () => fetch(url.href), {
      slowTimeoutDebug: 5000,
      extra: { ...extra, timeout: 5000 },
    })

    playpass.analytics.track('APIDebug', {
      ...extra,
      duration: time,
    })

    const json = await res.json()
    if (res.status !== 200) {
      throw makeError({
        error: json?.error,
        code: json?.code,
        urlMeta: { status: res.status, url: url.href },
      })
    }
    return json
  } catch (err) {
    console.error(err)

    if (retryCount < 4) {
      // Retry connection failures with exponential backoff
      const retryDelay = 100 * (retryCount + 1) ** 2
      console.log("Retrying...", retryCount)
      await sleep(retryDelay);
      return callBackend(method, params, retryCount+1)

    } if (err instanceof TypeError) {
      throw makeError({
        error: err.message,
        stack: err.stack,
        urlMeta: { status: 0, url: url.href },
        extra: err.cause?.message,
      })
    } else if (err instanceof Error) {
      throw makeError({
        urlMeta: { status: 0, url: url.href },
        extra: JSON.stringify(err),
      })
    } else {
      throw err
    }
  }
}

function toProfile(user: any): Profile {
  return {
    username: '@' + user.username.toLowerCase(),
    name: user.name,
    profilePictureURL: user.profile_image_url,
    type: 'recommended', // ?
  }
}

function getDay (explicitDay?: number): number {
  let day = explicitDay
  if (day === undefined) {
    const lastMidnight = new Date()
    lastMidnight.setHours(0, 0, 0, 0)

    let lastMidnightTime = lastMidnight.getTime()

    if (
      DebugOverrides.instance.isActive() &&
      DebugOverrides.instance.hasTimezoneOverride()
    ) {
      // Override the time for debugging
      const nowOverrideTZ = DateTime.fromUtcDateTimeToLocal(
        DateTime.nowUtc(),
        DebugOverrides.instance.getTimezoneOverride()!,
      )
        .toMidnight()
        .toUtc()
      console.log('OVERRIDING TZ: ', nowOverrideTZ.toISOString())
      lastMidnightTime = nowOverrideTZ.getTime()
    }

    day = Math.floor((lastMidnightTime - BASE_TIME) / DAY_MILLIS)
  }
  return day
}

export async function fetchCurrentLevelV3ForProfile(
  username: string,
  explicitDay?: number,
) {
  const day = getDay(explicitDay)

  const sort = getBucketID(ABKeys.SORTED_TWEETS) === 'sorted_tweets'

  playpass.analytics.track('FetchProfile', { profile: username, day: day })
  const level = await callBackend<any>(`level/${username}`, {
    day: '' + day,
    sort: sort ? 'true' : 'false',
  })

  // Check integrity of the data
  if (
    !level.word ||
    level.word.length < 3 ||
    level.word.length > 7 ||
    !level.tweet ||
    !level.tweet.id ||
    !level.user
  ) {
    throw makeError({
      error: 'Bad level data from backend',
      extra: JSON.stringify(level),
    })
  }

  return {
    level: {
      day,
      word: level.word,
      guesses: GUESS_LENGTHS[level.word.length as 3 | 4 | 5 | 6 | 7],
      id: level.tweet.id,
      tweet: level.tweet,
    },
    profile: toProfile(level.user),
    startDate: DateTime.fromUtc(new Date(BASE_TIME).toUTCString()),
  }
}

export async function fetchLevelForTweet(tweetId: string) {
  const level = await callBackend<any>(`tweet-level/${tweetId}`)
  return {
    level: {
      word: level.word,
      guesses: GUESS_LENGTHS[level.word.length as 3 | 4 | 5 | 6 | 7],
      id: level.tweet.id,
      tweet: level.tweet,
    },
    profile: toProfile(level.user),
  }
}

export async function fetchTweetIdsForProfile(username: string) {
  const day = getDay()
  const sort = getBucketID(ABKeys.SORTED_TWEETS) === 'sorted_tweets'

  const timeline = await callBackend<any>(`timeline/${username}`, {
    day: '' + day,
    sort: sort ? 'true': 'false',
  })

  const tweetIds = timeline.tweets.map((t: any) => t.id)
  return  { tweets: tweetIds }
}

export async function fetchTimeline(username: string) {
  const day = getDay()
  const timeline = await callBackend<any>(`timeline/${username}`, {
    day: '' + day,
  })

  return {
    profile: toProfile(timeline.user),
    tweets: timeline.tweets,
  }
}

export async function fetchSimilarProfilesToProfile(
  username: string,
  exclusionList: string[],
) {
  return callBackend<Profile[]>(`similar/${username}`, {
    exclude: exclusionList.join(','),
  })
}

export const profileTypeSort = {
  Humor: 1,
  News: 2,
  Music: 3,
  Sports: 4,
  'Sports Media': 4.1,
  Athletes: 4.2,
  'Influential People': 5,
  'Media Personalities': 5.1,
  Politics: 6,
  'Politics (Democrat)': 6.1,
  'Politics (Republican)': 6.2,
  Crypto: 7,
} as { [key: string]: number }

type profileAPIResponse = {
  name: string
  username: string
  profilePictureURL: string
  type: string
}
export async function fetchRecommendedProfiles(options: {}) {
  const profilesByType: Record<string, Array<profileAPIResponse>> = {}
  const recommendedProfiles: Array<profileAPIResponse> = [] as Array<
    profileAPIResponse
  >
  const allProfiles: Profile[] = await callBackend('profiles')

  allProfiles.forEach((p) => {
    if (!p.showInRecommended) {
      return
    }

    if (p.topRecommended) {
      recommendedProfiles.push({
        name: p.name,
        username: p.username.toLowerCase(),
        profilePictureURL: p.profilePictureURL,
        type: p.type,
      })
      return
    }

    const type = p.type
    if (profilesByType[type] === undefined) {
      profilesByType[type] = [] as Array<profileAPIResponse>
    }

    profilesByType[type].push({
      name: p.name,
      username: p.username.toLowerCase(),
      profilePictureURL: p.profilePictureURL,
      type: p.type,
    })
  })

  const profileEntries = Object.entries(profilesByType)
  profileEntries.sort((a, b) => {
    const aTypeValue =
      profileTypeSort[a[0] as keyof typeof profilesByType] || 100
    const bTypeValue =
      profileTypeSort[b[0] as keyof typeof profilesByType] || 100
    return aTypeValue - bTypeValue
  })

  return {
    sections: [
      {
        id: 'recommended',
        name: 'Recommended',
        profiles: recommendedProfiles,
      },
    ].concat(
      profileEntries.map((p) => {
        return {
          id: p[0].toLowerCase().replace(' ', ''),
          name: p[0],
          profiles: p[1],
        }
      }),
    ),
  }
}
