import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import * as playpass from 'playpass'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import LoadingView from '../components/LoadingView'
import ErrorView from '../components/ErrorView'
import PlayView from './PlayView'
import {
  selectGameState,
  selectLevelState,
  selectMessage,
  selectPlayerState,
} from '../features/game/gameSlice'
import {
  playTweet,
  PlaySource,
  selectCurrentProfile,
} from '../features/profiles/profilesSlice'
import { ABKeys, getBucketID } from '../abTests'
import { useSearchParam } from '../util/hooks'

const TweetGameView = () => {
  const playerState = useAppSelector(selectPlayerState)
  const levelState = useAppSelector(selectLevelState)
  const gameState = useAppSelector(selectGameState)
  const messageState = useAppSelector(selectMessage)
  const currentPlayProfile = useAppSelector(selectCurrentProfile)

  const dispatch = useAppDispatch()
  const searchParams = new URLSearchParams(document.location.search)
  const sourceParam = searchParams.get('source')
  let source =
    sourceParam && PlaySource[sourceParam as keyof typeof PlaySource]
      ? PlaySource[sourceParam as keyof typeof PlaySource]
      : PlaySource.EXPLICIT

  const { tweetId } = useParams()
  let profile = ''

  if (!profile) {
    profile = levelState ? levelState.profile.slice(1): ''
  }

  const profilesAlign =
    '@' + profile?.toLowerCase() ===
    currentPlayProfile.data?.username.toLowerCase()

  useEffect(() => {
    if (tweetId) {
      dispatch(playTweet({ tweetId }))
    }
  }, [tweetId])

  useEffect(() => {
    if (profilesAlign) {
      playpass.analytics.track('PuzzlePlayBegin', {
        profile: '@' + profile?.toLowerCase(),
        tweetId
      })
    }

    return () => {
      if (profilesAlign) {
        playpass.analytics.track('PuzzlePlayEnd', {
          profile: '@' + profile?.toLowerCase(),
          tweetId
        })
      }
    }
  }, [profilesAlign])

  if (currentPlayProfile.fetchStatus === 'ERROR') {
    return (
      <ErrorView>
        <p>Cannot load tweet <b>{tweetId}</b>. Please check back soon!</p>
      </ErrorView>
    )
  }

  if (
    !profilesAlign ||
    gameState === 'LOADING' ||
    currentPlayProfile.fetchStatus === 'PENDING' ||
    currentPlayProfile.fetchStatus === 'FETCHING'
  ) {
    return <LoadingView message={`Loading Tweet`} />  
  }

  return (
    <PlayView
      username={profile!}
      gameState={gameState}
      state={playerState}
      setup={levelState}
      message={messageState}
    />
  )
}
export default TweetGameView
