import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { ABKeys, getBucketID } from '../abTests'
import { useAppSelector } from '../app/hooks'
import { ExampleGrid } from '../components/Grid'
import Panel from '../components/Panel'
import SizeAwareDiv from '../components/SizeAwareDiv'
import { selectSeenTutorial } from '../features/nux/nuxSlice'
import { useCurrentProfile } from '../features/profiles/profilesHooks'
import { CurrentPlayProfile } from '../features/profiles/profilesSlice'
import ProfileTweet, {
  DefaultStyleOptions,
} from '../features/profiles/ProfileTweet'
import * as playpass from 'playpass'
import { ProfileGridItem } from '../features/profiles/ProfileGridItem'
import {
  useRecommendedProfiles,
} from '../features/profiles/profilesHooks'

const HelpViewContentSimple = (props: {
  onBack?: () => void
  onHome?: () => void
  width: number
  height: number
  profile?: string
  tweetId?: string
}) => {
  const currentProfile = useCurrentProfile()

  const displayCurrentProfile =
    currentProfile?.profile.username.toLowerCase() ===
      props.profile?.toLowerCase() || props.tweetId

  const explainerElement = (
    <div style={{ marginTop: '4px' }}>
      <div>
        <b>Can you guess my word?</b> Each guess will give you colored clues to
        how close you are. <span className="green">Green</span> means the word
        contains that correct letter in that correct spot.{' '}
        <span className="yellow">Yellow</span> indicates you guessed a correct
        letter, but in the wrong position. <span className="gray">Gray</span>{' '}
        means the letter is not used in the word.
        <ExampleGrid
          marks="nncbb"
          word="GUESS"
          width={props.width}
          height={65}
        />
      </div>
    </div>
  )

  return (
    <div className="screen" id="helpScreen">
      <h3>How to Play</h3>
      {props.profile || props.tweetId ? (
        <div onClick={props.onBack}>
          <ProfileTweet
            linkToTwitter={false}
            profile={
              displayCurrentProfile && currentProfile?.profile
                ? currentProfile!.profile
                : undefined
            }
            username={props.profile}
            disableInteraction={true}
            expandedText={explainerElement}
            expand={true}
            word=""
            textStyleOptions={DefaultStyleOptions}
            whiteBackground={true}
          />
        </div>
      ) : (
        <p>
          Choose a Twitter profile. Guess their word! Each guess will give you
          colored clues to how close you are.
        </p>
      )}
      {(props.profile || props.tweetId) && props.onBack && (
        <button
          id="helpBackBtn"
          className={classNames({
            secondary: true,
          })}
          onClick={props.onBack}
        >
          Play Game
        </button>
      )}
      {!props.profile && !props.tweetId && props.onHome && (
        <button
          id="helpBackBtn"
          className={classNames({
            secondary: true,
          })}
          onClick={props.onHome}
        >
          Browse
        </button>
      )}
      <p className="legal" style={{ marginTop: '6px' }}>
        By playing you agree to our{' '}
        <a href="https://www.play.co/tos" target="_blank" rel="noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://www.play.co/privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </p>
    </div>
  )
}

const HelpViewHeader = (props: {
  onBack?: (username?: any) => void
  onHome?: () => void
  currentProfile: CurrentPlayProfile | null
  profile?: string
  tweetId?: string
  needsTutorial: boolean
  whiteBackground?: boolean
}) => {
  const { currentProfile, profile, tweetId, needsTutorial, whiteBackground } = props

  const displayCurrentProfile =
    tweetId ||
    currentProfile?.profile.username.toLowerCase() === profile?.toLowerCase()

  if (props.profile || props.tweetId) {
    return (
      <div onClick={props.onBack} className="helpVariation">
        <ProfileTweet
          linkToTwitter={false}
          profile={
            displayCurrentProfile && currentProfile?.profile
              ? currentProfile!.profile
              : undefined
          }
          username={props.profile}
          disableInteraction={true}
          expandedText="Can you guess my word? Each guess will give you colored clues to how close you are."
          expand={true}
          word=""
          textStyleOptions={DefaultStyleOptions}
          whiteBackground={whiteBackground}
        />
      </div>
    )
  } else if (needsTutorial && currentProfile) {
    return (
      <div onClick={props.onBack}>
        <ProfileTweet
          linkToTwitter={false}
          profile={currentProfile.profile}
          username={currentProfile.username}
          disableInteraction={true}
          expandedText="Can you guess my word? Each guess will give you colored clues to how close you are."
          expand={true}
          word=""
          textStyleOptions={DefaultStyleOptions}
          whiteBackground={whiteBackground}
        />
      </div>
    )
  } else {
    return (
      <p>
        Choose a Twitter profile. Guess their word! Each guess will give you
        colored clues to how close you are.
      </p>
    )
  }
}

const HelpViewContent = (props: {
  onBack?: (username?: any) => void
  onHome?: (username?: any) => void
  width: number
  height: number
  profile?: string
  tweetId?: string
}) => {
  const currentProfile = useCurrentProfile()
  const needsTutorial = !useAppSelector(selectSeenTutorial)

  useEffect(() => {
    let cancel: null | NodeJS.Timeout = null
    if (currentProfile && needsTutorial) {
      playpass.analytics.track('TutorialLoaded', {
        profile: currentProfile.username,
        tweetId: props.tweetId,
      })

      let seconds = 1
      cancel = setInterval(() => {
        playpass.analytics.track('TutorialDwell', {
          profile: currentProfile.username,
          tweetId: props.tweetId,
          durationSeconds: 2 * seconds++,
        })

        // Cut it off if someone leaves the browser tab open
        if (seconds > 10 && cancel) {
          clearInterval(cancel)
          cancel = null
        }
      }, 2000)
    }
    return () => {
      if (cancel) {
        clearInterval(cancel)
        cancel = null
      }
    }
  }, [currentProfile, needsTutorial, props.tweetId])

  return (
    <div className="screen" id="helpScreen">
      <h3>How to Play</h3>
      <HelpViewHeader
        {...props}
        currentProfile={currentProfile}
        needsTutorial={needsTutorial}
        whiteBackground={true}
      />
      <div
        className="clickCatcher"
        onClick={() => {
          playpass.analytics.track('HelpHoneypotClicked')
          playpass.analytics.setUserProperties({
            maybeBot: true,
            maybeBotSource: 'helpHoneypot',
          })
        }}
      ></div>
      <Panel className="examples" showClose={false} onClose={props.onBack}>
        <div onClick={props.onBack}>
          <p>
            <span className="green">Green</span> means the word contains that
            correct letter in that correct spot.
          </p>
          <ExampleGrid
            marks="bnbnn"
            word="GREAT"
            width={props.width}
            height={65}
          />

          <p>
            <span className="yellow">Yellow</span> indicates you guessed a
            correct letter, but in the wrong position.
          </p>
          <ExampleGrid
            marks="cnncn"
            word="UNDER"
            width={props.width}
            height={65}
          />
          <p>
            Combine what you learn from previous guesses to get the right
            answer.
          </p>

          <ExampleGrid
            marks="bbbbb"
            word="GUESS"
            width={props.width}
            height={65}
          />
        </div>
      </Panel>
      <p className="legal">
        By playing you agree to our{' '}
        <a href="https://www.play.co/tos" target="_blank" rel="noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://www.play.co/privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </p>
      {(props.profile || props.tweetId || (needsTutorial && currentProfile)) &&
        props.onBack && (
          <button
            id="helpBackBtn"
            className={classNames({
              secondary: true,
            })}
            onClick={props.onBack}
          >
            Start
          </button>
        )}
      {!props.profile &&
        !props.tweetId &&
        !(needsTutorial && currentProfile) &&
        props.onHome && (
          <button
            id="helpBackBtn"
            className={classNames({
              secondary: true,
            })}
            onClick={props.onHome}
          >
            Browse
          </button>
        )}
    </div>
  )
}

const HelpViewContentVariation = (props: {
  onBack?: (username?: any) => void
  onHome?: (username?: any) => void
  width: number
  height: number
  profile?: string
  tweetId?: string
}) => {
  const currentProfile = useCurrentProfile()
  const needsTutorial = !useAppSelector(selectSeenTutorial)

  useEffect(() => {
    let cancel: null | NodeJS.Timeout = null
    if (currentProfile && needsTutorial) {
      playpass.analytics.track('TutorialLoaded', {
        profile: currentProfile.username,
        tweetId: props.tweetId,
      })

      let seconds = 1
      cancel = setInterval(() => {
        playpass.analytics.track('TutorialDwell', {
          profile: currentProfile.username,
          tweetId: props.tweetId,
          durationSeconds: 2 * seconds++,
        })

        // Cut it off if someone leaves the browser tab open
        if (seconds > 10 && cancel) {
          clearInterval(cancel)
          cancel = null
        }
      }, 2000)
    }
    return () => {
      if (cancel) {
        clearInterval(cancel)
        cancel = null
      }
    }
  }, [currentProfile, needsTutorial, props.tweetId])

  let onClick;

  if (props.profile || props.tweetId || (needsTutorial && currentProfile)) {
    onClick = props.onBack
  } else {
    onClick = props.onHome
  }

  return (
    <div className="screen" id="helpScreen">
      <h3>How to Play</h3>
      <HelpViewHeader
        {...props}
        currentProfile={currentProfile}
        needsTutorial={needsTutorial}
        whiteBackground={false}
      />
      <div
        className="clickCatcher"
        onClick={() => {
          playpass.analytics.track('HelpHoneypotClicked')
          playpass.analytics.setUserProperties({
            maybeBot: true,
            maybeBotSource: 'helpHoneypot',
          })
        }}
      ></div>
      <Panel className="examplesVariation" showClose={false} onClose={props.onBack}>
        <div onClick={props.onBack}>
          <p>
            <span className="green"><b>GREEN</b></span> means the word contains that
            correct letter in that correct spot.
          </p>
          <ExampleGrid
            marks="bnbnn"
            word="GREAT"
            width={props.width}
            height={65}
          />
          <p>
            <span className="yellow"><b>YELLOW</b></span> indicates you guessed a
            correct letter, but in the wrong position.
          </p>
          <ExampleGrid
            marks="cnncn"
            word="UNDER"
            width={props.width}
            height={65}
          />
        </div>
      </Panel>
      <div className="bottom">
        <ProfileSelection
          profile={props.profile}
          currentProfile={currentProfile}
          rows={2}
          cols={4}
          needsTutorial={needsTutorial}
          onClick={onClick}
        />
        <p className="legalVariation">
          By playing you agree to our{' '}
          <a href="https://www.play.co/tos" target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="https://www.play.co/privacy" target="_blank">
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </div>
  )
}

const ProfileSelection = (props: {
  profile?: string
  currentProfile: CurrentPlayProfile | null
  rows: number
  cols: number
  needsTutorial: boolean
  onClick?: (username: string) => void
}) => {
  const { rows, cols, currentProfile, profile, needsTutorial } = props
  const gridRef = useRef<HTMLDivElement>(null)
  const recommended = useRecommendedProfiles()

  const displayCurrentProfile =
    currentProfile?.profile.username.toLowerCase() === profile?.toLowerCase()
  
  let recommendedProfiles = recommended?.data?.sections?.length ? recommended.data.sections[0].profiles : undefined
  if (displayCurrentProfile && currentProfile) {
    recommendedProfiles = recommendedProfiles?.filter(profile => profile.profile.username.toLowerCase() !== currentProfile?.username.toLowerCase())
  }
  const profiles = recommendedProfiles?.map(profile => profile.profile).splice(0, rows * cols)
  let profileArray = []
  if (displayCurrentProfile && currentProfile) {
    profiles?.splice(0, 0, currentProfile.profile)
  }
  if (profiles) {
    const filteredProfiles = profiles.splice(0, rows * cols);
    while (filteredProfiles.length) profileArray.push(filteredProfiles.splice(0, cols));
  }

  const rowElements: JSX.Element[] = []
  profileArray.map((row, rowIndex) => {
    const colElements: JSX.Element[] = []
    row.map((value, colIndex) => {
      const colElement = (
        <ProfileGridItem
          key={value.username}
          profile={value}
          onTap={() => {
            playpass.analytics.track('HelpProfileSelected', {
              profile: value.username,
            })
            props.onClick && props.onClick(value.username)
          }}
        />
      )
      colElements.push(colElement)
    })

    rowElements.push(
      <div
        className="profileSquare"
        key={rowIndex}
      >
        {colElements}
      </div>,
    )
  })

  return (
    <div
      className="profileSelectionGrid"
      ref={gridRef}
    >
      <div className="titleText">Choose a Profile</div>
      {rowElements}
    </div>
  )
}

const HelpView = (props: {
  profile?: string
  tweetId?: string
  onBack?: (username: any) => void
  onHome?: (username: any) => void
}) => {
  return (
    <SizeAwareDiv
    className="helpViewContainer"
      render={(width, height) => (
        <HelpViewContent
          profile={props.profile}
          tweetId={props.tweetId}
          width={width}
          height={height}
          onBack={props.onBack}
          onHome={props.onHome}
        />
      )}
    />
  )
}

export default HelpView
