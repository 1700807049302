import { ABKeys, getBucketID } from '../../abTests'

export function getTutorialProfile() {
  return '@cnn'
}

export function getTutorialTweet(profile?: string) {
  
  if (!profile || profile === '@cnn') {
    return '1550417550831386624'
  }

  return '1550417550831386624'
}
