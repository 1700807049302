import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  fetchProfiles,
  selectCurrentProfile,
  selectFavoriteProfiles,
  selectProfileHistory,
  selectRecommendedProfiles,
} from './profilesSlice'
import * as playpass from 'playpass'

export const useRecommendedProfiles = (options?: {
  filter?: string | null
  order: string | null
}) => {
  const recommendedProfiles = useAppSelector(selectRecommendedProfiles)
  const profileHistories = useAppSelector(selectProfileHistory)

  const dispatch = useAppDispatch()

  const sectionFilters = options?.filter
    ? options!.filter.split(',').map((s) => s.toLowerCase())
    : []
  const sectionOrder = options?.order
    ? options!.order.split(',').map((s) => s.toLowerCase())
    : []

  useEffect(() => {
    if (recommendedProfiles.fetchStatus === 'PENDING') {
      // TODO - hoist the filters into the network call
      dispatch(fetchProfiles({}))
    } else if (
      recommendedProfiles.fetchStatus === 'COMPLETE' &&
      (!recommendedProfiles.data || !recommendedProfiles.data?.sections)
    ) {
      playpass.analytics.track('NoRecommendedProfilesFound')
    }
  }, [recommendedProfiles.fetchStatus])

  let sections =
    recommendedProfiles.data?.sections.filter((s) => {
      if (!sectionFilters || sectionFilters.length === 0) {
        return true
      }

      return sectionFilters.indexOf(s.name.toLowerCase()) !== -1
    }) || []

  if (sectionOrder && sectionOrder.length > 0) {
    sections = sections
      .map((s, i) => {
        return { s, i }
      })
      .sort((a, b) => {
        const aIndex = sectionOrder.indexOf(a.s.name.toLowerCase())
        const bIndex = sectionOrder.indexOf(b.s.name.toLowerCase())

        const aFinalIdx = aIndex === -1 ? a.i : aIndex - sectionOrder.length
        const bFinalIdx = bIndex === -1 ? b.i : bIndex - sectionOrder.length
        return aFinalIdx - bFinalIdx
      })
      .map((s) => s.s)
  }

  const mappedSections = sections.map((s) => {
    return {
      ...s,
      profiles: s.profiles.map((p) => {
        return {
          profile: p,
          ...profileHistories[p.username.toLowerCase()],
        }
      }),
    }
  })
  return {
    ...recommendedProfiles,
    data: mappedSections
      ? {
          sections: mappedSections,
        }
      : undefined,
  }
}

export const useFavoriteProfiles = () => {
  const profileHistories = useAppSelector(selectProfileHistory)
  const favoriteProfiles = Object.values(useAppSelector(selectFavoriteProfiles))

  return favoriteProfiles
    .map((p) => {
      return {
        ...p,
        ...profileHistories[p.profile.username.toLowerCase()],
      }
    })
    .sort((a, b) => {
      const hA = profileHistories[a.profile.username]
      const hB = profileHistories[b.profile.username]
      const oldDate = new Date(1900, 0, 1).toISOString()
      const aTime = hA?.lastCompletionTime || hA?.lastPlayTime || oldDate
      const bTime = hB?.lastCompletionTime || hB?.lastPlayTime || oldDate

      return Date.parse(bTime) - Date.parse(aTime)
    })
}

export const useProfileHistory = (username?: string) => {
  const profileHistories = useAppSelector(selectProfileHistory)
  if (!username) {
    return undefined
  }

  const profileHistory = profileHistories[username]
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return {
    ...profileHistory,
    completedToday: profileHistory?.lastCompletionTime
      ? profileHistory!.lastCompletionTime > today.toISOString()
      : false,
  }
}

export const useSimilarProfiles = () => {
  const currentPlayProfile = useAppSelector(selectCurrentProfile)
  const profileHistories = useAppSelector(selectProfileHistory)

  if (currentPlayProfile.data?.similar === undefined) {
    return []
  }

  const similar = currentPlayProfile.data!.similar!
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return similar.filter((s) => {
    if (profileHistories[s.username] === undefined) {
      return true
    }

    return profileHistories[s.username].lastCompletionTime
      ? profileHistories[s.username]!.lastCompletionTime! <= today.toISOString()
      : true
  })
}

export const useCurrentProfileContext = () => {
  const currentPlayProfile = useAppSelector(selectCurrentProfile)
  if (currentPlayProfile.context) {
    return currentPlayProfile.context!
  }
  return null
}

export const useCurrentProfile = () => {
  const currentPlayProfile = useAppSelector(selectCurrentProfile)
  if (currentPlayProfile.data) {
    return currentPlayProfile.data!
  }
  return null
}
