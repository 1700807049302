import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { State } from '../storage/daily'
import { GridState, GuessClasses, GuessSymbols } from './Grid'
import { ABKeys, getBucketID } from '../abTests'
import { isValidWord } from '../boilerplate/dictionary'

const Keyboard = (props: {
  state: State
  onKey: (key: string) => void
  message?: string
  disable?: boolean
  hintWord?: string
  currentWord?: string
}) => {
  const Row1 = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P']
  const Row2 = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L']
  const Row3 = ['Delete', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'Enter']

  const nextHint =
    props.hintWord &&
    props.currentWord !== undefined &&
    props.hintWord.startsWith(props.currentWord)
      ? props.currentWord.length >= props.hintWord.length
        ? 'Enter'
        : props.hintWord[props.currentWord.length]
      : undefined

  const { words, marks } = props.state

  const charStates = Array.from(Array(26), () => GridState.EMPTY)
  const ccA = 'A'.charCodeAt(0)

  for (let w = 0; w < marks.length; w++) {
    for (let i = 0; i < words[w].length; i++) {
      const c = words[w].charCodeAt(i) - ccA
      const newMarkPriority = GuessSymbols.indexOf(marks[w][i])

      if (newMarkPriority > GuessSymbols.indexOf(charStates[c])) {
        charStates[c] = marks[w][i] as GridState
      }
    }
  }

  const isLastLetter = props.state.words[props.state.words.length - 1].length === props.currentWord?.length
  const isWordValid = isLastLetter && isValidWord(props.state.words[props.state.words.length - 1])

  const MapKeys = (keys: string[]) => {
    return keys.map((e) => {
      const charIndex = e.charCodeAt(0) - ccA
      const classes = classNames(
        {
          enter: e === 'Enter',
          delete: e === 'Delete',
          hintKey: e === nextHint,
          highlighted: isLastLetter && ((e === 'Enter' && isWordValid) || (e === 'Delete' && !isWordValid))
        },
        e.length === 1
          ? GuessClasses[GuessSymbols.indexOf(charStates[charIndex])]
          : '',
      )
      return (
        <div
          key={e}
          className={classes}
          onClick={() => {
            if (!props.disable) {
              props.onKey(e)
            }
          }}
        >
          {e}
        </div>
      )
    })
  }

  useEffect(() => {
    const subscribeKeyboard = (event: KeyboardEvent) => {
      let key
      switch (event.key) {
        case 'Enter':
          key = 'Enter'
          break
        case 'Backspace':
        case 'Delete':
          key = 'Delete'
          break
        default:
          if (event.key.length == 1) {
            const charCode = event.key.toUpperCase().charCodeAt(0)
            if (charCode >= 65 && charCode <= 90) {
              key = String.fromCharCode(charCode)
            }
          }
      }
      if (key && !props.disable) {
        props.onKey(key)
      }
    }

    window.addEventListener('keydown', subscribeKeyboard)
    return () => window.removeEventListener('keydown', subscribeKeyboard)
  })

  return (
    <div className="keyboard">
      <div>{MapKeys(Row1)}</div>
      <div className="row">{MapKeys(Row2)}</div>
      <div className="row">{MapKeys(Row3)}</div>
      {props.message && <p className="message">{props.message}</p>}
    </div>
  )
}

export default Keyboard
