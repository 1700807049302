import * as playpass from 'playpass'

export function remapBestShareType(type?: string) {
  if (type === 'any') {
    return 'unknown'
  }
  return type
}

export function getStandardEntryUserProperties() {
  let entrySpecifiedProfile: string | null = null
  const pathname = document.location.pathname
  const entryProfileMatch = pathname.match(/\/@([\d\w]+)\/?|\/p\/([\d\w]+)\/?/)
  if (entryProfileMatch !== null) {
    const profile = entryProfileMatch[1] || entryProfileMatch[2]
    if (profile) {
      entrySpecifiedProfile = '@' + profile.toLowerCase()
    }
  }

  const linkData = playpass.getLinkData() as Record<string, any>
  console.log('linkData:', linkData)
  let destination: string | null =
    linkData?.destination === 'UNKNOWN' ? null : linkData?.destination

  const screenHeight = window.innerHeight
  const screenWidth = window.innerWidth
  const orientation =
    window.innerHeight >= window.innerWidth ? 'portrait' : 'landscape'

  return {
    firstEntryUserProperties: {
      firstEntrySpecifiedProfile: entrySpecifiedProfile,
      firstEntryShareDestinationApp: destination,
      firstEntryFeature: linkData?.feature,
      firstEntrySubfeature: linkData?.subfeature,
      firstEntrySourcePlatformSocialNetwork: linkData?.platformSocialNetwork,
      firstEntryPlatformSocialNetwork: remapBestShareType(
        playpass.device.getBestShareType(),
      ),
      firstEntryDeviceOrientation: orientation,
      firstEntryDeviceScreenHeight: screenHeight,
      firstEntryDeviceScreenWidth: screenWidth,
    },
    lastEntryUserProperties: {
      lastEntrySpecifiedProfile: entrySpecifiedProfile,
      lastEntryShareDestinationApp: destination,
      lastEntryFeature: linkData?.feature,
      lastEntrySubfeature: linkData?.subfeature,
      lastEntrySourcePlatformSocialNetwork: linkData?.platformSocialNetwork,
      lastEntryPlatformSocialNetwork: remapBestShareType(
        playpass.device.getBestShareType(),
      ),
      lastEntryDeviceOrientation: orientation,
      lastEntryDeviceScreenHeight: screenHeight,
      lastEntryDeviceScreenWidth: screenWidth,

      // Clear out all the per-puzzle realtime stuff
      realtimeProfileSource: null,
      realtimeProfile: null,
      realtimeAnswer: null,
      realtimeTweetId: null,
      realtimeAnswerLength: null,
      realtimeGameState: null,
      realtimeGuessCount: null,
      realtimeSessionStartedGames: 0,
      realtimeSessionStartedProfiles: 0,
      realtimeGameMode: null,
    },
    eventProperties: {
      profile: entrySpecifiedProfile,
      shareDestinationApp: destination,
      feature: linkData?.feature,
      subfeature: linkData?.subfeature,
      sourcePlatformSocialNetwork: linkData?.platformSocialNetwork,
      platformSocialNetwork: remapBestShareType(
        playpass.device.getBestShareType(),
      ),
    },
  }
}
