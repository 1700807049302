import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { AppDispatch, RootState, store } from '../../app/store'
import { Daily } from '../../storage/daily'
import {
  resetLevel,
  resetFromStorage as resetGameFromStorage,
  clear,
} from '../game/gameSlice'
import {
  ProfileError,
  fetchCurrentLevelV3ForProfile,
  fetchLevelForTweet,
  fetchRecommendedProfiles,
  fetchSimilarProfilesToProfile,
  ProfileAPIResponse,
  makeError,
  fetchTweetIdsForProfile,
} from './profilesAPI'
import { getTweetText } from './tweetUtils'
import DateTime from '../../util/DateTime'
import DebugOverrides from '../../DebugOverrides'

export type Profile = {
  name: string
  username: string
  profilePictureURL: string
  type: string
}

export type Tweet = {
  author_id: string
  created_at: string
  text: string
  id: string
  attachments?: {
    media?: Array<{
      type?: string
      width?: number
      height?: number
      url?: string
      media_key: string
    }>
  }
  referenced_tweets?: Array<Omit<Tweet, 'referenced_tweets'>>
}

export type CurrentPlayProfile = {
  username: string
  profile: Profile
  similar?: Profile[]
  startDateUTC: string
  level: {
    word: string
    guesses: number
    day: number
    tweet?: string
    object?: Tweet
  }
  tweets: string[]
}

const _PlaySource = {
  EXPLICIT: 'EXPLICIT',
  TUTORIAL_DEFAULT: 'TUTORIAL_DEFAULT',
  HOME_SCREEN_INPUT_BOX: 'HOME_SCREEN_INPUT_BOX',
  HOME_SCREEN_RECENT: 'HOME_SCREEN_RECENT',
  HOME_SCREEN_RECOMMENDED: 'HOME_SCREEN_RECOMMENDED',
  SIMILAR_PROFILE: 'SIMILAR_PROFILE',
  ME_SCREEN_INPUT_BOX: 'ME_SCREEN_INPUT_BOX',
  TWITTER_LOGIN_REDIRECT: 'TWITTER_LOGIN_REDIRECT',
  HELP_SCREEN: 'HELP_SCREEN',
}
export type PlaySourceType = keyof typeof _PlaySource
export const PlaySource = _PlaySource as Record<
  keyof typeof _PlaySource,
  keyof typeof _PlaySource
>

export type CurrentPlayProfileContext = {
  source?: keyof typeof PlaySource
}

export type LoadableData<T, E, C> = {
  data?: T
  error?: E
  context?: C
  fetchStatus: 'PENDING' | 'FETCHING' | 'COMPLETE' | 'ERROR'
}

export type ProfilesState = {
  recommendedProfiles: {
    data?: ProfileAPIResponse
    error?: ProfileError
    fetchStatus: 'PENDING' | 'FETCHING' | 'COMPLETE' | 'ERROR'
    lastFetchTime?: string
  }
  favoriteProfiles: Record<string, FavoriteProfile>
  profileHistory: Record<string, ProfileHistory>
  currentPlayProfile: LoadableData<
    CurrentPlayProfile,
    ProfileError,
    CurrentPlayProfileContext
  >
}

export type FavoriteProfile = {
  profile: Profile
}

export type ProfileHistory = {
  lastPlayTime?: string
  lastCompletionTime?: string
}

const initialState = {
  recommendedProfiles: {
    fetchStatus: 'PENDING',
  },
  favoriteProfiles: {},
  profileHistory: {},
  currentPlayProfile: {
    fetchStatus: 'PENDING',
  },
} as ProfilesState

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    resetFromStorage: (
      state: ProfilesState,
      action: PayloadAction<
        Pick<ProfilesState, 'favoriteProfiles' | 'profileHistory'>
      >,
    ) => {
      state.favoriteProfiles = action.payload.favoriteProfiles
      state.profileHistory = action.payload.profileHistory
    },
    addFavoriateProfile: (state, action: PayloadAction<Profile>) => {
      const username = action.payload.username.toLowerCase()
      if (!state.favoriteProfiles[username]) {
        state.favoriteProfiles[username] = {
          profile: action.payload,
        }
      }
    },
    markProfileCompleted: (
      state,
      action: PayloadAction<{ username: string }>,
    ) => {
      const { username } = action.payload
      const un = username.toLowerCase()
      if (!state.profileHistory[un]) {
        state.profileHistory[un] = {}
      }
      const now = new Date().toISOString()
      state.profileHistory[un].lastCompletionTime = now
    },
    markPlayed: (state, action: PayloadAction<{ username: string }>) => {
      const { username } = action.payload
      const un = username.toLowerCase()
      if (!state.profileHistory[un]) {
        state.profileHistory[un] = {}
      }
      const now = new Date().toISOString()
      state.profileHistory[un].lastPlayTime = now
    },
    clearCurrentProfile: (state) => {
      state.currentPlayProfile = {
        fetchStatus: 'PENDING',
        data: undefined,
        error: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfiles.fulfilled, (state, action) => {
      state.recommendedProfiles.fetchStatus = 'COMPLETE'
      state.recommendedProfiles.data = action.payload
    })

    builder.addCase(fetchProfiles.pending, (state, action) => {
      state.recommendedProfiles.lastFetchTime = new Date().toDateString()
      state.recommendedProfiles.fetchStatus = 'FETCHING'
      state.recommendedProfiles.data = undefined
    })

    builder.addCase(fetchProfiles.rejected, (state, action) => {
      state.recommendedProfiles.fetchStatus = 'ERROR'
      state.recommendedProfiles.error = action.payload
    })

    builder.addCase(playProfile.fulfilled, (state, action) => {
      state.currentPlayProfile.fetchStatus = 'COMPLETE'
      state.currentPlayProfile.data = action.payload
      state.currentPlayProfile.error = undefined
    })

    builder.addCase(playProfile.pending, (state, action) => {
      state.currentPlayProfile.fetchStatus = 'FETCHING'
      state.currentPlayProfile.data = undefined
      state.currentPlayProfile.error = undefined
      state.currentPlayProfile.context = action.meta.arg.context
    })

    builder.addCase(playProfile.rejected, (state, action) => {
      state.currentPlayProfile.fetchStatus = 'ERROR'
      state.currentPlayProfile.data = undefined
      state.currentPlayProfile.error = action.payload
    })

    builder.addCase(playTweet.fulfilled, (state, action) => {
      state.currentPlayProfile.fetchStatus = 'COMPLETE'
      state.currentPlayProfile.data = action.payload
      state.currentPlayProfile.error = undefined
    })

    builder.addCase(playTweet.pending, (state, action) => {
      state.currentPlayProfile.fetchStatus = 'FETCHING'
      state.currentPlayProfile.data = undefined
      state.currentPlayProfile.error = undefined
    })

    builder.addCase(playTweet.rejected, (state, action) => {
      state.currentPlayProfile.fetchStatus = 'ERROR'
      state.currentPlayProfile.data = undefined
      state.currentPlayProfile.error = action.payload
    })
  },
})

export const selectFavoriteProfiles = (state: RootState) =>
  state.profiles.favoriteProfiles
export const selectRecommendedProfiles = (state: RootState) =>
  state.profiles.recommendedProfiles
export const selectCurrentProfile = (state: RootState) =>
  state.profiles.currentPlayProfile
export const selectProfileHistory = (state: RootState) =>
  state.profiles.profileHistory

export const fetchProfiles = createAsyncThunk<
  ProfileAPIResponse,
  {},
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: ProfileError
  }
>('profiles/fetchProfiles', async (payload, thunkApi) => {
  try {
    const profiles = await fetchRecommendedProfiles({})
    return profiles
  } catch (e) {
    console.error(e)
    return thunkApi.rejectWithValue(e as ProfileError)
  }
})

export const clearCurrentProfile = createAsyncThunk<
  void,
  {},
  {
    dispatch: AppDispatch
    state: RootState
  }
>('profiles/clearCurrentProfile', async (payload, thunkApi) => {
  const dispatch = thunkApi.dispatch
  dispatch(clear())
  return
})

export const playProfile = createAsyncThunk<
  CurrentPlayProfile,
  { username: string; day?: number; context?: CurrentPlayProfileContext },
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: ProfileError
  }
>('profiles/playProfile', async (payload, thunkApi) => {
  try {
    const { username, day } = payload
    const profiles: ProfilesState = thunkApi.getState().profiles
    const dispatch = thunkApi.dispatch

    // Handle case were the day changes underneath the current profile
    if (username === profiles.currentPlayProfile.data?.username && !day) {
      const dt = DateTime.fromUtcToLocal(
        profiles.currentPlayProfile.data!.startDateUTC,
        DateTime.systemTimezoneOffset(),
      ).toMidnight()
      const daily = new Daily(dt)
      if (daily.day === profiles.currentPlayProfile!.data?.level.day) {
        return profiles.currentPlayProfile.data!
      }
    }

    const [levelForProfile, similarProfiles] = await Promise.all([
      fetchCurrentLevelV3ForProfile(username, day),
      fetchSimilarProfilesToProfile(username, []),
    ])
    const { tweets } = await fetchTweetIdsForProfile(username)

    // Validate data
    const isDebugOverride =
      DebugOverrides.instance.isActive() &&
      DebugOverrides.instance.hasTimezoneOverride()

    const localStartDate = DateTime.fromUtcDateTimeToLocal(
      levelForProfile.startDate,
      isDebugOverride
        ? DebugOverrides.instance.getTimezoneOverride()!
        : DateTime.systemTimezoneOffset(),
    )
    const daily: Daily =
      day === undefined
        ? new Daily(localStartDate)
        : new Daily(localStartDate, day)

    if (!isDebugOverride && daily.day !== levelForProfile.level.day) {
      return thunkApi.rejectWithValue(
        makeError({ error: 'Inconsistent client day and server day' }),
      )
    }

    // Load the level in the game state
    const level = {
      day: levelForProfile.level.day,
      word: levelForProfile.level.word,
      tweet: getTweetText(levelForProfile.level.tweet),
      guesses: levelForProfile.level.guesses,
      id: levelForProfile.level.id,
      object: levelForProfile.level.tweet,
      isCurrentDay: daily.isCurrentDay(),
      profile: username,
      tweetId: levelForProfile.level.tweet.id,
    }

    dispatch(resetLevel(level)) // important ordering - analytics keyed off this event

    // Load and reest the player data for the game state
    const playerData = await daily.loadObject(username)
    dispatch(resetGameFromStorage({ username, state: playerData }))

    // Save profile as favorite
    dispatch(
      addFavoriateProfile({
        profilePictureURL: levelForProfile.profile.profilePictureURL,
        username: username,
        type: levelForProfile.profile.type,
        name: levelForProfile.profile.name,
      }),
    )

    dispatch(markPlayed({ username }))

    // finally set the current player
    return {
      username,
      profile: {
        username,
        type: levelForProfile.profile.type,
        name: levelForProfile.profile.name,
        profilePictureURL: levelForProfile.profile.profilePictureURL,
      } as Profile,
      startDateUTC: localStartDate.toUtc().toISOString(),
      similar: similarProfiles,
      level,
      tweets,
    }
  } catch (e) {
    console.error(e)
    return thunkApi.rejectWithValue(e as ProfileError)
  }
})

export const playTweet = createAsyncThunk<
  CurrentPlayProfile,
  { tweetId: string },
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: ProfileError
  }
>('profiles/playTweet', async (payload, thunkApi) => {
  try {
    const { tweetId } = payload
    const dispatch = thunkApi.dispatch

    const [levelForTweet] = await Promise.all([fetchLevelForTweet(tweetId)])
    const { tweets } = await fetchTweetIdsForProfile(levelForTweet.profile.username)

    // Validate data
    const isDebugOverride =
      DebugOverrides.instance.isActive() &&
      DebugOverrides.instance.hasTimezoneOverride()

    const localStartDate = DateTime.fromUtcDateTimeToLocal(
      DateTime.nowUtc(),
      isDebugOverride
        ? DebugOverrides.instance.getTimezoneOverride()!
        : DateTime.systemTimezoneOffset(),
    )

    const daily = Daily.forTweet(tweetId)
    const username = levelForTweet.profile.username

    // Load the level in the game state
    const level = {
      day: -1,
      word: levelForTweet.level.word,
      tweet: getTweetText(levelForTweet.level.tweet),
      guesses: levelForTweet.level.guesses,
      id: levelForTweet.level.id,
      object: levelForTweet.level.tweet,
      isCurrentDay: false,
      profile: username,
      tweetId: tweetId,
    }

    dispatch(resetLevel(level)) // important ordering - analytics keyed off this event

    // Load and reest the player data for the game state
    const playerData = await daily.loadObject(username)
    dispatch(resetGameFromStorage({ username, state: playerData }))

    // Save profile as favorite
    dispatch(
      addFavoriateProfile({
        profilePictureURL: levelForTweet.profile.profilePictureURL,
        username,
        type: levelForTweet.profile.type,
        name: levelForTweet.profile.name,
      }),
    )

    // finally set the current player
    return {
      username,
      profile: {
        username,
        type: levelForTweet.profile.type,
        name: levelForTweet.profile.name,
        profilePictureURL: levelForTweet.profile.profilePictureURL,
      } as Profile,
      startDateUTC: localStartDate.toUtc().toISOString(),
      level,
      tweets,
    }
  } catch (e) {
    console.error(e)
    return thunkApi.rejectWithValue(e as ProfileError)
  }
})

export const {
  resetFromStorage,
  addFavoriateProfile,
  markProfileCompleted,
  markPlayed,
} = profilesSlice.actions

export default profilesSlice.reducer
