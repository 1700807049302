import classNames from 'classnames'
import {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { usePrevious } from '../app/hooks'
import { State } from '../storage/daily'
import gsap from 'gsap'
import { BullLetterStyle, CowLetterStyle, MissedLetterStyle } from '../skin/CSS'
import * as playpass from 'playpass'

const GRID_PADDING = 10

export enum GridState {
  EMPTY = '_',
  NONE = 'n',
  COW = 'c',
  BULL = 'b',
}

export const GuessSymbols =
  GridState.EMPTY + GridState.NONE + GridState.COW + GridState.BULL
export const GuessClasses = [
  'letterEmpty',
  'letterNone',
  'letterCow',
  'letterBull',
]

const CLASSES = {
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
}

export const ResizableGrid = (props: {
  width: number
  height: number
  state: State
  length: number
  guesses: number
  enableScrollable?: boolean
}) => {
  if (!props.guesses || isNaN(props.guesses)) {
    throw new Error(
      'Cannot render grid with current props: ' + JSON.stringify(props),
    )
  }

  const { width, height, guesses, length, enableScrollable } = props

  const heightPerCell = Math.min((height - GRID_PADDING * 2) / guesses, 80)
  const widthPerCell = Math.min((width - GRID_PADDING * 2) / length, 80)
  let cellSize = Math.min(heightPerCell, widthPerCell)

  let style: CSSProperties = {}
  const overrideSize = cellSize < 40 && enableScrollable
  if (overrideSize) {
    style.overflowY = 'scroll'
    style.width = width + 'px'
    style.height = height + 'px'
    cellSize = 50
  }

  const constraintWidth = cellSize * length + GRID_PADDING * 2
  const constraintHeight = cellSize * guesses + GRID_PADDING * 2

  return (
    <div
      className="gridConstraint"
      style={{ width: constraintWidth, height: constraintHeight, ...style }}
    >
      <Grid
        sizeOverride={
          overrideSize
            ? { width: constraintWidth, height: constraintHeight }
            : undefined
        }
        state={props.state}
        length={props.length}
        guesses={props.guesses}
        size={
          cellSize < 40 && !enableScrollable
            ? cellSize < 20
              ? 'TINY'
              : 'SMALL'
            : 'NORMAL'
        }
      />
    </div>
  )
}

export const ExampleGrid = (props: {
  marks: string
  word: string
  width: number
  height: number
}) => {
  const state = {
    words: [props.word],
    marks: [props.marks],
  } as State

  return (
    <div className="exampleGrid">
      <ResizableGrid
        width={props.width}
        height={props.height}
        state={state}
        length={props.word.length}
        guesses={1}
      />
    </div>
  )
}

export const ExampleGridMulti = (props: {
  marks: string[]
  words: string[]
  width: number
  height: number
}) => {
  const state = {
    words: props.words,
    marks: props.marks,
  } as State

  return (
    <div className="exampleGrid">
      <ResizableGrid
        width={props.width}
        height={props.height}
        state={state}
        length={props.words[0].length}
        guesses={props.marks.length}
      />
    </div>
  )
}

const getAnimationClass = (letter: string) => {
  switch (letter) {
    case 'c':
      return CowLetterStyle
    case 'b':
      return BullLetterStyle
    default:
      return MissedLetterStyle
  }
}

const Grid = (props: {
  sizeOverride?: { width: number; height: number }
  state: State
  length: number
  guesses: number
  size: 'SMALL' | 'NORMAL' | 'TINY'
}) => {
  const prevState = usePrevious(props.state)
  const [animate, setAnimate] = useState(-1)
  const { words, marks } = props.state
  const gridRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (
      animate === -1 &&
      prevState !== undefined &&
      prevState!.marks.length !== props.state.marks.length
    ) {
      setAnimate(props.state.marks.length - 1)
    } else {
      setAnimate(-1)
    }
  }, [props.state.marks.length])

  useEffect(() => {
    if (props.state.marks.length && gridRef.current && props.sizeOverride) {
      // Auto scroll to the next row
      const div = gridRef.current!
      const currentRow = div.children[
        props.state.marks.length
      ] as HTMLDivElement

      if (!currentRow) {
        return
      }

      // TODO - doesn't work when the element is already scrolled
      const height = div.parentElement!.clientHeight
      const offsetTopRelative = currentRow.offsetTop - div.offsetTop
      const scrollBottom = div.parentElement!.scrollTop + height

      if (
        height > 0 &&
        offsetTopRelative + currentRow.clientHeight > scrollBottom
      ) {
        const scrollTarget =
          offsetTopRelative -
          scrollBottom +
          currentRow.clientHeight +
          div.parentElement!.scrollTop

        div.parentElement!.scrollTo(0, scrollTarget)
      }
    }
  }, [props.state.marks.length, gridRef.current?.parentElement?.clientHeight])

  useEffect(() => {
    if (animate !== -1) {
      gsap.set('.animateRow', {
        transformStyle: 'preserve-3d',
        transformPerspective: 1000,
      })

      const promises = gsap.utils
        .toArray<HTMLDivElement>('.animateRow div')
        .map((el, i) => {
          const letter = marks[animate] && marks[animate][i]
          const tl = gsap.timeline()
          tl.delay(i * 0.1)
          tl.to(el, {
            duration: 0.2,
            rotationX: 180,
          })
          tl.to(el, {
            duration: 0,
            ...getAnimationClass(letter),
          })
          tl.to(el, {
            duration: 0.2,
            rotationX: 360,
          })
          return tl.then()
        })

      Promise.all(promises).then(() => {
        setAnimate(-1)
      })
    }
  }, [animate])

  const classes = classNames({
    three: props.length === 3,
    four: props.length === 4,
    five: props.length === 5,
    six: props.length === 6,
    seven: props.length === 7,
    grid: true,
    small: props.size === 'SMALL',
    tiny: props.size === 'TINY',
  })

  const rowElements: JSX.Element[] = []
  for (let row = 0; row < props.guesses; row++) {
    const colElements: JSX.Element[] = []
    for (let letter = 0; letter < props.length; letter++) {
      const mark =
        animate !== row
          ? (marks[row] && marks[row][letter]) || GridState.EMPTY
          : GridState.EMPTY
      const colElement = (
        <div
          key={'' + row + '_' + letter}
          onClick={
            row === 2 && letter === 3
              ? () => {
                  playpass.analytics.track('GridCellHoneypotClicked')
                }
              : undefined
          }
          className={
            classNames(GuessClasses[GuessSymbols.indexOf(mark)])
          }
        >
          <span>{(words[row] && words[row][letter]) || ''}</span>
        </div>
      )
      colElements.push(colElement)
    }

    rowElements.push(
      <div
        className={classNames({
          animateRow: animate !== -1 && row === animate,
        })}
        key={'' + row}
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${props.length}, 1fr)`,
          gridColumnGap: '2px',
        }}
      >
        {colElements}
      </div>,
    )
  }

  let sizeStyleOverride: CSSProperties = {}
  if (props.sizeOverride) {
    sizeStyleOverride.width = `${props.sizeOverride!.width - 20}px`
    sizeStyleOverride.height = `${props.sizeOverride!.height - 20}px`
    sizeStyleOverride.margin = '0 auto'
  }

  return (
    <div
      className={classes}
      ref={gridRef}
      style={{
        padding: '10px',
        gridTemplateRows: 'repeat(' + props.guesses + ', 1fr)',
        display: 'grid',
        gridRowGap: '2px',
        ...sizeStyleOverride,
      }}
    >
      {rowElements}
    </div>
  )
}

export default Grid
