import { isAnyOf } from '@reduxjs/toolkit'
import {
  resetFromStorage,
  resetLevel,
  submitAnswer,
} from '../features/game/gameSlice'
import {
  clearCurrentProfile,
  playProfile,
  playTweet,
} from '../features/profiles/profilesSlice'
import {
  loginWithTwitter,
  Properties,
  setUserProperty,
  UserProperties,
} from '../features/user/userSlice'
import { addAppListener } from './middleware'
import { StoreType } from './store'
import * as playpass from 'playpass'

const addUserPropertiesMiddleware = (store: StoreType) => {
  const dispatchAndLog = (
    properties: Properties,
    payload: {
      key: string
      value: any
      firstTimeOnly?: boolean
    },
  ) => {
    const { key, value, firstTimeOnly } = payload
    store.dispatch(
      setUserProperty({
        key: key,
        value: value,
        firstTimeOnly: firstTimeOnly,
        disableLog: true,
      }),
    )

    if (properties[key] === undefined || !firstTimeOnly) {
      playpass.analytics.setUserProperties({ [key]: value })
    }
  }

  const startedProfiles: Record<string, true> = {}
  let startedGames = 0

  playpass.analytics.setUserProperties({
    realtimeSessionStartedGames: 0,
    realtimeSessionStartedProfiles: 0,
  })

  store.dispatch(
    addAppListener({
      matcher: isAnyOf(
        playProfile.fulfilled,
        playProfile.pending,
        submitAnswer,
        playTweet.pending,
        playTweet.fulfilled,
        clearCurrentProfile.fulfilled,
        setUserProperty,
        resetLevel,
        resetFromStorage,
        loginWithTwitter,
      ),
      effect: (action, listenerApi) => {
        const currentGameState = listenerApi.getState().game.gameState
        const prevGameState = listenerApi.getOriginalState().game.gameState
        const currentLevel = listenerApi.getState().game.levelState

        const currentProfile = listenerApi.getState().profiles
          .currentPlayProfile.data

        const properties = listenerApi.getState().user.properties

        switch (action.type) {
          case playProfile.pending.type:
            playpass.analytics.setUserProperties({
              realtimeProfileSource: listenerApi.getState().profiles
                .currentPlayProfile.context?.source,
              realtimeProfile: null,
              realtimeAnswer: null,
              realtimeTweetId: null,
              realtimeAnswerLength: null,
              realtimeGameState: null,
              realtimeGuessCount: null,
              realtimeGameMode: 'dailyProfile',
            })
            break
          case playTweet.pending.type:
            playpass.analytics.setUserProperties({
              realtimeProfileSource: listenerApi.getState().profiles
                .currentPlayProfile.context?.source,
              realtimeProfile: null,
              realtimeAnswer: null,
              realtimeTweetId: null,
              realtimeAnswerLength: null,
              realtimeGameState: null,
              realtimeGuessCount: null,
              realtimeGameMode: 'tweet',
            })
            break
          case clearCurrentProfile.fulfilled.type:
            playpass.analytics.setUserProperties({
              realtimeProfileSource: null,
              realtimeProfile: null,
              realtimeAnswer: null,
              realtimeTweetId: null,
              realtimeAnswerLength: null,
              realtimeGameState: null,
              realtimeGuessCount: null,
            })
            break
          case submitAnswer.type:
            if (
              (currentGameState === 'WIN' || currentGameState === 'LOSS') &&
              prevGameState === 'PENDING' &&
              currentProfile
            ) {
              dispatchAndLog(properties, {
                key: UserProperties.LAST_COMPLETED_PROFILE,
                value: currentProfile.username.toLowerCase(),
              })

              dispatchAndLog(properties, {
                key: UserProperties.FIRST_COMPLETED_PROFILE,
                value: currentProfile.username.toLowerCase(),
                firstTimeOnly: true,
              })
            }

            playpass.analytics.setUserProperties({
              realtimeGameState: currentGameState,
              realtimeGuessCount: listenerApi.getState().game.playerState.marks
                .length,
            })

            break
          case resetLevel.type:
            if (currentLevel.profile) {
              startedProfiles[currentLevel.profile.toLowerCase()] = true
              ++startedGames

              playpass.analytics.setUserProperties({
                realtimeProfile: currentLevel.profile.toLowerCase(),
                realtimeAnswer: currentLevel.word,
                realtimeAnswerLength: currentLevel.word.length,
                realtimeTweetId: currentLevel.object?.id,
                realtimeSessionStartedGames: startedGames,
                realtimeSessionStartedProfiles: Object.keys(startedProfiles)
                  .length,
              })
            }
            break
          case resetFromStorage.type:
            if (currentLevel && currentGameState === 'PENDING') {
              dispatchAndLog(properties, {
                key: UserProperties.LAST_STARTED_PROFILE,
                value: currentLevel.profile.toLowerCase(),
              })

              dispatchAndLog(properties, {
                key: UserProperties.FIRST_STARTED_PROFILE,
                value: currentLevel.profile.toLowerCase(),
                firstTimeOnly: true,
              })
            }

            playpass.analytics.setUserProperties({
              realtimeGameState: currentGameState,
              realtimeGuessCount: listenerApi.getState().game.playerState.marks
                .length,
            })
            break
          case setUserProperty.type:
            const property = action?.payload?.key
            const value = listenerApi.getState().user.properties[property]
            if (property && !action?.payload?.disableLog) {
              playpass.analytics.setUserProperties({
                [property]: value,
              })
            }
            break

          case loginWithTwitter.type:
            playpass.analytics.setUserProperties({
              realtimeIsLoggedInWithTwitter:
                listenerApi.getState().user.twitterLogin !== undefined,
            })
        }
      },
    }),
  )
}

export default addUserPropertiesMiddleware
