import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../app/hooks'
import { GameState, selectSessionStreak, selectTweetHistory, } from '../features/game/gameSlice'
import {
  useCurrentProfileContext,
  useSimilarProfiles,
} from '../features/profiles/profilesHooks'
import { getNextTweet } from '../features/profiles/tweetUtils'
import {
  PlaySource,
  Profile,
  ProfilesState,
  selectCurrentProfile,
} from '../features/profiles/profilesSlice'
import { State } from '../storage/daily'
import './ResultsView.css'
import { Fragment, useState } from 'react'
import {
  getBragShareButtonTextFromGame,
  getOwnProfileShareText,
  getShareButtonText,
  getShareExplainerText,
} from './shareCTAs'
import * as playpass from 'playpass'
import ShareButton from '../components/share/ShareButton'
import { useTwitterLogin } from '../features/user/userSlice'
import { ABKeys, getBucketID } from '../abTests'

const OwnProfileShare = (props: {
  state: State
  setup: GameState['levelState']
  status: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING'
  currentPlayProfile: ProfilesState['currentPlayProfile']
  similarProfiles: Profile[]
  showExtra: boolean
  nextTweet: string
}) => {
  const { currentPlayProfile } = props
  const navigate = useNavigate()
  const [showExtraButtons, setShowExtraButtons] = useState(props.showExtra)

  return (
    <div className="actions">
      <div className="shareButtons">
        <ShareButton
          buttonClass="twitter"
          id="share"
          feature="own-profile-share"
          subfeature="share-twitter"
          label={'Share on Twitter'}
          shareTextTemplate={getOwnProfileShareText(
            currentPlayProfile.data!,
            props.state,
            props.setup,
            props.status,
          )}
          payload={{
            day: props.setup.day,
          }}
          shareOptions={{
            type: 'twitter',
          }}
          onPostShare={() => setShowExtraButtons(true)}
        />
      </div>
      {showExtraButtons && (
        <div className="actionLinks">
          <button
            className="playMoreLink"
            onClick={() => {
              playpass.analytics.track('PlayNext', {
                tweetId: props.nextTweet,
              })
              navigate('/tweet/' + props.nextTweet)
            }}
          >
          Play Next
          </button>
        </div>
      )}
    </div>
  )
}

export const ShareToPlayMore = (props: {
  state: State
  setup: GameState['levelState']
  status: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING'
  currentPlayProfile: ProfilesState['currentPlayProfile']
  similarProfiles: Profile[]
  showExtra: boolean
  nextTweet: string
}) => {
  const { currentPlayProfile } = props
  const navigate = useNavigate()
  const [showExtraButtons, setShowExtraButtons] = useState(props.showExtra)
  const ab27 = getBucketID(ABKeys.SHARE_TO_PLAY) !== 'control'

  return (
    <div className="actions">
      <div className="shareButtons">
        <ShareButton
          buttonClass="share"
          id="share"
          feature="brag"
          subfeature="share-sheet"
          label={ab27 ? getShareButtonText('long') : getShareButtonText('short')}
          shareTextTemplate={getBragShareButtonTextFromGame(
            currentPlayProfile.data!,
            props.state,
            props.setup,
            props.status,
          )}
          payload={{
            day: props.setup.day,
          }}
          shareOptions={{
            type: 'any',
          }}
          onPostShare={() => setShowExtraButtons(true)}
          withShareIcon
        />
      </div>
      {showExtraButtons && (
        <div className="actionLinks">
            <button
              className="playMoreLink"
              onClick={() => {
                playpass.analytics.track('PlayNext', {
                  tweetId: props.nextTweet,
                })
                navigate('/tweet/' + props.nextTweet)
              }}
            >
            Play Next
            </button>
          </div>
      )}
    </div>
  )
}

export const GeneralShare = (props: {
  state: State
  setup: GameState['levelState']
  status: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING'
  currentPlayProfile: ProfilesState['currentPlayProfile']
  similarProfiles: Profile[]
  showExtra: boolean
  nextTweet: string
}) => {
  const { currentPlayProfile } = props
  const navigate = useNavigate()

  return (
    <div className="actions">
      <div className="shareButtons">
        <ShareButton
          buttonClass="share"
          id="share"
          feature="brag"
          subfeature="share-sheet"
          label={getShareButtonText('short')}
          shareTextTemplate={getBragShareButtonTextFromGame(
            currentPlayProfile.data!,
            props.state,
            props.setup,
            props.status,
          )}
          payload={{
            day: props.setup.day,
          }}
          shareOptions={{
            type: 'any',
          }}
          withShareIcon
        />
      </div>
      <div className="actionLinks">
          <button
            className="playMoreLink"
            onClick={() => {
              playpass.analytics.track('PlayNext', {
                tweetId: props.nextTweet,
              })
              navigate('/tweet/' + props.nextTweet)
            }}
          >
          Play Next
          </button>
        </div>
    </div>
  )
}

export const PlayNext = (props: {
  state: State
  setup: GameState['levelState']
  status: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING'
  nextTweet: string
}) => {
  const navigate = useNavigate()

  return (
    <div className="actionLinks">
          <button
            className="playMoreButton"
            onClick={() => {
              playpass.analytics.track('PlayNext', {
                tweetId: props.nextTweet,
              })
              navigate('/tweet/' + props.nextTweet)
            }}
          >
          Play Next
          </button>
    </div>
  )
}

export const ShareAB = (props: {
  state: State
  setup: GameState['levelState']
  status: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING'
  currentPlayProfile: ProfilesState['currentPlayProfile']
  similarProfiles: Profile[]
  nextTweet: string
  sessionStreak: number
}) => {
  const ab27 = getBucketID(ABKeys.SHARE_TO_PLAY)
  const showShareToPlayAgain = (ab27 === 'share_2nd_game' && props.sessionStreak === 2) || (ab27 === 'share_3rd_game' && props.sessionStreak === 3)
  const showStandardShare = (ab27 === 'share_2nd_game' && props.sessionStreak > 2) || (ab27 === 'share_3rd_game' && props.sessionStreak > 3)

  if (showShareToPlayAgain || ab27 === 'control') {
    return <ShareToPlayMore {...props} showExtra={false} />
  } else if (showStandardShare) {
    return <GeneralShare {...props} showExtra={true} />
  } else {
    return <PlayNext {...props} />
  }
}

const ResultsView = (props: {
  state: State
  setup: GameState['levelState']
  status: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING'
}) => {
  const currentPlayProfile = useAppSelector(selectCurrentProfile)
  const tweetHistory = useAppSelector(selectTweetHistory)
  const similarProfiles = useSimilarProfiles()
  const nextTweet = getNextTweet(currentPlayProfile.data!.tweets, tweetHistory, props.setup.tweetId)
  const sessionStreak = useAppSelector(selectSessionStreak) || 0

  const twitterUser = useTwitterLogin()
  const playSourceContext = useCurrentProfileContext()
  const twitterUsersProfile =
    (twitterUser &&
      twitterUser.username.toLowerCase() ===
        props.setup.profile.toLowerCase()) ||
    playSourceContext?.source === PlaySource.ME_SCREEN_INPUT_BOX

  const cta = twitterUsersProfile ? (
    <Fragment>
      <p className="triggerText">
        Share your game with your Twitter followers!
      </p>
      <OwnProfileShare
        {...props}
        showExtra={false}
        currentPlayProfile={currentPlayProfile}
        similarProfiles={similarProfiles}
        nextTweet={nextTweet}
      />
    </Fragment>
  ) : (
    <Fragment>
      <p className="triggerText">
        {getShareExplainerText(
            currentPlayProfile.data!,
            props.state,
            props.status,
        )}
      </p>
      <ShareAB
        {...props}
        currentPlayProfile={currentPlayProfile}
        similarProfiles={similarProfiles}
        nextTweet={nextTweet}
        sessionStreak={sessionStreak}
      />
    </Fragment>
  )

  return (
    <div className="resultContent">
      <div className="primary">{cta}</div>
    </div>
  )
}

export default ResultsView
