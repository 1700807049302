import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import * as playpass from 'playpass';

type status = 'loading' | 'loaded' | 'error';

const useShareImage = () => {

  const [status, setStatus] = useState<status>('loading');
  const [imageURL, setImageURL] = useState<string>('');

  useEffect(() => {

    const tweet = document.querySelector<HTMLDivElement>('.profileTweet');
    if (!tweet) {
      console.error('Cannot find ".profileTweet" selector in DOM');
      setStatus('error');
      return;
    }
    const tweetRect = tweet.getBoundingClientRect();
    const artificalPadding = 10;


    setTimeout(() => html2canvas(document.body, {
      allowTaint: true,
      useCORS: true,    // Enable because twitter profile image is from twitter's domain
      logging: false,   // loud 
      ignoreElements: (element) => element.tagName === 'NOSCRIPT',
    }).then(canvas => {
      const context = canvas.getContext('2d')!;

      // Create temp canvas to draw cropped image into
      const tmpCanvas = document.createElement('canvas');
      const tmpContext = tmpCanvas.getContext('2d')!;
      tmpCanvas.width = canvas.width;
      tmpCanvas.height = canvas.height;

      const imgObj = new Image();
      imgObj.onload = function() {

        const xPOS = (tweetRect.left - artificalPadding) * window.devicePixelRatio;
        const yPOS = 0;
        const imgWidth = (tweetRect.width + (artificalPadding * 2)) * window.devicePixelRatio;
        const imgHeight = imgWidth * (3/4);

        tmpContext.drawImage(
          imgObj,
          xPOS,
          yPOS,
          imgWidth,
          imgHeight,
          xPOS,
          yPOS,
          imgWidth,
          imgHeight,
        );

        const data = tmpContext.getImageData(xPOS, yPOS, imgWidth, imgHeight);
        context.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = imgWidth;
        canvas.height = imgHeight;

        context.putImageData(data, 0, 0);
        playpass.uploadTemporaryImage(canvas, 'image/jpeg')
          .then(imageURL => {
            setImageURL(imageURL)
            setStatus('loaded');
          })
          .catch((error) => {
            console.error(error);
            setStatus('error');
          });
      } // end onload
      imgObj.src = canvas.toDataURL('image/jpeg', 1.0);
    }), 0); // end html2Canvas
  }, []); // end useEffect

  return { status, imageURL };

};

export default useShareImage;
