import { useNavigate } from 'react-router-dom'
import { HOME_PATH } from '../Paths'

const ErrorView = (props: {
  children?: JSX.Element | undefined | Array<JSX.Element>
}) => {
  const navigate = useNavigate()
  return (
    <div className="loadingScreen">
      <img src={'/error.png'} />
      {props.children}
      <button onClick={() => navigate(HOME_PATH)} className="primary">
        Return Home
      </button>
    </div>
  )
}
export default ErrorView
