const CLIENT_ID = 'NXBmSVFhYk1Sb2F1Rjd6Rk82OXo6MTpjaQ'

const USE_DEV_BACKEND = false
const BACKEND = USE_DEV_BACKEND
  ? 'http://localhost:3001'
  : 'https://cmscmwtehivwhdnpvgwkzucwya0fasnz.lambda-url.us-east-1.on.aws'

export const redirectForTwitterLogin = () => {
  document.location =
    BACKEND +
    '/twitter/login' +
    (process.env.NODE_ENV === 'development' ? '?env=development' : '')
}

export const getTwitterRedirectStatus = () => {
  const params = getTwitterRedirectParams()
  if (params.oauth_token && params.oauth_verifier) {
    return 'success'
  }

  if (params.denied) {
    return 'failed'
  }

  return 'unknown'
}

export const getTwitterRedirectParams = () => {
  const params = new URLSearchParams(document.location.search)

  return {
    oauth_verifier: params.get('oauth_verifier') || undefined,
    oauth_token: params.get('oauth_token') || undefined,
    denied: params.get('denied') || undefined,
  }
}

export const completeTwitterLogin = async () => {
  const params = getTwitterRedirectParams()
  if (!params.oauth_token || !params.oauth_verifier) {
    throw new Error(
      'Cannot complete twitter login due to invalid redirect from Twitter',
    )
  }

  try {
    const callbackResponse = await fetch(
      BACKEND +
        `/twitter/callback?oauth_verifier=${params.oauth_verifier}&oauth_token=${params.oauth_token}`,
      {
        method: 'POST',
      },
    )

    if (callbackResponse.status !== 200) {
      const body = await callbackResponse.text()
      const error = new Error(body)
      ;(error as any).status = callbackResponse.status
      throw error
    }

    const tokens = (await callbackResponse.json()) as {
      id: string
      username: string
      accessToken: string
      accessSecret: string
    }
    return tokens
  } catch (e) {
    console.error(e)
    return null
  }
}
