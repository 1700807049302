import { useAppDispatch, useAppSelector } from './app/hooks'
import { selectInitialLoad } from './features/game/gameSlice'
import { selectSeenTutorial } from './features/nux/nuxSlice'
import { isGoogleBot } from './util/seo'
import { Navigate, useLocation, useMatch } from 'react-router-dom'

import LoadingView from './components/LoadingView'
import App from './App'
import { ABKeys, getBucketID } from './abTests'
import { useSearchParam } from './util/hooks'
import { DEBUG_PATH, HELP_PATH, HOME_PATH, ME_PATH } from './Paths'
import { useEffect } from 'react'
import { getTutorialProfile, getTutorialTweet } from './features/profiles/tutorialData'
import {
  playProfile,
  PlaySource,
  playTweet,
} from './features/profiles/profilesSlice'
import { getDescriptionText, getTitleText } from './Metatags'
import * as playpass from 'playpass'

const AppPather = () => {
  const location = useLocation()
  const needsTutorial = !useAppSelector(selectSeenTutorial) && !isGoogleBot()
  const initialLoadDone = useAppSelector(selectInitialLoad)
  const dispatch = useAppDispatch()

  const searchParams = new URLSearchParams(location.search)
  let profileSearchParam = searchParams.get('profile')
  let tweetIdSearchParam = searchParams.get('tweetId')
  if (profileSearchParam) {
    profileSearchParam = profileSearchParam.toLowerCase()
  }

  const inProfile = useMatch('/@:profile')
  const maybeProfile = inProfile?.params?.profile

  const tweet = useMatch('/tweet/:tweetId')
  const tweetId = tweet?.params?.tweetId

  // Defer the tutorial if a profile isn't specified on first load
  const deferTutorialParam = useSearchParam('deferTutorial')
  const deferTutorial =
    (needsTutorial &&
      deferTutorialParam !== null &&
      !profileSearchParam &&
      (location.pathname === '/' || location.pathname === HOME_PATH)) ||
    location.pathname === ME_PATH

  useEffect(() => {
    if (initialLoadDone) {
      playpass.analytics.track('ReactAppLoaded', {
        profile: maybeProfile,
        tweetId,
        needsTutorial,
      })
    }
  }, [initialLoadDone])

  // Preload a tweet to play
  useEffect(() => {
    if (tweetId && needsTutorial && initialLoadDone && !deferTutorial) {
      dispatch(playTweet({ tweetId }))
    }
  }, [tweetId, needsTutorial, initialLoadDone, deferTutorial])

  // Preload a profile to play
  useEffect(() => {
    if (!tweetId && needsTutorial && initialLoadDone && !deferTutorial) {
      const tutorialProfile = getTutorialProfile()
      dispatch(
        playProfile({
          username: maybeProfile ? '@' + maybeProfile : tutorialProfile,
          context: {
            source: maybeProfile
              ? (searchParams.get('source') as keyof typeof PlaySource) ||
                PlaySource.EXPLICIT
              : PlaySource.TUTORIAL_DEFAULT,
          },
        }),
      )
    }
  }, [needsTutorial, initialLoadDone, deferTutorial])

  useEffect(() => {
    if (needsTutorial && location.pathname === HELP_PATH) {
      playpass.analytics.track('TutorialShow', {
        profile: profileSearchParam,
        tweetId: tweetIdSearchParam,
      })
    }
  }, [needsTutorial, location.pathname])

  // Update meta tags and title
  useEffect(() => {
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', getDescriptionText(maybeProfile))

    document.title = getTitleText(maybeProfile)
  }, [maybeProfile])

  if (!initialLoadDone) {
    return <LoadingView />
  }

  if (
    needsTutorial &&
    location.pathname !== HELP_PATH &&
    location.pathname !== DEBUG_PATH &&
    !deferTutorial
  ) {
    const tutorialProfile = getTutorialProfile()
    // const tutorialTweet = getTutorialTweet()
    let path = ''
    if (maybeProfile) {
      path = HELP_PATH + '?profile=@' + maybeProfile
      const source = searchParams.get('source')
      if (source) {
        path += '&source=' + source
      }
    } else if (tweetId) {
      path = HELP_PATH + '?tweetId=' + tweetId
    } else {
      // Default to profile daily tweet
      path = HELP_PATH + '?profile=' + tutorialProfile // default profile to play  
      // path = HELP_PATH + '?tweetId=' + tutorialTweet // default tweet to play
    }

    return <Navigate replace to={path} />
  }

  return <App needsTutorial={needsTutorial} />
}

export default AppPather
