import { Profile } from './profilesSlice'
import './Profiles.css'
import classNames from 'classnames'

export const ProfileItem = (
  props: { profile: Profile } & {
    lastCompletionTime?: string
    onTap: () => void
  },
) => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  const completedToday = props.lastCompletionTime
    ? date.toISOString() < props.lastCompletionTime
    : false

  const buttonClasses = classNames({
    secondary: true,
    outline: completedToday,
  })

  return (
    <div className="profileItem" onClick={props.onTap}>
      <div className="itemLeft">
        <img src={props.profile.profilePictureURL} />
        <div className="text">
          <h3>{props.profile.name}</h3>
          <span>{props.profile.username}</span>
        </div>
      </div>
      <button className={buttonClasses}>
        {completedToday ? 'Done' : 'Play'}
      </button>
    </div>
  )
}
